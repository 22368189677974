export default {
  'portal.commonFooter.aboutAddress': 'Address：',
  'portal.price.team': 'TEAM',
  'portal.price.discount': 'Campaign discounts',
  'portal.home.contactWaySubmitted': 'Submitted',
  'portal.referral.copyrightInformation': '© Pandova holds the final explanation right of this campaign',
  'portal.commonFooter.aboutServiceTerms': 'Terms of Service',
  'portal.price.rateOpayOnlinePay': 'OPay Online Payment',
  'portal.home.contactWayPlaceholder': 'Email or phone number',
  'portal.price.storeThemes': 'Store customization tools',
  'portal.home.faqTitle1': 'Is Pandova suitable for my business?',
  'portal.home.faqTitle2': 'Can I create online store if I have no coding skill?',
  'portal.home.faqTitle5': 'Is there transaction service fee?',
  'portal.home.faqTitle3': 'Can my online store will mobile responsive?Will my online store be mobile-friendly? ',
  'portal.home.faqTitle4': 'How long can I use this for free?',
  'portal.price.expenseManagement': 'Expense management',
  'portal.referral.errorMessage': 'The system is busy, please try again later.',
  'portal.commonFooter.aboutPrivacyPolicy': 'Privacy Policy',
  'portal.price.explainText': 'We’ve included all the professional tools that any merchant could ever need to build a successful online business.',
  'portal.price.onlineStore': 'Free online store',
  'portal.helpCenter.PandovaHelpCenter': 'Pandova Help Center',
  'portal.home.onlinePay': 'Get paid quickly and securely',
  'portal.home.sloganMinutes': 'in 5 minutes',
  'portal.home.examinePrice': 'Price Plan',
  'portal.home.contactWayExplainInfoText': 'Leave your contact information, We will be in touch soon.',
  'portal.blog.SeeMore': 'View more',
  'portal.home.contactWaySubmitText': 'Submit',
  'portal.commonFooter.productAndServicePandova': 'Pandova',
  'portal.home.sloganFree': 'free',
  'portal.price.customDomain': 'Custom domain name',
  'portal.price.freeSSL': 'Free SSL certificate',
  'portal.home.onlinePayInfoText1': 'Onboard OPay online payment in just a few minutes to receive payments from customers online. Currently supports popular payment methods such as bank cards, credit cards, mobile wallets, etc., so that your customers can pay in their own way, making shopping easy and increasing conversion rates.',
  'portal.price.free': 'FREE',
  'portal.price.leadingFeature': 'MAIN FEATURES',
  'portal.onlineCustomer.guideText': 'You can ask question or give feedback',
  'portal.price.shippingIntegration': 'Shipping integration',
  'portal.price.analytics': 'ANALYTICS',
  'portal.home.freeStartGuidanceText': 'Start selling online for free',
  'portal.referral.helpContent3': '3. Tell your friends they can register through your link and win rewards',
  'portal.referral.helpContent2': '2. Share your link to social media or directly to your friends',
  'portal.referral.helpContent1': '1. Copy your referral link above',
  'portal.commonHeader.price': 'Price',
  'portal.price.unlimitedProducts': 'Add unlimited products',
  'portal.price.manuallyOrder': 'Manually create orders',
  'portal.commonHeader.helpCenter': 'Help Center',
  'portal.price.customerGroup': 'Customer groups',
  'portal.home.contactWaySubmitFormatErrorMessage': 'Only mailbox format or numbers within 50 digits are supported',
  'portal.commonHeader.freeStart': 'Get Started for Free',
  'portal.home.featureIntroduceDataAnalyseExplainText': 'Data analysis can give you a comprehensive and detailed understanding of your business to know better about sales, orders and customers to optimize your product and marketing strategy.',
  'portal.commonFooter.aboutPhone': 'Phone:',
  'portal.price.month': '/month',
  'portal.blog.latestRecommended': 'Latest Recommendation',
  'portal.referral.noLoginText': 'Please log in firstly',
  'portal.price.support': 'SUPPORT',
  'portal.referral.copyLink': 'Copy Link',
  'portal.onlineCustomer.feedbackBack': 'Back',
  'portal.home.faq': 'FAQ',
  'portal.commonFooter.productAndServiceHelpCenter': 'Help Center',
  'portal.onlineCustomer.helpCenterEntrance': 'Help Center',
  'portal.price.customerManagement': 'Customer management',
  'portal.commonFooter.about': 'About Us',
  'portal.down.publicityText': 'Sell and manage on the phone anywhere and anytime',
  'portal.onlineCustomer.welcomeText': 'Welcome',
  'portal.price.onlinePay': 'Payment integration',
  'portal.home.sloganCreateWebsite1': 'Create your online store',
  'portal.commonFooter.productAndService': 'Product and Service',
  'portal.home.featureIntroduceDataAnalyse': 'Optimize operations with advanced data analysis',
  'portal.price.unlimitedOrders': 'Receive unlimited orders',
  'portal.blog.ReadMinutes': 'minutes read',
  'portal.referral.registerTime': 'Registration Date',
  'portal.price.marketing': 'MARKETING',
  'portal.down.publicityTextDesc': 'Download the APP, manage your store on your phone from anywhere, add products, share them on social media, communicate with customers, and never miss an order.',
  'portal.referral.copyLinkSuccess': 'Link Copied',
  'portal.price.staffAccount': 'Staff accounts',
  'portal.price.googleAnalytics': 'Integrated Google Analytics',
  'portal.referral.guideText': 'share your referral link with your friends',
  'portal.commonFooter.productAndServiceAppDown': 'APP Download',
  'portal.onlineCustomer.feedbackFormFeedbackContent': 'Feedback content',
  'portal.commonFooter.aboutAddressContent': '13G Ahmed Kamel St., Al lasilki St., New Maadi, Maadi',
  'portal.price.smsMarketing': 'SMS marketing',
  'portal.price.facebook': 'Integrated Facebook Pixel ',
  'portal.commonHeader.myStore': 'My Store',
  'portal.helpCenter.quickSearchPlaceholder': 'Quick Search',
  'portal.price.engineOptimization': 'Search engine optimization',
  'portal.home.contactWaySubmitSuccessOK': 'OK',
  'portal.referral.notData': 'No referrals',
  'portal.referral.helpTitle': 'Easy to Get Referrals',
  'portal.onlineCustomer.nigeriaEntrance': 'Chat with us - Nigeria',
  'portal.price.plan': 'Cairo, Egypt',
  'portal.price.limitedTimeOffer': '(Limited-Time Offer)',
  'portal.blog.categoryAll': 'All',
  'portal.home.sloganFor': 'for',
  'portal.home.freeExplain': 'Everything you need to handle day-to-day service，totally free!',
  'portal.onlineCustomer.egyptEntrance': 'Chat with us - Egypt',
  'portal.home.featureIntroduceBusinessTools': 'Marketing Tools for Business Growth',
  'portal.home.contactWaySubmitErrorMessage': 'Contact information cannot be blank',
  'portal.home.featureIntroduceBusinessToolsExplainText': 'You can grow your business by creating marketing campaigns,  sending to and attracting your customers via Facebook, WhatsApp, Twitter, Instagram, email, SMS, etc.',
  'portal.price.explainText2': 'Let’s start TODAY!',
  'portal.commonHeader.login': 'Log in',
  'portal.home.contactWaySubmitSuccessInfoText': 'We will be in touch soon.',
  'portal.onlineCustomer.feedbackEntrance': 'Feedback',
  'portal.commonHeader.blog': 'Blog',
  'portal.commonFooter.aboutContactUs': 'Contact Us',
  'portal.referral.friendName': 'Name',
  'portal.price.commission': 'Commission',
  'portal.home.freeThemeExplainText': 'With constantly updated free themes and decorative components, easily customized colors, graphics and fonts, you can create a fantastic online store to attract customers to browse and purchase without any design skills.',
  'portal.referral.orderNum': 'Completed Orders',
  'portal.home.ProductSummary': 'Sell everything you want easily and attract more customers through social platforms like Facebook, WhatsApp, Twitter, Instagram, etc.',
  'portal.onlineCustomer.feedbackFormContact': 'Contact information',
  'portal.price.24/7support': '24/7 support',
  'portal.home.contactWaySubmitSuccessMessage': 'Your contact information',
  'portal.helpCenter.allResultShowText': 'Display all the results of the query',
  'portal.commonHeader.home': 'Feature',
  'portal.price.processingFee': 'Processing Fee',
  'portal.commonHeader.down': 'Download',
  'portal.home.knowMore': 'Learn more',
  'portal.home.faqAnswer1': 'Pandova is perfect for merchants that want to sell their products.  You can create an online store that sells everything you want and attract more customers through social media.',
  'portal.home.faqAnswer2': 'Absolutely yes. You do notneed to know how to code or design for using Pandova. You can quickly create your online store and start selling with no technical knowledge, the whole process takes about five minutes.',
  'portal.home.faqAnswer5': 'Pandova currently does not charge merchants transaction fees. However,  when your customers pay online, the payment platform will charge a payment rate of about 1.5% (the payment rate is subject to the payment platform you have used). For more information please visit',
  'portal.home.faqAnswer3': 'Yes, your online store comes with a mobile-optimized version, which means your online store has a very user-friendly interface on both PC and mobile.',
  'portal.home.faqAnswer4': 'The current free version contains basic features for daily use, which you can use for free forever. For more information, please visit',
  'portal.referral.activityRules': 'Learn More Details',
  'portal.onlineCustomer.feedbackFormUploadImage': 'Upload',
  'portal.price.emailMarketing': 'Email marketing',
  'portal.referral.myInviteList': 'My Referrals',
  'portal.home.freeTheme': 'Customize your store with themes for free',
  'portal.onlineCustomer.feedbackSubmit': 'Submit',
  'portal.price.reportsAnalytics': 'Reports & Analytics',
}
