export default {
  'home.quickMoveShop': '一键搬家',
  'home.kycInfo.accountFeozen': '您的OPay账户已被冻结，不能收款和结算，',
  'home.shippingTypeHasSet': '已设置运费模版',
  'home.paymentMethodExplanation': '设置收款方式，让顾客顺利完成交易。',
  'home.addDomainOperationText': '添加域名',
  'home.viewPaymentDetail': '查看详情',
  'home.kycInfo.paymentReceived': '已收款',
  'home.productAdded': '已添加商品',
  'home.kycInfo.comma': '，',
  'home.payment.goSet': '去设置',
  'home.goSettingUpdateShipping': '您可以前往设置，修改运费模版。',
  'home.completedStepsPercent': '已完成',
  'home.adminLanguage': '后台语言',
  'home.kycInfo.accountNotset': '您未完成OPay账户设置，账户已被冻结。',
  'home.addProductExplanation': '添加您要售卖的实物或数字商品。',
  'home.shopThemeExplanation': '选择一套主题，使用自定义模块搭建在线店铺。',
  'home.selectThemeOperationText': '选择店铺主题',
  'home.shopNeed': '店铺还差',
  'home.shippingFee': '物流运费',
  'home.domainSetting': '自定义域名',
  'home.kycInfo.onlyBeUsed': '仅可使用',
  'home.dowloadText': '下载',
  'home.addOtherProduct': '添加其他产品',
  'home.title': '首页',
  'home.kycInfo.accountPayment': '当前账户已收款',
  'home.todaySales': '销售额',
  'home.shopInfoComplete': '完善店铺信息',
  'home.kycInfo.willFrozen': '后账户会被冻结。',
  'home.shopMallPreview': '预览店铺',
  'home.productAddedGuide': '您可以前往商品管理，添加更多商品。',
  'home.kycInfo.maxAccount': '收款上限',
  'home.kycInfo.remaining': '（剩余',
  'home.logout': '退出登录',
  'home.todayPageVisit': '浏览量',
  'home.paymentActivated': '已激活支付',
  'home.kycInfo.setNow': '立即设置',
  'home.shopHasInit': '已完成初始化设置',
  'home.kycInfo.daysOfBracket': '天）',
  'home.kycInfo.NotSettle': '不能结算',
  'home.shopMoveTip': '如果您在其他平台已有商品，请点击导入',
  'home.creatShop': '创建店铺',
  'home.domainSettingExplain': '使用自定义域名，让顾客更容易找到您的在线店铺。',
  'home.shopThemeUpdated': '已更新店铺主题',
  'home.todayProcess': '今日动态',
  'home.shopTheme': '店铺主题',
  'home.kycInfo.limitForMonry': '您未完成OPay账户设置，收款受到一些限制。',
  'home.addProduct': '添加商品',
  'home.checkShopping': '查看运费',
  'home.dowloadPandovaApp': '下载PANDOVA应用程序！',
  'home.goSettingUpdatePayment': '您可以前往设置，修改收款设置。',
  'home.customTheme': '自定义主题',
  'home.shopInitSteps': '店铺还差4步完成初始化设置',
  'home.todayOrderNumber': '订单数',
  'home.paymentMethod': '收款方式',
  'home.fillToAddressAndCurrency': '填写店铺经营所在的地址，确认经营的货币',
  'home.someStepsForInit': '步完成初始化设置',
  'home.kycInfo.expirationFrozen': '到期后账户会被冻结',
  'home.completeStoreOperationText': '完善店铺信息',
  'home.todayUserVisit': '访客数',
  'home.kycInfo.setOpayAccount': '请尽快完成账户设置恢复使用。',
  'home.shippingFeeExplanation': '支持按重量、价格设置配送区域的运费方案。',
  'home.goAdd': '去添加',
  'home.accountSetting': '账户设置',
  'home.goDecorate': '去装修',
  'home.settingWarnTips': 'Just a few steps left to activate your store',
  'home.kycInfo.days': '天',
  'home.moreSettingTip': '完善以下设置让您的店铺更受欢迎',
  'home.checkPayment': '查看收款设置',
  'home.shopThemeGuide': '您可以前往在线店铺，设计自己的店铺主题。',
}
