export default {
  'report.saleOverview.pagePath': 'اسم الصفحة',
  'report.saleOverview.discounts': 'خصومات',
  'report.selectTextFifteenDaysAgo': 'اخر 15 يوما',
  'report.saleOverview.totalOrdersTIps': 'العدد الإجمالي للطلبات في جميع قنوات المبيعات خلال الوقت الإحصائي.',
  'report.saleOverview.checkOut': 'الدفع عند الاستلام',
  'report.saleOverview.unitPriceTIps': 'مبلغ الدفع لكل عميل خلال الوقت الإحصائي.',
  'report.saleOverview': 'الإحصائيات',
  'report.customerStatistics.customerTotal': 'إجمالي عدد العملاء',
  'report.saleOverview.expenses': 'المصاريف',
  'report.saleOverview.orderNumber': 'رقم الطلب',
  'report.saleOverview.orderCreateTimes': 'وقت الطلب',
  'report.saleOverview.hotPageTips': 'وخلال الفترة الإحصائية ، متجر على شبكة الإنترنت لديها أكبر عدد من الصفحات و كل صفحة من الزيارات .',
  'report.customerStatistics.unitPrice': 'متوسط الإنفاق لكل عميل',
  'report.saleOverview.sessionsConverted': 'الجلسات المحوله',
  'report.customerStatistics.returningVisitors': 'العملاء العائدين',
  'report.saleOverview.offlineSales': 'مبيعات المتجر التقليدي',
  'report.saleOverview.storeOrderConversion': 'معدل تحويل الطلبات في المتجر الإلكتروني',
  'report.saleOverview.totalOrders': 'إجمالي عدد الطلبات',
  'report.saleOverview.salesMargin': 'إجمالي الربح',
  'report.saleOverview.onlineSales': 'مبيعات المتجر الإلكتروني',
  'report.saleOverview.shippingSpend': 'تكلفة الشحن',
  'report.customerStatistics.ordersTop': 'توب 10 عملاء حسب عدد الطلبات',
  'report.saleOverview.unitPrice': 'السعر لكل عملية',
  'report.selectTextYesterday': 'امس',
  'report.comparison': 'مقارنة',
  'report.saleOverview.salesVolumeTIps': 'خلال الوقت الإحصائي ، صافي المبيعات (إجمالي المبيعات مطروحًا منه الخصومات والمرتجعات) بالإضافة إلى الضرائب والشحن.',
  'report.saleOverview.monetaryUnit': 'العملة',
  'report.selectTextSevenDaysAgo': 'اخر 7 ايام',
  'report.title': 'التحليلات',
  'report.saleOverview.salesVolume': 'المبيعات',
  'report.customerStatistics': 'تحليلات العملاء',
  'report.saleOverview.orderTransferTIps': 'النسبة المئوية من مجموع عدد الزيارات التي تم تحويلها إلى أوامر خلال الفترة الإحصائية .',
  'report.saleOverview.addToCart': 'تم الإضافة إلى عربة التسوق',
  'report.saleOverview.hotSaleTopTips': 'في الوقت الإحصائي ، أفضل 10 منتجات من المبيعات.',
  'report.customerStatistics.newCustomers': 'عملاء جدد',
  'report.saleOverview.times': 'مرات',
  'report.saleOverview.product': 'المنتج',
  'report.selectTextToday': 'اليوم',
  'report.saleOverview.hotSaleTop': 'توب 10 منتجات الأكثر مبيعا (حسب مبيعات الوحدة)',
  'report.saleOverview.onlineVisits': 'عدد زيارات المتجر',
  'report.selectTextThirtyDaysAgo': 'آخر 30 يومًا',
  'report.saleOverview.hotPage': 'صفحة ذات شعبية',
  'report.saleOverview.totalSales': 'إجمالي المبيعات',
  'report.saleOverview.salesAmount': 'المبيعات',
  'report.customerStatistics.amountTop': 'توب 10 عملاء حسب مبلغ الدفع',
  'report.saleOverview.orderPaymentAmount': 'مبلغ الدفع',
  'report.saleOverview.pageViewCount': 'الزيارات',
  'report.dayAfter': 'منذ عدة ايام',
}
