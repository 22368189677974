import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import { DEFAULT_LANGUAGE } from '@/constants/common'
import zhCN from './lang/zh-CN'
import enUS from './lang/en-US'
import arEG from './lang/ar-EG'

Vue.use(VueI18n)

const messages = {
  'zh-CN': {
    ...zhCN,
  },
  'en-US': {
    ...enUS,
  },
  'ar-EG': {
    ...arEG,
  },
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  messages,
})

window.$t = (key, value) => i18n.t(key, value)

export const changeLanguage = (language = DEFAULT_LANGUAGE) => {
  i18n.locale = language
  const dir = language === 'ar-EG' ? 'rtl' : 'ltr'
  document.documentElement.setAttribute('lang', language)
  document.documentElement.setAttribute('dir', dir)
  const locale = messages[language]
  moment.updateLocale(messages[language].momentName, locale.momentLocale)
}

export default i18n
