export default {
  'discount.discountListEdit.atTheRatio ': '按比例',
  'discount.discountListEdit.specifyGroup': '指定商品分组',
  'discount.deleteSuccess': '删除成功',
  'discount.discountListEdit.specifyProducts': '指定商品',
  'discount.discountList.add': '添加折扣',
  'discount.discountListEdit.tipContent': '使用可共享的链接通过电子邮件或社交媒体推广您的折扣码。客户使用此链接时，即可在结账时应用此折扣。',
  'discount.discountList.selectGoodsGroup': '选择商品分组',
  'discount.discountList.usageCount': '使用券数量',
  'discount.discountList.discountState': '折扣状态',
  'discount.discountList.startTime': '起始时间',
  'discount.discountList.title': '折扣列表',
  'discount.discountList.operation': '操作',
  'discount.discountEdit.placeInputScopeNumber': '请输入0到100之间的数',
  'discount.discountListEdit.getShareButton': '获取分享链接',
  'discount.discountListEdit.dialogTitle': '获取可分享链接',
  'discount.discountList.makeDiscountCode': '生成折扣码',
  'discount.discountListEdit.shareButton': '分享',
  'discount.discountList.minCartValue': '购物车最低金额',
  'discount.discountListEdit.fixedAmount ': '固定金额',
  'discount.discountList.descriptionForDiscountCode': '顾客会在结账时输入折扣码',
  'discount.discountList.maxDiscountValue': '最大折扣金额',
  'discount.discountList.close': '停用',
  'discount.discountList.open': '启用',
  'discount.discountList.discountValue': '折扣值',
  'discount.discountListEdit.startTime': '开始时间',
  'discount.discountList.descriptionForDiscount': '折扣描述',
  'discount.discountListEdit.endTime': '结束时间',
  'discount.discountListEdit.textNext': '下一步是什么？',
  'discount.discountList.discountType': '折扣类型',
  'discount.discountList.discountCode': '折扣码',
  'discount.title': '折扣管理',
  'discount.discountList.selectGoods': '选择商品',
  'discount.discountList.endTime': '截止时间',
  'discount.discountList.minimumRequirement': '最低要求',
  'discount.discountList.useScope': '适用范围',
  'discount.discountListEdit.dialogContent': '您可以通过电子邮件或社交媒体与您的客户分享此折扣链接。客户使用此链接时，即可在结账时应用此折扣。',
  'discount.dicountCodeCheckTip': '只能输入字母或数字  ',
  'discount.discountListEdit.allProducts': '所有商品',
  'discount.discountList.placeInput': '输入不能为空',
  'discount.discountList.detail': '详情',
  'discount.discountAdd.selectedItems': '已选',
  'discount.discountList.effectiveDate': '生效时间',
  'discount.makeSureDelete': '确认要删除吗？',
  'discount.discountList.delete': '删除',
  'discount.discountList.edit': '编辑折扣',
  'discount.discountListEdit.copyButton': '复制链接',
  'discount.discountListEdit.tipTitle': '已成功创建',
}
