export default {
  'application.shopMove.attentionText1': ' لن يتم نقل العملاء الذين لديهم نفس البريد الإلكتروني.',
  'application.shopMove.attentionText2': 'لن يتم نقل مقاطع الفيديو الخاصة بالمنتجات',
  'application.shopMove.DataSelectErrorText': 'يرجى تحديد البيانات المراد نقلها.',
  'application.shopMove.shopKey': 'مفتاح المتجر (مفتاح API)',
  'application.shopMove.sameProductTransfer': 'نفس طريقة معاملة المنتج',
  'application.facebookPixel.description': 'قم بعرض سلعك للبيع على فيس بوك و تتبع فعالية الأداء من خلال استخدام تعليقات بكسل.',
  'application.facebookPixel.title': 'فيس بوك بكسل',
  'application.shopMove.moveContent': 'بيانات النقل',
  'application.shopMove.IsMoving': 'عملية النقل جارية',
  'application.shopMove.result': 'نتائج',
  'application.shopMove.planRepetitionErrorText': 'توجد مهمة نقل بالفعل ، يرجى المحاولة مرة أخرى لاحقًا!',
  'application.facebookPixel.howToGetId': 'كيفية الحصول على معرف بكسل الفيسبوك؟',
  'application.googleAnalytics.description': 'من خلال تحليلات جوجل ، يمكن تتبع زوار المتجر ،إنه يساعد في الترويج التسويقي و متابعة التقارير. ',
  'application.facebookPixel.adsLabel': 'الاتصال بفيسبوك بكسل',
  'application.shopMove.Jumain.twoStepText': 'الخطوة الثانية: احصل على إقرار متجر جوميا',
  'application.shopMove.Jumain.UserId': 'معرف المستخدم',
  'application.shopMove.Jumain.APIURL': 'رابط API',
  'application.shopMove.shopifyStoreAuthorization': 'إذن Shopify',
  'application.shopMove.moveRemind': 'قد تستغرق هذه العملية بعض الوقت حتى تكتمل ، ويمكنك عرض المزيد من المعلومات في سجلات النقل.',
  'application.googleAnalytics.login': 'تسجيل الدخول ت الى google analytics',
  'application.shopMove.endTIme': 'وقت النهاية',
  'application.title': 'تطبيق',
  'application.facebookPixel.adsText': 'يساعد في تتبع أداء إعلانات فيس بوك، وتحسين الإعلانات ، وبناء عملاء مستهدفين',
  'application.facebookPixel.id': 'معرف الفيسبوك',
  'application.googleAnalytics.howToGetId': 'كيفية الحصول على معرف تحليلات جوجل؟',
  'application.shopMove.moveProductLang': 'لغة المنتجات التي تم نقلها',
  'application.shopMove.Jumain.firstStepText': 'الخطوة الأولى: أدخل رابط API الخاص بجوميا ',
  'application.googleAnalytics.adsLabel': 'ربط تحليلات جوجل',
  'application.facebookPixel.login': 'تسجيل الدخول في اFacebook Pixel',
  'application.shopMove.confirm': 'نعم',
  'application.shopMove.attention': 'ملاحظة:',
  'application.shopMove.firstStepText': 'الخطوة الأولى ، أدخل عنوان URL لمتجر shopify ',
  'application.shopMove.status': 'الحالة',
  'application.googleAnalytics.adsText': 'أضف معرف تحليلات جوجل لتتبع فعالية موقع الويب من خلال إنشاء العديد من التقارير',
  'application.shopMove.move': 'نقل',
  'application.shopMove.moveRecord': 'سجلات النقل',
  'application.shopMove.twoStepText': 'الخطوة الثانية ، نقل إعدادات البيانات',
  'application.applicationList.title': 'إدارة التطبيق',
  'application.shopMove.lengthErrorText': 'لا يمكن أن يتجاوز 1024 حرفًا.',
  'application.shopMove.shopSource': 'مصدر المتجر',
  'application.googleAnalytics.title': 'تحليلات جوجل',
  'application.shopMove.Jumain.migrationContentInstructions': 'سنقوم بنقل منتجات متجرك إلى Pandova ',
  'application.shopMove.shopUrlPlaceholder': 'الرجاء إدخال موقع متجرك',
  'application.shopMove.dutyDataLabel': 'سيتم نقل البيانات',
  'application.shopMove.threeStepDeclare3': '3. انقر فوق \'إنشاء تطبيق خاص\'',
  'application.shopMove.threeStepDeclare4': '4. إذا كانت هذه هي المرة الأولى لك ، فانقر فوق \'إنشاء تطبيق حصري جديد\' لإضافة مفتاح API ، وإذا لم تكن هذه هي المرة الأولى لك ، فيمكنك تحديد مفاتيح API الموجودة.',
  'application.shopMove.threeStepDeclare1': '1. أدخل \'التطبيقات\' في Shopify ADMIN',
  'application.shopMove.threeStepDeclare2': '2. أدخل \'إدارة التطبيقات الخاصة\'',
  'application.shopMove.threeStepDeclare7': '7- بمجرد الإنشاء بنجاح ، انسخ Shopify API Key وكلمة المرور إلى Pandova لإكمال نقل المنتجات.',
  'application.shopMove.threeStepDeclare5': '5. أدخل \'تفاصيل التطبيق\' ، وسّع الوحدة النمطية \'ADMIN API\' وعيّن إذن \'المنتج\' و \'صفحة المنتج\' على أنه \'قراءة وكتابة\'',
  'application.shopMove.threeStepDeclare6': '6. انقر فوق \'حفظ\' و \'إنشاء تطبيق\'',
  'application.shopMove.takesDate': 'يستغرق حوالي 15 دقيقة لإتمام النقل ',
  'application.shopMove.detailKnow': 'اعرف التفاصيل',
  'application.shopMove.Jumain.InfoText1': '١- اضغط على الإعدادات في لوحة بيانات جوميا ',
  'application.shopMove.Jumain.InfoText2': '٢- اضغط على إدارة الدمج',
  'application.shopMove.Jumain.InfoText3': '٣- قم بنسخ \'معرف المستخدم\' و \'مفتاح API\' الخاص بجوميا إلى Pandova، وسيتم نقل المنتجات بنجاح',
  'application.shopMove.dutyDataErrorText': 'الرجاء تحديد طريقة معالجة نقل المنتج',
  'application.shopMove.desc': 'نقرة واحدة لنقل المنتجات والبيانات الأخرى من منصات التجارة الإلكترونية مثل Shopify',
  'application.shopMove.shopLink': 'موقع المتجر',
  'application.shopMove.getShopifyLink': 'الحصول على رابط Shopify',
  'application.shopMove.startTIme': 'وقت البدء',
  'application.googleAnalytics.id': 'معرف تحليلات جوجل',
  'application.shopMove.shopKeyPassword': 'كلمة المرور الرئيسية (كلمة المرور)',
  'application.shopMove.threeStepText': 'الخطوة الثالثة ، احصل على ترخيص متجر Shopify',
  'application.shopMove.Jumain.APIKEY': 'مفتاح API',
  'application.shopMove.title': 'النقل بنقرة واحدة',
}
