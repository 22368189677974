import antdArEG from 'ant-design-vue/es/locale-provider/ar_EG'
import momentAR from 'moment/locale/ar'
import account from './ar-EG/account'
import charge from './ar-EG/charge'
import comment from './ar-EG/comment'
import common from './ar-EG/common'
import customer from './ar-EG/customer'
import discount from './ar-EG/discount'
import helpCenter from './ar-EG/help-center'
import home from './ar-EG/home'
import onlineShop from './ar-EG/online-shop'
import order from './ar-EG/order'
import portal from './ar-EG/portal'
import product from './ar-EG/product'
import report from './ar-EG/report'
import setting from './ar-EG/setting'
import sms from './ar-EG/sms'
import application from './ar-EG/application'
import activity from './ar-EG/activity'

const components = {
  antLocale: antdArEG,
  momentName: 'ar',
  momentLocale: momentAR,
}

export default {
  message: '-',
  ...components,
  ...account,
  ...charge,
  ...comment,
  ...common,
  ...customer,
  ...discount,
  ...helpCenter,
  ...home,
  ...onlineShop,
  ...order,
  ...portal,
  ...product,
  ...report,
  ...setting,
  ...sms,
  ...application,
  ...activity,
}
