export default {
  'account.register.emailCheckCode': 'رمز التحقق بواسطة البريد الإلكتروني',
  'account.shopAdd.shopNameFormatTip': 'ما يصل إلى 50 خانة ',
  'account.register.falseCheckCode': 'رمز التحقق غير صحيح',
  'account.register.reSend': 'إعادة الإرسال',
  'account.forgetPassword.checkCode': 'رمز التحقق',
  'account.login.linkManager': 'يرجى الاتصال بصاحب المتجر',
  'account.shopAdd.shopInfo': 'معلومات المتجر',
  'account.login.register': 'إنشاء حساب',
  'account.shopAdd.domainUrl': 'اسم النطاق (الدومين)',
  'account.register.passwordFalseFormat': 'يجب أن تتكون كلمة المرور من 8 إلى 16 رقمًا ويوصى بأن تحتوي على أحرف وأرقام',
  'account.resetPassword.currentAccount': 'الحساب الحالي',
  'account.register.goAgree': 'من خلال الاستمرار، فأنت توافق على',
  'account.resetPassword.newPassword': 'تأكيد كلمة المرور',
  'account.shopAdd.addShop': 'إنشاء متجر',
  'account.shopAdd.freeUse': 'إرسال',
  'account.register.sendMail': 'إرسال الرمز',
  'account.resetPassword.backToLogin': 'الرجوع إلى صفحة تسجيل الدخول',
  'account.forgetPassword.falseMail': 'البريد الإلكتروني غير موجود',
  'account.register.accountExist': 'هل لديك حساب؟',
  'account.register.privacy': 'سياسة الخصوصية',
  'account.shopList.allShops': 'المتاجر',
  'account.shopAdd.shopDomainFormatTip': 'ما يصل إلى 50 خانة، يمكن إدخال حروف و أرقام و رمز \'-\'، و لا يمكن أن تبدأ برمز  \'-\'',
  'account.register.passwordNoCommon': 'كلمتا المرور غير متطابقتين',
  'account.orderDetail.paymentTime': 'وقت الدفع',
  'account.resetPassword.password': 'كلمة المرور',
  'account.register.success': 'تم التسجيل بنجاح',
  'account.register.confirmPassword': 'تأكيد كلمة المرور',
  'account.login.canNotUse': 'انتهت صلاحية رابط الدعوة الحالي',
  'account.register.codeMailTips': 'أدخل رمز التحقق المرسل إلى بريدك الإلكتروني',
  'account.register.firstName': 'الاسم الأول',
  'account.resetPassword.passwordNoCommon': 'كلمتا المرور غير متطابقتين',
  'account.forgetPassword.sendCheckMailButton': 'إرسال الرمز',
  'account.register.and': 'و',
  'account.forgetPassword.requireText': 'يرجى ملء هذا الحقل',
  'account.login.falseMail': 'البريد الإلكتروني غير موجود',
  'account.register.password': 'كلمة المرور',
  'account.resetPassword.passwordFalseLength': 'من 8 إلى 16 خانة',
  'account.login.onAccount': ' ليس لديك حساب؟',
  'account.register.createButton': 'إنشاء',
  'account.login.password': 'كلمة المرور',
  'account.register.passwordCheckTips': 'يجب أن تتكون كلمة المرور من 8 إلى 16 رقمًا ويوصى بأن تحتوي على أحرف وأرقام',
  'account.login.forgetPassword': 'هل نسيت كلمة المرور؟',
  'account.forgetPassword.passwordMail': 'أدخل رمز التحقق المرسل إلى بريدك الإلكتروني',
  'account.register.accountLogin': 'تسجيل الدخول',
  'account.forgetPassword.reSend': 'إعادة الإرسال',
  'account.shopAdd.country': 'الدولة/المنطقة',
  'account.login.mail': 'البريد الإلكتروني',
  'account.register.email': 'البريد الإلكتروني',
  'account.shopAdd.business': 'ما نوع النشاط الخاص بك ؟(اختياري)',
  'account.forgetPassword.explainText': 'يرجى إدخال بريدك الإلكتروني ورمز التحقق لإثبات ملكية حسابك',
  'account.forgetPassword.falseCheckCode': 'رمز التحقق غير صحيح',
  'account.login.back': 'رجوع',
  'account.resetPassword.reset': 'إعادة تعيين كلمة المرور',
  'account.forgetPassword.email': 'البريد الإلكتروني',
  'account.resetPassword.passwordFalseFormat': 'يجب أن تتكون كلمة المرور من 8 إلى 16 رقمًا ويوصى بأن تحتوي على أحرف وأرقام',
  'account.forgetPassword.goOn': 'استمرار',
  'account.register.serviceTerms': 'بنود الخدمة',
  'account.register.createAccount': 'إنشاء حساب',
  'account.register.mailExisted': 'تم تسجيل هذا البريد الإلكتروني من قبل',
  'account.resetPassword.confirmButton': 'تأكيد',
  'account.resetPassword.finish': 'تم تعيين كلمة المرور بنجاح',
  'account.login.login': 'تسجيل الدخول',
  'account.register.passwordPlaceHolder': 'من 8 إلى 16 خانة',
  'account.register.nextToCreateShop': 'التالي',
  'account.shopAdd.shopName': 'اسم المتجر',
  'account.login.falsePassword': 'كلمة المرور غير صحيحة',
  'account.resetPassword.confirmPassword': 'تأكيد كلمة المرور',
  'account.resetPassword.passwordRequireText': 'من 8 إلى 16 خانة',
  'account.register.lastName': 'الاسم الأخير',
  'account.shopAdd.completeInfo': 'أكمل المعلومات التالية لإنشاء متجرك مجانا',
  'account.resetPassword.requireText': 'يرجى ملء هذا الحقل',
  'account.register.requireTrueMail': 'يرجى إدخال البريد الإلكتروني الصحيح',
  'account.register.passwordFalseLength': 'من 8 إلى 16 خانة',
  'account.resetPassword.oldPassword': 'كلمة المرور الأصلية',
  'account.register.checkCode': 'رمز التحقق',
  'account.register.requireText': 'يرجى ملء هذا الحقل',
  'account.resetPassword.back': 'رجوع',
  'account.register.createFirstShop': 'إكمال المعلومات',
}
