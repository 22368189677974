export default {
  'discount.discountListEdit.atTheRatio ': 'النسبة',
  'discount.discountListEdit.specifyGroup': 'مجموعة منتجات محددة',
  'discount.deleteSuccess': 'تم الحذف بنجاح',
  'discount.discountListEdit.specifyProducts': 'منتج محدد',
  'discount.discountList.add': 'إضافة خصم جديد',
  'discount.discountListEdit.tipContent': 'قم بمشاركة كود الخصم الخاص بك عن طريق رابط المشاركة من خلال البريد الإلكتروني أو منصات التواصل الاجتماعي. يمكن للعملاء الحصول على الخصم عند الدفع باستعمال الرابط',
  'discount.discountList.selectGoodsGroup': 'اختيار مجموعة المنتجات',
  'discount.discountList.usageCount': 'الكمية',
  'discount.discountList.discountState': 'الحالة',
  'discount.discountList.startTime': 'صالح من',
  'discount.discountList.title': 'خصم',
  'discount.discountList.operation': 'أوامر',
  'discount.discountEdit.placeInputScopeNumber': 'الرجاء إدخال رقم بين 0 و 100',
  'discount.discountListEdit.getShareButton': 'الحصول على رابط المشاركة',
  'discount.discountListEdit.dialogTitle': 'الحصول على رابط المشاركة',
  'discount.discountList.makeDiscountCode': 'إنشاء كود',
  'discount.discountListEdit.shareButton': 'مشاركة',
  'discount.discountList.minCartValue': 'الحد الأدنى لقيمة عربة التسوق (اختياري)',
  'discount.discountListEdit.fixedAmount ': 'مبلغ ثابت',
  'discount.discountList.descriptionForDiscountCode': 'سيقوم العميل بإدخال كود الخصم في صفحة الدفع',
  'discount.discountList.maxDiscountValue': 'مبلغ الخصم الأقصى (اختياري)',
  'discount.discountList.close': 'وقف',
  'discount.discountList.open': 'تفعيل',
  'discount.discountList.discountValue': 'قيمة الخصم',
  'discount.discountListEdit.startTime': 'تاريخ البدء',
  'discount.discountList.descriptionForDiscount': 'الوصف',
  'discount.discountListEdit.endTime': 'تاريخ الانتهاء',
  'discount.discountListEdit.textNext': 'ما هي الخطوة التالية؟',
  'discount.discountList.discountType': 'نوع الخصم',
  'discount.discountList.discountCode': 'كود الخصم',
  'discount.title': 'خصومات',
  'discount.discountList.selectGoods': 'اختيار المنتج',
  'discount.discountList.endTime': 'صالح إلى',
  'discount.discountList.minimumRequirement': 'الحد الأدنى من المتطلبات',
  'discount.discountList.useScope': 'نطاق التطبيق',
  'discount.discountListEdit.dialogContent': 'يمكن مشاركة رابط الخصم مع عملائك من خلال البريد الإلكتروني أو منصات التواصل الاجتماعي. و يمكن للعملاء استعمال الخصم عند الدفع ',
  'discount.dicountCodeCheckTip': 'يمكن إدخال أرقام و حروف فقط',
  'discount.discountListEdit.allProducts': 'كل المنتجات',
  'discount.discountList.placeInput': 'لا يمكن أن يكون الإدخال فارغًا',
  'discount.discountList.detail': 'تفاصيل',
  'discount.discountAdd.selectedItems': 'تم اختيار',
  'discount.discountList.effectiveDate': 'تاريخ الفعالية',
  'discount.makeSureDelete': 'هل أنت متأكد أنك تريد حذف؟',
  'discount.discountList.delete': 'حذف',
  'discount.discountList.edit': 'تعديل',
  'discount.discountListEdit.copyButton': 'نسخ الرابط',
  'discount.discountListEdit.tipTitle': 'تم الإنشاء بنجاح',
}
