export default {
  'portal.commonFooter.aboutAddress': 'العنوان：',
  'portal.price.team': 'الفريق',
  'portal.price.discount': 'الخصومات',
  'portal.home.contactWaySubmitted': 'تم رفعه ',
  'portal.referral.copyrightInformation': 'تمتلك Pandova التفسير النهائي الصحيح لهذه الحملة',
  'portal.commonFooter.aboutServiceTerms': 'شروط الخدمة',
  'portal.price.rateOpayOnlinePay': 'الدفع أون لاين عن طريق OPay ',
  'portal.home.contactWayPlaceholder': 'البريد الإلكتروني أو رقم الهاتف',
  'portal.price.storeThemes': 'تصاميم المتجر',
  'portal.home.faqTitle1': 'هل تناسب بندوفا عملي ؟',
  'portal.home.faqTitle2': 'هل يمكنني إنشاء متجر على الإنترنت إذا لم يكن لدي أي مهارة في البرمجة؟',
  'portal.home.faqTitle5': 'هل هناك رسوم خدمة المعاملات؟',
  'portal.home.faqTitle3': 'هل يمكن لمتجري على الإنترنت أن يستجيب للجوال؟',
  'portal.home.faqTitle4': 'كم من الوقت يمكنني استخدام هذا مجانا؟',
  'portal.price.expenseManagement': 'إدارة النفقات',
  'portal.referral.errorMessage': 'النظام مشغول ، يرجى المحاولة مرة أخرى لاحقًا.',
  'portal.commonFooter.aboutPrivacyPolicy': 'سياسة الخصوصية',
  'portal.price.explainText': 'قمنا بتصميم خطة استثنائية و مجانية خصيصا لك، تحتوي على كل ما تحتاجه لزيادة مبيعاتك',
  'portal.price.onlineStore': 'موقع المتجر الإلكتروني',
  'portal.helpCenter.PandovaHelpCenter': 'مركز مساعدة باندوفا',
  'portal.home.onlinePay': 'احصل على الدفع بسرعة وبنجاح',
  'portal.home.sloganMinutes': 'في غضون 5 دقائق',
  'portal.home.examinePrice': 'خطة السعر',
  'portal.home.contactWayExplainInfoText': 'من فضلك قم بادخال بياناتك و سنقوم بالتواصل معك في اقرب وقت',
  'portal.blog.SeeMore': 'شاهد المزيد',
  'portal.home.contactWaySubmitText': 'تسليم - رفع ',
  'portal.commonFooter.productAndServicePandova': 'Pandova',
  'portal.home.sloganFree': 'مجانا',
  'portal.price.customDomain': 'تخصيص اسم نطاق الموقع (الدومين)',
  'portal.price.freeSSL': 'شهادة SSL مجانية',
  'portal.home.onlinePayInfoText1': ' تعليمات الدفع عبر الإنترنتيستغرق الأمر بضع دقائق فقط لتقديم طلب الدفع عبر الإنترنت لـ OPay واستلام المدفوعات من العملاء عبر الإنترنت. حاليًا ، يدعم طرق الدفع السائدة مثل البطاقات المصرفية وبطاقات الائتمان ومحافظ الهاتف المحمول ، مما يسمح لعملائك بالدفع بطريقتهم الخاصة ، مما يجعل التسوق أسهل ويحسن معدلات التحويل.',
  'portal.price.free': 'مجانا',
  'portal.price.leadingFeature': 'الخصائص الرئيسية',
  'portal.onlineCustomer.guideText': 'يمكنك طرح سؤال أو إبداء الرأي',
  'portal.price.shippingIntegration': 'التكامل اللوجستي',
  'portal.price.analytics': 'التحليلات',
  'portal.home.freeStartGuidanceText': 'ابدأ البيع عبر الإنترنت مجانًا',
  'portal.referral.helpContent3': '3. أخبر أصدقاءك أنه يمكنهم التسجيل من خلال الرابط الخاص بك والفوز بجوائز',
  'portal.referral.helpContent2': '2. شارك الرابط الخاص بك على وسائل التواصل الاجتماعي أو مباشرة مع أصدقائك',
  'portal.referral.helpContent1': '1. انسخ رابط الدعوة بالأعلي',
  'portal.commonHeader.price': 'السعر',
  'portal.price.unlimitedProducts': 'منتجات غير محدودة',
  'portal.price.manuallyOrder': 'قم بإنشاء الطلبات يدويًا',
  'portal.commonHeader.helpCenter': 'مركز الخدمة',
  'portal.price.customerGroup': 'مجموعات العملاء',
  'portal.home.contactWaySubmitFormatErrorMessage': 'يتم دعم تنسيق صندوق البريد أو الأرقام الموجودة ضمن 50 رقمًا فقط',
  'portal.commonHeader.freeStart': ' البدء مجانا',
  'portal.home.featureIntroduceDataAnalyseExplainText': 'يمكن أن يمنحك تحليل البيانات فهمًا شاملاً ومفصلاً لعملك للتعرف بشكل أفضل على المبيعات والطلبات والعملاء لتحسين استراتيجية المنتج والتسويق',
  'portal.commonFooter.aboutPhone': 'رقم الهاتف',
  'portal.price.month': '/شهر',
  'portal.blog.latestRecommended': 'احدث توصية',
  'portal.referral.noLoginText': 'قم بتسجيل الدخول أولاً',
  'portal.price.support': 'الدعم',
  'portal.referral.copyLink': 'نسخ الرابط',
  'portal.onlineCustomer.feedbackBack': 'الرجوع للخلف',
  'portal.home.faq': 'الاسئلة المتكررة',
  'portal.commonFooter.productAndServiceHelpCenter': 'مركز المساعدة',
  'portal.onlineCustomer.helpCenterEntrance': 'مركز الخدمة',
  'portal.price.customerManagement': 'إدارة العملاء',
  'portal.commonFooter.about': 'معلومات عنا',
  'portal.down.publicityText': 'البيع والإدارة على الهاتف في أي مكان وزمان',
  'portal.onlineCustomer.welcomeText': 'مرحبا',
  'portal.price.onlinePay': 'تكامل الدفع عبر الإنترنت',
  'portal.home.sloganCreateWebsite1': 'قم بانشاء متجرك الالكتروني',
  'portal.commonFooter.productAndService': 'المنتجات و الخدمة',
  'portal.home.featureIntroduceDataAnalyse': 'تحليل البيانات لتحسين العمليات',
  'portal.price.unlimitedOrders': 'طلبات غير محدودة',
  'portal.blog.ReadMinutes': 'كم دقيقة للقراءة',
  'portal.referral.registerTime': 'تاريخ التسجيل',
  'portal.price.marketing': 'التسويق',
  'portal.down.publicityTextDesc': 'قم بتنزيل التطبيق ، وإدارة متجرك على هاتفك من أي مكان ، وإضافة منتجات ، ومشاركتها على وسائل التواصل الاجتماعي ، والتواصل مع العملاء ، وعدم تفويت أي طلب.',
  'portal.referral.copyLinkSuccess': 'تم نسخ الرابط',
  'portal.price.staffAccount': 'حسابات الموظفين',
  'portal.price.googleAnalytics': 'دمج تحليلات جوجل',
  'portal.referral.guideText': 'شارك الرابط المقترح الخاص بك مع أصدقائك',
  'portal.commonFooter.productAndServiceAppDown': 'تحميل التطبيق',
  'portal.onlineCustomer.feedbackFormFeedbackContent': 'محتوى التعليقات',
  'portal.commonFooter.aboutAddressContent': '13G ش أحمد كامل من ش اللاسيلكي المعادي الجديدة ، المعادي اللاسيلكي',
  'portal.price.smsMarketing': 'تسويق الرسائل القصيرة',
  'portal.price.facebook': 'دمج Facebook Pixel',
  'portal.commonHeader.myStore': 'متجري',
  'portal.helpCenter.quickSearchPlaceholder': 'البحث السريع ',
  'portal.price.engineOptimization': 'محرك البحث الأمثل',
  'portal.home.contactWaySubmitSuccessOK': 'حسنا',
  'portal.referral.notData': 'لا يوجد سجل دعوات مؤقتًا',
  'portal.referral.helpTitle': 'كيف تتم الدعوة؟',
  'portal.onlineCustomer.nigeriaEntrance': 'للدعم اضغط هنا -نيجيريا',
  'portal.price.plan': 'خطة السعر',
  'portal.price.limitedTimeOffer': 'عرض لوقت محدود',
  'portal.blog.categoryAll': 'الكل',
  'portal.home.sloganFor': '',
  'portal.home.freeExplain': 'استمتع بكل المميزات طوال اليوم مجانا',
  'portal.onlineCustomer.egyptEntrance': 'للدعم اضغط هنا -مصر',
  'portal.home.featureIntroduceBusinessTools': 'أدوات التسويق تعزز نمو الأعمال',
  'portal.home.contactWaySubmitErrorMessage': 'معلومات الاتصال فارغة',
  'portal.home.featureIntroduceBusinessToolsExplainText': 'يمكنك تنمية أعمالك من خلال إنشاء حملات تسويقية وإرسال وجذب عملائك عبر الفيسبوك و الواتساب و الانستجرام و البريد الالكتروني و الرسائل القصيرة الخ',
  'portal.price.explainText2': 'لنبدأ اليوم!',
  'portal.commonHeader.login': 'تسجيل الدخول',
  'portal.home.contactWaySubmitSuccessInfoText': 'سنتواصل معك قريبا',
  'portal.onlineCustomer.feedbackEntrance': 'التعليقات',
  'portal.commonHeader.blog': 'المدونة',
  'portal.commonFooter.aboutContactUs': 'اتصل بنا',
  'portal.referral.friendName': 'الاسم',
  'portal.price.commission': 'العمولة',
  'portal.home.freeThemeExplainText': 'من خلال السمات المجانية والمكونات الزخرفية التي يتم تحديثها باستمرار ، والألوان والرسومات والخطوط المخصصة بسهولة ، يمكنك إنشاء متجر رائع عبر الإنترنت لجذب العملاء للتصفح والشراء دون أي مهارات تصميم',
  'portal.referral.orderNum': 'عدد الطلبات',
  'portal.home.ProductSummary': '  قم ببيع كل ما تريد بسهولة و جذب المزيد من العملاء من خلال منصات التوااصل الاجتماعي مثل : الفيسبوك - الواتساب - التويتر و الانتسجرام الخ',
  'portal.onlineCustomer.feedbackFormContact': ' معلومات الاتصال',
  'portal.price.24/7support': 'دعم على مدار الساعة طوال أيام الأسبوع',
  'portal.home.contactWaySubmitSuccessMessage': 'بياناتك الشخصية - طريقة التواصل',
  'portal.helpCenter.allResultShowText': 'عرض جميع نتائج الاستعلام',
  'portal.commonHeader.home': 'الخصائص',
  'portal.price.processingFee': 'رسوم الخدمة',
  'portal.commonHeader.down': 'تحميل - تنزيل',
  'portal.home.knowMore': 'تعلم المزيد',
  'portal.home.faqAnswer1': 'Pandova مثالية للتجار الذين يرغبون في بيع منتجاتهم. يمكنك إنشاء متجر على الإنترنت يبيع كل ما تريده ويجذب المزيد من العملاء عبر وسائل التواصل الاجتماعي',
  'portal.home.faqAnswer2': 'بكل تأكيد نعم. لا تحتاج إلى معرفة كيفية البرمجة أو التصميم لاستخدام Pandova. يمكنك إنشاء متجرك عبر الإنترنت بسرعة والبدء في البيع بدون معرفة تقنية ، وتستغرق العملية برمتها حوالي خمس دقائق.',
  'portal.home.faqAnswer5': '\'لا تفرض Pandova حاليًا رسومًا على معاملات التجار. ومع ذلك ، عندما يدفع عملاؤك عبر الإنترنت ، ستفرض منصة الدفع معدل دفع يبلغ حوالي 1.5٪ (يخضع معدل الدفع لمنصة الدفع التي استخدمتها). لمزيد من المعلومات ، يرجى زيارة',
  'portal.home.faqAnswer3': 'نعم ، سيكون لديك نسخة محسّنة تستجيب للهاتف المحمول من المتجر عبر الإنترنت. سيكون لكل من إصدار موقع الويب والجوال واجهة سهلة الاستخدام',
  'portal.home.faqAnswer4': 'يحتوي الإصدار المجاني الحالي على سمات أساسية للاستخدام اليومي ، والتي يمكنك استخدامها مجانًا إلى الأبد. لمزيد من المعلومات، يرجى زيارة الموقع',
  'portal.referral.activityRules': 'أفهم أكثر',
  'portal.onlineCustomer.feedbackFormUploadImage': 'تحميل الصور',
  'portal.price.emailMarketing': 'التسويق عبر البريد الإلكتروني',
  'portal.referral.myInviteList': 'الدعوة الخاصة بي',
  'portal.home.freeTheme': 'قم بتعديل خصائص المتجر الخاص بك',
  'portal.onlineCustomer.feedbackSubmit': 'إرسال',
  'portal.price.reportsAnalytics': 'التقارير و التحليلات',
}
