export default {
  'order.orderList.phoneNumber': 'رقم الموبايل',
  'order.orderDetail.logistics.overview.noSend': 'لم يتم شحن المنتج، لذلك معلومات الشحن غير متاحة حاليا',
  'order.orderDetail.logistics.overview.NotifyShipment': 'إشعار العميل بحالة الشحن',
  'order.orderEdit.selectStatus': 'اختر حالة الدفع',
  'order.orderDetail.shipped': 'تم الشحن',
  'order.throw.copy.text': 'نسخ الرابط',
  'order.orderList.orderFilter': 'تصفية',
  'order.orderDetail.logistics.overview.CancelSend': 'إلغاء الشحن',
  'order.orderDetail.shippingStatus': 'حالة الشحن',
  'order.orderDetail.orderSummary': 'الاوامر',
  'order.orderDetail.canceled': 'تم الإلغاء',
  'order.orderDetail.logistics.overview.CancelFulfillment': 'إلغاء الشحن',
  'order.orderDetail.copyLinkDescription': 'يمكنك نسخ هذا الرابط و إرساله إلى العميل لإتمام الدفع',
  'order.orderList.payMethodName': 'طريقة الدفع',
  'order.orderEdit.markToFormalTips': 'بعد تحديد العلامة بنجاح ، لا يمكن تعديلها مرة أخرى.  يرجى التأكد ما إذا كانت المعلومات صحيحة.',
  'order.orderDetail.logistics.overview.EditShipmentInformation': 'تعديل معلومات الشحن',
  'order.orderList.orderAmount': 'المبلغ الإجمالي',
  'order.throw.list.action': 'أوامر',
  'order.orderList.orderStatus': 'الحالة',
  'order.orderEdit.moneyAmount': 'الكمية',
  'order.orderDetail.logistics.overview.Carrier': 'شركة النقل',
  'order.orderEdit.moneyPercentage': 'النسبة',
  'order.orderEdit.markToFormalOrder': 'حدد كطلب عادي',
  'order.orderDetail.productMemo': 'ملاحظات',
  'order.orderEdit.doNotOperate': 'لا يمكن تعديل الطلبات قيد التنفيذ',
  'order.orderDetail.logistics.overview.AddInformation': 'إضافة معلومات الشحن',
  'order.orderDetail.logistics.overview.SendInformation': 'إرسال معلومات الشحن إلى العميل',
  'order.orderDetail.productInfo': 'معلومات المنتج',
  'order.orderEdit.paid': 'مدفوع',
  'order.orderList.StartTime': 'وقت الطلب (من)',
  'order.throw.send.success': 'تم إرسال بريد إلكتروني إلى العميل للتذكير باستعادة عربة التسوق المتروكة في sendTime',
  'order.orderEdit.selectCustomer': 'اختر العميل',
  'order.orderDetail.logistics.AddDeliveryInformation': 'إضافة معلومات الشحن',
  'order.orderList.deliveredOrder': 'طلبات المشحونة',
  'order.orderEdit.discountValueValidator': 'لا يمكن أن يكون مبلغ الخصم أكبر من السعر الإجمالي للبضائع',
  'order.orderDetail.preview': 'معاينة',
  'order.orderDetail.unPaid': 'غير مدفوعة',
  'order.orderEdit.editOrder': 'تعديل مسودة طلب',
  'order.orderEdit.discountPercentCheck': 'لا يمكن أن يكون معدل الخصم أكبر من 100',
  'order.orderList.saleChannel': 'قناة المبيعات',
  'order.orderDetail.logistics.overview.EditShipment': 'تعديل معلومات الشحن',
  'order.orderDetail.shippingSelect': 'خيارات الشحن',
  'order.orderEdit.orderMemo': 'ملاحظات (اختياري)',
  'order.orderDetail.sendPaymentLink': 'إرسال  رابط الدفع',
  'order.orderEdit.unPaid': 'غير مدفوع',
  'order.orderEdit.editProduct': 'تعديل',
  'order.orderDetail.sendNote': 'إرسال إيصال',
  'order.orderEdit.saleChannel': 'قناة المبيعات',
  'order.orderList.isDraftOrder': 'مفعل / مسودة',
  'order.orderDetail.productName': 'اسم المنتج',
  'order.orderDetail.transactionNo': 'رقم العملية',
  'order.throw.status.recovery': 'حالة الاستعادة',
  'order.orderDetail.logistics.TrackingNumber': 'رقم التتبع',
  'order.orderList.customerName': 'اسم العميل',
  'order.orderDetail.shippingFee': 'تكلفة الشحن',
  'order.orderDetail.billText': 'فاتورة',
  'order.orderDetail.logistics.overview.CancelConfirmMessage': 'تم شحن المنتج. هل أنت متأكد من إلغاء الشحن؟',
  'order.orderEdit.discountType': 'نوع الخصم',
  'order.orderEdit.selectAddress': 'اختر العنوان',
  'order.orderDetail.productUnitPrice': 'السعر',
  'order.orderDetail.productAmount': 'الكمية',
  'order.orderDetail.orderNo': 'رقم الطلب',
  'order.throw.order.placed': 'وقت الطلب',
  'order.orderDetail.relationInfo': 'معلومات للتواصل',
  'order.throw.throw.title': 'الطلبات المتروكة',
  'order.orderDetail.markPaidTips': 'إذا كنت قد استلمت مبلغ الدفع، فيرجى وضع علامة \'مدفوع\' على الطلب',
  'order.orderDetail.pickUpInStore': 'استلام في المحل',
  'order.orderDetail.pickUpInLogistics': 'توصيل بالبريد',
  'order.orderList.unPaidOrder': 'طلبات غير مدفوعة',
  'order.orderEdit.totalAmount': 'المبلغ الإجمالي',
  'order.orderDetail.orderSource': 'المصدر',
  'order.orderEdit.productMemo': 'ملاحظات',
  'order.orderEdit.orderList': 'المنتجات',
  'order.orderDetail.logistics.overview.HasShipped': 'تم شحن المنتج، ولكن معلومات الشحن غير متاحة حاليا',
  'order.orderEdit.manualAddOrder': 'اضافة الطلب يدويا',
  'order.throw.detail.btn': 'تفاصيل',
  'order.orderDetail.customerInfo': 'معلومات العميل',
  'order.orderDetail.memoRequiredTip': 'الرجاء إدخال الملاحظات',
  'order.orderList.draftStatus': 'مفعل / مسودة',
  'order.orderDetail.tax': 'ضرائب',
  'order.orderDetail.newOrderNotification': 'تم استلام طلب دفع عند الاستلام',
  'order.orderEdit.unManualAddOrder': 'اضافة الطلب اونلاين',
  'order.orderEdit.discount': 'خصومات',
  'order.orderDetail.processing': 'بانتظار الشحن',
  'order.orderDetail.paymentLinkCopedToast': 'تم نسخ رابط الدفع',
  'order.orderEdit.selectProduct': 'اختر المنتج',
  'order.throw.send.customer': 'هل أنت متأكد من إرسال بريد إلكتروني لاستعادة العميل؟',
  'order.orderDetail.exportText': 'تصدير',
  'order.orderList.title': 'كل الطلبات',
  'order.orderDetail.logistics.Carrier': 'شركة النقل',
  'order.orderEdit.shippingFee': 'تكلفة الشحن',
  'order.orderDetail.logistics.Confirm': 'تأكيد',
  'order.orderEdit.totalGoodsPrice': 'المجموع الفرعي',
  'order.orderEdit.orderStartDate': 'تاريخ الطلب',
  'order.orderEdit.payMethod': 'طريقة الدفع',
  'order.orderDetail.logistics.overview.ConfirmSend': 'تأكيد الشحن',
  'order.orderDetail.sendOrder': 'إرسال فاتورة',
  'order.orderEdit.addProduct': 'منتج جديد',
  'order.orderDetail.markPaidText': 'وضع علامة مدفوع',
  'order.orderDetail.payStatus': 'حالة الدفع',
  'order.orderList.orderNo': 'رقم الطلب',
  'order.orderList.EndTime': 'وقت الطلب (إلى)',
  'order.orderDetail.productDiscount': 'خصومات',
  'order.orderDetail.logistics.DeliveryProgress': 'معلومات تتبع الشحن',
  'order.throw.list.sent.status': 'حالة الإرسال',
  'order.orderDetail.logistics.overview.Cancel': 'إلغاء',
  'order.orderEdit.formatOrder': 'مفعل',
  'order.orderList.orderFilterStatus': 'حالة الطلب',
  'order.orderDetail.returned ': 'تم الاسترجاع',
  'order.orderDetail.paymentInfo': 'الدفع',
  'order.orderEdit.tax': 'ضرائب',
  'order.orderList.allOrder': 'كل الطلبات',
  'order.orderList.completedOrder': 'طلبات ناجحة',
  'order.throw.send.btn.text': 'إرسال بريد إلكتروني لاستعادة العميل',
  'order.orderEdit.saveProductInfo': 'حفظ',
  'order.orderDetail.logistics.noDetail': 'لا يوجد معلومات شحن',
  'order.orderDetail.cancelOrder': 'تم إلغاء الطلب',
  'order.orderEdit.addOrder': 'إنشاء مسودة طلب',
  'order.orderDetail.newOrder': 'طلب جديد',
  'order.throw.send.success.toast': 'تم إرسال البريد الإلكتروني بنجاح',
  'order.orderDetail.shippingAddress': 'عنوان الشحن',
  'order.orderEdit.customerSelectRequireTip': 'من فضلك اختر عميل',
  'order.orderEdit.saveToOfficial': 'حفظ ',
  'order.orderDetail.totalGoodsPrice': 'المجموع الفرعي',
  'order.orderList.searchOrder': 'تحقق',
  'order.orderEdit.addNewCustomer': 'إضافة عميل جديد',
  'order.orderDetail.orderMemo': 'ملاحظات',
  'order.orderDetail.orderDetailText': 'تفاصيل الطلب',
  'order.orderDetail.payInfo': 'الدفع',
  'order.orderDetail.requestPay': 'طلب دفع',
  'order.orderList.addOrder': 'إنشاء مسودة طلب',
  'order.orderDetail.completed': 'تمت بنجاح',
  'order.orderEdit.deleteProduct': 'حذف',
  'order.orderDetail.logistics.deliverGoods': 'شحن المنتج',
  'order.orderDetail.billAddress': 'عنوان الدفع',
  'order.orderList.orderStartTime': 'التاريخ',
  'order.orderList.willDealOrder': 'قيد المعالجة',
  'order.orderDetail.totalAmount': 'المبلغ الإجمالي',
  'order.orderDetail.logistics.completeInformation': 'يرجى إكمال معلومات الشحن',
  'order.orderEdit.draftOrder': 'طلب المسودة',
  'order.orderEdit.productAmount': 'الكمية',
}
