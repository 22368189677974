export default {
  'common.clearText': 'Clear',
  'common.NeedHelp': 'Need Help?',
  'common.adminMenu.reviews': 'Reviews',
  'common.operationText': 'Operations',
  'common.adminMenu.taxSetting': 'Tax',
  'common.adminMenu.chargeGroupList': 'Expense Categories',
  'common.adminMenu.productGroup': 'Collections',
  'common.resetText': 'Reset',
  'common.minValue': 'Minimum',
  'common.adminMenu.staff': 'Staff',
  'common.adminMenu.smsRecords': 'SMS records',
  'common.adminMenu.dataReview': 'Data Overview',
  'common.totalAmount': 'Total',
  'common.adminMenu.analytics': 'Analytics',
  'common.adminMenu.rechargeRecords': 'Recharge records',
  'common.adminMenu.paymentSetting': 'Payment Settings',
  'common.moreFilters': 'More Filters',
  'common.validateNumber': 'Please enter a number',
  'common.adminMenu.chargeList': 'All Expenses',
  'common.batchOperation': 'Batch operation',
  'common.deleteText': 'Delete',
  'common.lessThan': 'Less than',
  'common.remove': 'Remove',
  'common.adminMenu.shopTheme': 'Themes',
  'common.menu.product': 'Products',
  'common.finishText': 'Done',
  'common.adminMenu.smsSetting': 'SMS',
  'common.adminMenu.product': 'Products',
  'common.oneScore': '1 star',
  'common.back': 'Back',
  'common.twoScore': '2 stars',
  'common.saveText': 'Save',
  'common.adminMenu.customerGroup': 'Customer Group',
  'common.cancelText': 'Cancel',
  'common.confirm': 'Confirm',
  'common.uploadLimitSize': 'The picture must not be larger than 10MB',
  'common.adminMenu.onlineShopPage': 'Pages',
  'common.adminMenu.message': 'Messages',
  'common.menu.customPage': 'Custom pages',
  'common.adminMenu.shippingFee': 'Shipping fee',
  'common.adminMenu.accountSetting': 'Profile',
  'common.threeScore': '3 stars',
  'common.adminMenu.domain': 'Domain',
  'common.networkError': 'Network disconnected. Please check network',
  'common.fiveScore': '5 stars',
  'common.confirmBtnText': 'OK',
  'common.greaterThan': 'Greater than',
  'common.adminMenu.onlineShop': 'Online store',
  'common.formValidate.codeText': 'characters',
  'common.adminMenu.charge': 'Expenses',
  'common.menu.collection': 'Collections',
  'common.memoText': 'Notes',
  'common.openOption': 'Enable',
  'common.adminMenu.customerList': 'All Customers',
  'common.uploadText': 'Upload',
  'common.adminMenu.setting': 'Settings',
  'common.operateFail': 'Failed',
  'common.formValidate.errorInfoText': 'The information is filled in incorrectly. Please check the contents.',
  'common.adminMenu.customerAnalytics': 'Customer Analysis',
  'common.formValidate.maxLengthTip': 'The content is too long and cannot exceed',
  'common.selectTip': 'Select',
  'common.adminMenu.home': 'Home',
  'common.networkTimeout': 'Unstable network connection. Please refresh and try again later.',
  'common.adminMenu.shopInfo': 'Store Information',
  'common.discountCodeLengthTip': 'Please enter a 3-50 character discount code.',
  'common.adminMenu.onlineShopNav': 'Navigation',
  'common.adminMenu.discount': 'Discounts',
  'common.adminMenu.feedback': 'Feedback',
  'common.menu.home': 'Homepage',
  'common.closeOption': 'Disable',
  'common.maxValue': 'Maximum',
  'common.requireNormsTip': 'Please select an option',
  'common.adminMenu.inventoryList': 'Inventory list',
  'common.uploadLimitFormat': 'You can only upload pictures in JPG / PNG format',
  'common.adminMenu.order': 'Orders',
  'common.fourScore': '4 stars',
  'common.adminMenu.productList': 'All Products',
  'common.adminMenu.customer': 'Customers',
  'common.editText': 'Edit',
  'common.serverErrorTip': 'Service exception. Please try again later.',
  'common.menu.search': 'Search',
  'common.requireNormsInfoTips': 'Please complete the option information',
}
