export default {
  'activity.landing.descText': 'إعداد سريع وسهل - كل ما تحتاجه لبدء البيع عبر الإنترنت، من منشئ المتجر إلى تتبع البيانات في الوقت الحقيقي.',
  'activity.landing.freeSubTitle': 'إنشاء متجر عبر الإنترنت في 5 دقائق',
  'activity.landing.featureTitle3': 'دفع موثوق',
  'activity.landing.featureTitle2': 'لوحة البيانات و المعلومات',
  'activity.landing.featureTitle1': 'واجهة متجرر مخصصة',
  'activity.landing.startButtonText2': 'الحصول على حساب مجاني الآن',
  'activity.landing.startButtonText1': 'ابدء البيع',
  'activity.landing.footerTitle': 'بيع عبر الإنترنت مع متجر Pandova المجاني',
  'activity.landing.freeTitle': 'جعل البيع عبر الإنترنت مجانًا',
  'activity.landing.featureDesc3': 'إلى جانب الدفع بOpay عبر الإنترنت، يوفر Pandova أيضا أساليب دفع شائعة مثل البطاقات المصرفية وبطاقات الائتمان ومحافظ الهاتف المحمول وغيرها، بحيث يمكن لعملائك الدفع بطريقتهم الخاصة، مما يجعل التسوق سهل وآمن.',
  'activity.landing.featureDesc1': 'يعطي Pandova التجار خيار الاختيار من بين مجموعة متنوعة من النماذج لإنشاء منفذ البيع الخاصة بهم على الفور.',
  'activity.landing.featureDesc2': 'لوحة المعلومات و البيانات (الداش بورد) هي نظام إدارة مخزون متعدد الإمكانات يعطي التجار لمحة عامة عن أعمالهم. يمكن للتجار إدارة المنتجات والطلبات وتتبع مسار المنتج وغيرها الكثير!',
}
