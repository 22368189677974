export default {
  'product.productEdit.salePrice': 'الأسعار',
  'product.productGroupList.downloadExcelText': 'تنزيل',
  'product.comment.createCommentUserSurnamePlaceholder': 'يرجى إدخال اسم عائلة المستخدم',
  'product.productEdit.importSKUNotCoverSameProduct': 'لن يؤدي تحميل المنتجات إلى استبدال أي منتجات موجودة بنفس اسم المنتج ',
  'product.productEdit.batchEditCostPrice': 'تعديل سعر التكلفة',
  'product.productGroupList.operation': 'اللعمليات',
  'product.productGroupList.saveGroup': 'حفظ المجموعة',
  'product.comment.batchTurnOnOperationSuccess': 'تم تفعيل التعليقات بنجاح',
  'product.comment.productName': 'اسم المنتج',
  'product.productEdit.importMultipleTip': 'النوع',
  'product.comment.reviewShow': 'عرض التعليق',
  'product.comment.detailsCommentBatchPublickOperationTipsDescription': 'بمجرد عرض التعليقات، سيتمكن العملاء من رؤيتها على الموقع',
  'product.productGroupList.addGroup': 'مجموعة جديدة',
  'product.productEdit.sku': 'الحد الأدنى من وحدة حفظ المخزون',
  'product.productList.title': 'كل المنتجات',
  'product.comment.createCommentUserName': 'اسم المستخدم',
  'product.productEdit.requireNormsName': 'الرجاء إدخال اسم خيار المنتج',
  'product.productEdit.pricingTitle': 'التسعير',
  'product.productEdit.selectUnit': 'اختر الوحدة',
  'product.productEdit.importSuccess': 'تم التحميل بنجاح',
  'product.comment.batchTurnOnOperationTipsTitle': 'هل أنت متأكد من تفعيل التعليقات ل $[X] منتجات؟',
  'product.comment.detailsCommentSearchScore': 'التقييم',
  'product.comment.detailsBatchOperationShow': 'عرض',
  'product.productEdit.batchEditOriginalPrice': 'تعديل السعر الأصلي',
  'product.inventoryModel.setting': 'الإعدادات',
  'product.productEdit.batchEditStock': 'تعديل المخزون',
  'product.comment.createCommentAverage': 'التقييم',
  'product.productGroupList.coverText': 'سيتم الكتابة فوق المنتجات التي تحمل نفس الأسماء. إذا كانت المنتجات التي تم تحميلها عبارة عن منتجات متعددة المواصفات ، فستستبدل المواصفات الأصلية للمنتجات.',
  'product.productList.selectNeedTips': 'حدد عنصرًا واحدًا على الأقل',
  'product.comment.detailsBatchOperationClose': 'مخفي',
  'product.comment.detailsCommentBatchDeleteOperationSuccess': 'حذف التعليقات',
  'product.comment.reviewHide': 'إخفاء التعليق',
  'product.productEdit.costPrice': 'سعر التكلفة',
  'product.productEdit.normsEditExplain': 'أضف ما يصل إلى ثلاثة مواصفات للمنتج',
  'product.inventoryList.images': 'صورة المنتج',
  'product.productEdit.addNormsPlaceHolder': 'الرجاء إدخال قيمة الخيار ',
  'product.comment.detailsCommentSearchIsPublic': 'حالة عرض التعليق',
  'product.productEdit.importLoadingModalClose': 'إغلاق',
  'product.productGroupList.excelTemplate': 'نموذج لملف اكسيل ',
  'product.productEdit.productNumberTip': 'ادخل كمية المنتج',
  'product.productEdit.images': 'صورة المنتج',
  'product.comment.reviewStore': 'تعليق المتجر',
  'product.productEdit.productNorms': 'مواصفات المنتج',
  'product.productEdit.title': 'مجموعات المنتج',
  'product.comment.batchTurnOnOperationTipsDescription': 'بمجرد تفعيل التعليقات، سيتم عرض تعليقات المنتج ويمكن للعملاء إضافة تعليقات',
  'product.productEdit.originalPriceValidateTip': 'لا يمكن أن يكون السعر الأصلي أقل من سعر البيع',
  'product.productEdit.importSuccessTip': 'تم التحميل بنجاح',
  'product.productEdit.noWeightTip': 'يرجى ملء وزن المنتجات',
  'product.productGroupList.editGroup': 'تعديل المجموعة',
  'product.productEdit.comparelPriceTip': 'عرض الأسعار ، نقل السعر الأصلي إلى \' مقارنة الأسعار \' . أدخل قيمة أقل في السعر .',
  'product.productEdit.publish': 'نشر المنتج',
  'product.productList.publish': 'نشر',
  'product.productEdit.imageSizeTips': 'نوصي بتحميل الصور بنسبة 1: 1',
  'product.comment.createCommentUserNamePlaceholder': 'يرجى إدخال اسم المستخدم',
  'product.comment.detailsCommentSearchContent': 'بحث عن تعليق',
  'product.inventoryList.selectNeedTips': 'يرجى اختيار خيار واحد على الأقل',
  'product.comment.reviewTurnOn': 'تفعيل التعليقات',
  'product.productList.operationButton': 'العمليات',
  'product.productGroupList.downloadExcelTemplateExplain': 'لعرض مثال على الشكل المطلوب.',
  'product.productEdit.productDetail': 'تفاصيل المنتج',
  'product.comment.reviewTurnOff': 'إيقاف التعليقات',
  'product.comment.detailsCommentBatchHideOperationTipsTitle': 'هل أنت متأكد من إخفاء $[X] من التعليقات المحددة؟',
  'product.comment.reviewEnable': 'تفعيل التعليقات',
  'product.comment.createCommentCreateTimePlaceholder': 'يرجى اختيار الوقت',
  'product.comment.detailsCommentIsPublic': 'عام',
  'product.comment.detailsCommentSource': 'مصدر التعليق',
  'product.inventoryModel.add': 'إضافة',
  'product.inventoryList.productName': 'اسم المنتج',
  'product.inventoryList.stockCount': 'كمية المخزون',
  'product.productEdit.multipleProductGroupName': 'اسم مجموعة سلع مكرر',
  'product.comment.detailsCommentBatchHideOperationTipsDescription': 'بمجرد إخفاء التعليقات، لن يتمكن العملاء من رؤيتها على الموقع',
  'product.productEdit.deleteNormsTitle': 'حذف عنوان خيار المنتج / قيمة خيار المنتج',
  'product.productEdit.normsValue': 'القيمة',
  'product.comment.batchTurnOffOperationTipsTitle': 'هل أنت متأكد من إيقاف التعليقات ل $[X] منتجات؟',
  'product.inventoryModel.selected': 'تم اختيار',
  'product.productGroupList.import': 'إضافة',
  'product.productGroupList.addNewGroup': 'إضافة مجموعة جديدة',
  'product.productList.productStatus': 'الحالة',
  'product.productList.search': 'تحقق',
  'product.comment.helpCenter': 'هل تحتاج مساعدة؟',
  'product.inventoryModel.state': '',
  'product.inventoryList.product': 'المنتج',
  'product.productEdit.templateOfCSV': 'نموذج CSV',
  'product.comment.createCommentProductName': 'اسم المنتج',
  'product.common.allProducts': 'كل المنتجات',
  'product.productEdit.helpImportText': 'هل تحتاج المساعدة لتحميل المنتجات؟',
  'product.comment.createCommentPictureNumberMax': 'يمكن إضافة ٩ صور كحد أقصى',
  'product.productGroupList.importLocalFile': 'الاستيراد محليا',
  'product.productEdit.applyToAllTip': 'تعيين السعر لجميع أنواع مواصفات المنتج',
  'product.productEdit.normsInfo': 'تفاصيل الوصف',
  'product.productList.stockNoTrack': 'من غير تتبع المخزون',
  'product.comment.selectionItems': 'تم اختيار $[X] تعليقات',
  'product.productEdit.importSKUCoverSameProduct': 'سيؤدي تحميل المنتجات إلى استبدال أي منتجات موجودة بنفس اسم المنتج ',
  'product.comment.createForStore': 'من المتجر',
  'product.productEdit.importNoCover': 'لن يودي تحميل المنتجات إلى استبدال أي منتجات موجودة بنفس اسم المنتج ',
  'product.productEdit.importPreviewTip': 'ذا واجهت أي خطأ اثناء التحميل ، يمكنك إلغاء التحميل و تعديل الملف ثم إعادة التحميل مرة أخرى',
  'product.productEdit.addPictureByURL': 'أضف صورة من URL',
  'product.productEdit.preview': 'عرض',
  'product.productEdit.importConentSuccess': 'تم تحميل goodsNum منتجات، إجمالي skuNum SKU و imgNum صورة بنجاح إلى Pandova. ',
  'product.inventoryModel.keepSale': 'مواصلة البيع عند نفاد  المخزون',
  'product.inventoryModel.strategy': 'تعديل خطة المخزون بالجملة',
  'product.productEdit.importHelp': 'هل تحتاج مساعدة؟',
  'product.productGroupList.selectFile': 'إضافة ملف',
  'product.comment.createCommentUserSurname': 'اسم عائلة المستخدم',
  'product.comment.detailsCommentContent': 'محتوى التعليق',
  'product.comment.detailsCommentBatchDeleteOperationTipsDescription': 'يرجى ملاحظة أنه لن يتم استرداد التعليقات المحذوفة ولن يتم عرضها في الموقع.',
  'product.productEdit.pastePictureUrl': 'لصق عنوان URL للصورة',
  'product.comment.createForCustomer': 'من العميل',
  'product.productGroupList.dragFile': 'أو قم بسحب وإفلات الملف للتحميل',
  'product.productEdit.normsName': 'اسم الوصف',
  'product.comment.batchTurnOffConfirm': 'إيقاف',
  'product.productList.status': 'الحالة',
  'product.comment.batchTurnOffOperationSuccess': 'تم إيقاف التعليقات بنجاح',
  'product.productGroupList.selectOther': 'تغيير الملف',
  'product.comment.batchTurnOnCancel': 'إلغاء',
  'product.productEdit.learnMoreImport': 'للمزيد من المعلومات',
  'product.productList.stockAmount': 'المخزون',
  'product.comment.createComment': 'إضافة تعليق',
  'product.comment.batchTurnOnConfirm': 'تفعيل',
  'product.productEdit.importLoadingModalTitle': 'قيد التحميل',
  'product.productEdit.importInfoTip': 'سيتم تحميل تقريبا',
  'product.productList.batchProductPublish': 'تفعيل  منتجات بالجملة',
  'product.productList.filter': 'فلاتر',
  'product.productEdit.batchEditRecover': 'استعادة',
  'product.productEdit.draftOrPublish': 'مفعل / مسودة',
  'product.productGroupList.requireGroupName': 'الرجاء إدخال اسم المجموعة',
  'product.comment.detailsEdit': 'تعديل',
  'product.productEdit.uploadAndGoOn': 'تحميل و استمرار',
  'product.comment.detailsCommentUser': 'الاسم',
  'product.comment.createContentOptional': 'أدخل المحتوى(اختياري)',
  'product.productEdit.multipleNorms': 'مواصفات متعددة',
  'product.comment.createCommentScorePlaceholder': 'يرجى إعطاء تقييمك',
  'product.productList.batchProductUnPublish': 'إزالة منتجات بالجملة',
  'product.comment.detailsCommentBatchPublicOperationSuccess': 'عرض التعليقات',
  'product.productEdit.selectSkuImage': 'اختر صورة المنتج',
  'product.productGroupList.importExcel': 'إضافة ملف اكسيل',
  'product.productList.addProduct': 'إضافة منتج جديد',
  'product.comment.createPictureOptional': 'إضافة تعليق بصورة (اختياري)',
  'product.productEdit.importSKUSummaryImages': 'صورة',
  'product.inventoryList.title': 'إدارة المخزون',
  'product.productGroupList.importSuccess': 'تم الإضافة بنجاح',
  'product.productEdit.batchEditWeight': 'تعديل الوزن',
  'product.inventoryList.updateStrategy': 'تحديث خطة المخزون بالجملة',
  'product.productEdit.importSame': 'استبدال نفس المنتجات، سيتم استعمال القيم الحالية لأي عمود فارغ ',
  'product.productEdit.batchEditPrice': 'تعديل السعر',
  'product.productEdit.importProductSummaryTip': 'منتجات، إجمالي ',
  'product.productEdit.trueProduct': ' العناصر المادية',
  'product.inventoryList.sku': 'SKU',
  'product.productEdit.virtualProduct': 'السلع الافتراضية',
  'product.productEdit.saveProduct': 'حفظ المنتج',
  'product.productEdit.salePriceValidateTip': 'لا يمكن أن يكون سعر البيع أعلى من السعر الأصلي',
  'product.productEdit.weight': 'الوزن',
  'product.comment.detailsCommentBatchPublickOperationTipsTitle': 'هل أنت متأكد من عرض $[X] من التعليقات المحددة؟',
  'product.productEdit.productName': 'اسم المنتج',
  'product.productEdit.applyToAll': 'الكل',
  'product.productEdit.importImageSumaryTip': 'صورة. لن يؤدي تحميل المنتجات إلى استبدال أي منتجات موجودة بنفس اسم المنتج',
  'product.comment.detailsCommentTotal': 'إجمالي $[X] تعليقات',
  'product.productEdit.importSKUSummaryTip': ' SKU و ',
  'product.productList.deleteText': 'حذف',
  'product.comment.detailsCommentBatchHideOperationSuccess': 'إخفاء التعليقات',
  'product.productList.name': 'اسم المنتج',
  'product.productGroupList.adminRun': 'تنفيذ الخلفية',
  'product.productEdit.requireProductName': 'الرجاء إدخال اسم المنتج',
  'product.productEdit.productGroup': 'مجموعة المنتج',
  'product.productEdit.shortDescription': 'الوصف',
  'product.productGroupList.close': 'إغلاق',
  'product.productList.productName': 'اسم المنتج',
  'product.inventoryList.updateStock': 'تحديث كمية المخزون بالجملة',
  'product.comment.createCommentCreateTime': 'وقت التعليق',
  'product.productGroupList.groupName': 'اسم المجموعة',
  'product.productList.unpublish': 'عدم نشر',
  'product.productEdit.importProductByCSV': 'تحميل المنتجات عن طريق CSV',
  'product.inventoryModel.tip': 'سيتم تعديل المواصفات المتعددة للمنتج',
  'product.comment.detailsAllComment': 'كل التعليقات',
  'product.productEdit.addNormsRepeatTips': 'الخيارات  موجودة بالفعل',
  'product.productGroupList.importFail': 'فشل في الإضافة',
  'product.comment.editComment': 'تعديل التعليقات',
  'product.productEdit.imporCover': 'سيؤدي تحميل المنتجات إلى استبدال أي منتجات موجودة بنفس اسم المنتج',
  'product.productEdit.overStockSelling': 'مواصلة البيع عند نفاد  المخزون',
  'product.productList.importProduct': 'إضافة ملف',
  'product.inventoryModel.stopSale': 'وقف البيع عند نفاد المخزون',
  'product.comment.batchTurnOffOperationTipsDescription': 'بمجرد إيقاف التعليقات، لن يتم عرض تعليقات المنتج ولا يمكن للعملاء إضافة  أي تعليق',
  'product.productEdit.editProduct': 'تعديل المنتج',
  'product.productEdit.stockCount': 'كمية المخزون',
  'product.productEdit.editNormsDescription': 'يحتوي هذا المنتج على خيارات متعددة من حيث الحجم واللون وما إلى ذلك.',
  'product.comment.reviewCustomer': 'تعليق العميل',
  'product.inventoryList.strategy': 'خطة المخزون',
  'product.productEdit.importConentFail': 'لم يتم تحميل المعلومات التالية. يرجى إضافة المعلومات على صفحة التعديل الخاصة بالمنتج',
  'product.comment.detailsDelete': 'حذف',
  'product.productEdit.multipleProductName': 'اسم منتج مكرر',
  'product.productEdit.deleteNormsContent': 'سيتم مسح معلومات SKU ذات الصلة بمجرد الحذف. هل ترغب في الاستمرار في الحذف',
  'product.productEdit.uploadCSVErrorTip': 'حدث خطأ في تحميل الملف، يرجى إصلاح الخطأ والمحاولة مرة أخرى',
  'product.productEdit.productSkuImage': 'صورة المنتج',
  'product.productEdit.originalPrice': 'السعر الأصلي',
  'product.productEdit.trackStock': 'تتبع كمية المخزون',
  'product.productGroupList.productCount': 'المنتجات',
  'product.comment.average': 'معدل التقييم',
  'product.productEdit.addNorms': 'وصف جديد',
  'product.comment.detailsCommentBatchDeleteOperationTipsTitle': 'هل أنت متأكد من حذف $[X] من التعليقات المحددة؟',
  'product.productEdit.productType': 'نوع المنتج',
  'product.productList.productGroup': 'المجموعات',
  'product.comment.createCommentIsPublic': 'عام',
  'product.comment.batchTurnOffCancel': 'إلغاء',
  'product.productEdit.productPublishStatus': 'حالة المنتج',
  'product.comment.createCommentPageTitle': 'تعليق المتجر',
  'product.comment.detailsBatchOperationHide': 'إخفاء',
  'product.productEdit.importLoadingModalContent': 'قد تتطلب عملية التحميل بعضا من الوقت، يمكنك إغلاق هذه الصفحة و الاستمرار في أي إجراءات أخرى، لن يؤثر ذلك على عملية تحميل المنتجات',
  'product.productEdit.editText': 'تعديل',
  'product.inventoryList.stock': 'كمية المخزون',
}
