export default {
  'sms.smsCount.type': 'Type',
  'sms.smsPurchaseList.rechargeUser': 'Recharge person',
  'sms.smsPurchaseList.item': '',
  'sms.smsPurchaseList.smsPurchaseTitle': 'SMS recharge',
  'sms.smsCount.content': 'SMS Content',
  'sms.smsCount.scene': 'SMS Type',
  'sms.smsPurchaseList.rechargeState': 'Status',
  'sms.smsPurchaseList.used': 'Used',
  'sms.smsCount.sendNumber': 'Sent Quantity',
  'sms.animation': 'Automatic',
  'sms.commandShift': 'Manual',
  'sms.smsPurchaseList.rechargeTime': 'Recharge time',
  'sms.smsPurchaseList.smsPurchaseSMS': 'SMS',
  'sms.smsCount.from': 'Source',
  'sms.smsPurchaseList.rechargeAmount': 'Recharge amount',
  'sms.smsCount.recharge': 'SMS Recharge',
  'sms.smsPurchaseList.smsResidual': 'Left',
  'sms.smsPurchaseList.rechargeNumber': 'Recharge quantity',
  'sms.smsPurchaseList.recharge': 'SMS Recharge',
  'sms.smsCount.sendDate': 'Send Time',
  'sms.smsPurchaseList.title': 'SMS recharge record',
  'sms.smsCount.title': 'SMS Records',
  'sms.smsPurchaseList.residual': 'Remaining',
}
