import antdZhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import momentZhCN from 'moment/locale/zh-cn'
import account from './zh-CN/account'
import charge from './zh-CN/charge'
import comment from './zh-CN/comment'
import common from './zh-CN/common'
import customer from './zh-CN/customer'
import discount from './zh-CN/discount'
import helpCenter from './zh-CN/help-center'
import home from './zh-CN/home'
import onlineShop from './zh-CN/online-shop'
import order from './zh-CN/order'
import portal from './zh-CN/portal'
import product from './zh-CN/product'
import report from './zh-CN/report'
import setting from './zh-CN/setting'
import sms from './zh-CN/sms'
import application from './zh-CN/application'
import activity from './zh-CN/activity'

const components = {
  antLocale: antdZhCN,
  momentName: 'zh-cn',
  momentLocale: momentZhCN,
}

export default {
  message: '-',
  ...components,
  ...account,
  ...charge,
  ...comment,
  ...common,
  ...customer,
  ...discount,
  ...helpCenter,
  ...home,
  ...onlineShop,
  ...order,
  ...portal,
  ...product,
  ...report,
  ...setting,
  ...sms,
  ...application,
  ...activity,
}
