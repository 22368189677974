export default {
  'charge.chargeList.descriptionOfFees': 'Description',
  'charge.chargeList.addGroup': 'Add Category',
  'charge.chargeList.inputAmount': 'Enter amount',
  'charge.chargeList.editGroup': 'Edit',
  'charge.chargeList.title': 'Expenses',
  'charge.chargeList.groupDescription': 'Description',
  'charge.chargeList.groupTitle': 'Expense Categories',
  'charge.chargeList.date': 'Date',
  'charge.chargeList.require': 'Cannot be empty',
  'charge.chargeList.amount': 'Amount',
  'charge.chargeList.addTitle': 'New Expense',
  'charge.chargeList.selectType': 'Select category',
  'charge.chargeList.delete': 'Delete',
  'charge.deleteSuccess': 'Delete successfully',
  'charge.typeName': 'Title',
  'charge.chargeList.edit': 'Edit',
  'charge.chargeList.groupName': 'Title',
  'charge.chargeGroup.title': 'Expense Categories',
  'charge.makeSureDelete': 'Are you sure you want to delete?',
  'charge.chargeList.editTitle': 'Edit',
  'charge.chargeList.selectDate': 'Select Date',
  'charge.chargeList.operation': 'Operation',
}
