export default {
  'report.saleOverview.pagePath': '页面地址',
  'report.saleOverview.discounts': '折扣金额',
  'report.selectTextFifteenDaysAgo': '过去15天',
  'report.saleOverview.totalOrdersTIps': '在统计时间内，所有销售渠道的订单总数。',
  'report.saleOverview.checkOut': '到达结账',
  'report.saleOverview.unitPriceTIps': '在统计时间内，每个客户的付款金额。',
  'report.saleOverview': '销售概览',
  'report.customerStatistics.customerTotal': '顾客总数',
  'report.saleOverview.expenses': '店内开支',
  'report.saleOverview.orderNumber': '订单编号',
  'report.saleOverview.orderCreateTimes': '下单时间',
  'report.saleOverview.hotPageTips': '在统计时间内，客户进入在线店铺的热门网页，以及与每个目标网页相关联的访问次数。',
  'report.customerStatistics.unitPrice': '客单价',
  'report.saleOverview.sessionsConverted': '已转换的流量',
  'report.customerStatistics.returningVisitors': '回头客数量',
  'report.saleOverview.offlineSales': '线下销售额',
  'report.saleOverview.storeOrderConversion': '在线店铺订单转化率',
  'report.saleOverview.totalOrders': '总订单数',
  'report.saleOverview.salesMargin': '销售毛利',
  'report.saleOverview.onlineSales': '线上销售额',
  'report.saleOverview.shippingSpend': '运费',
  'report.customerStatistics.ordersTop': '订单最多的顾客Top10',
  'report.saleOverview.unitPrice': '客单价',
  'report.selectTextYesterday': '昨天',
  'report.comparison': '较',
  'report.saleOverview.salesVolumeTIps': '在统计时间内，净销售额（总销售额减去折扣和退货金额）加上税费和运费。',
  'report.saleOverview.monetaryUnit': '货币单位',
  'report.selectTextSevenDaysAgo': '过去7天',
  'report.title': '数据报表',
  'report.saleOverview.salesVolume': '销量',
  'report.customerStatistics': '顾客统计',
  'report.saleOverview.orderTransferTIps': '在统计时间内，达成订单的访问次数占总访问次数的百分比。',
  'report.saleOverview.addToCart': '已添加到购物车',
  'report.saleOverview.hotSaleTopTips': '统计时间内，销售额前10的商品。',
  'report.customerStatistics.newCustomers': '新顾客数',
  'report.saleOverview.times': '次',
  'report.saleOverview.product': '商品',
  'report.selectTextToday': '今天',
  'report.saleOverview.hotSaleTop': 'Top10热销商品(按单位销量)',
  'report.saleOverview.onlineVisits': '在线访问量',
  'report.selectTextThirtyDaysAgo': '过去30天',
  'report.saleOverview.hotPage': '热门页面',
  'report.saleOverview.totalSales': '总销售额',
  'report.saleOverview.salesAmount': '销售额',
  'report.customerStatistics.amountTop': '订单金额最多的顾客Top10',
  'report.saleOverview.orderPaymentAmount': '支付金额',
  'report.saleOverview.pageViewCount': '浏览量',
  'report.dayAfter': '天前',
}
