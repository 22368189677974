export default {
  'common.clearText': '清除',
  'common.NeedHelp': '需要帮助？',
  'common.adminMenu.reviews': '评价',
  'common.operationText': '操作',
  'common.adminMenu.taxSetting': '税费设置',
  'common.adminMenu.chargeGroupList': '手工记账分组',
  'common.adminMenu.productGroup': '商品分组',
  'common.resetText': '重置',
  'common.minValue': '最小值',
  'common.adminMenu.staff': '员工管理',
  'common.adminMenu.smsRecords': '短信统计',
  'common.adminMenu.dataReview': '销售概览',
  'common.totalAmount': '总共',
  'common.adminMenu.analytics': '数据',
  'common.adminMenu.rechargeRecords': '充值记录',
  'common.adminMenu.paymentSetting': '收款设置',
  'common.moreFilters': '更多筛选器',
  'common.validateNumber': '请输入数字',
  'common.adminMenu.chargeList': '手工记账列表',
  'common.batchOperation': '批量操作',
  'common.deleteText': '删除',
  'common.lessThan': '小于',
  'common.remove': '移除',
  'common.adminMenu.shopTheme': '店铺主题',
  'common.menu.product': '产品',
  'common.finishText': '完成',
  'common.adminMenu.smsSetting': '短信管理',
  'common.adminMenu.product': '商品',
  'common.oneScore': '1分',
  'common.back': '返回',
  'common.twoScore': '2分',
  'common.saveText': '保存',
  'common.adminMenu.customerGroup': '顾客分组',
  'common.cancelText': '取消',
  'common.confirm': '确认',
  'common.uploadLimitSize': '图片不得大于10MB',
  'common.adminMenu.onlineShopPage': '页面',
  'common.adminMenu.message': '营销消息',
  'common.menu.customPage': '自定义页面',
  'common.adminMenu.shippingFee': '运费设置',
  'common.adminMenu.accountSetting': '账户设置',
  'common.threeScore': '3分',
  'common.adminMenu.domain': '域名',
  'common.networkError': '网络已断开，请检查网络连接',
  'common.fiveScore': '5分',
  'common.confirmBtnText': '确定',
  'common.greaterThan': '大于',
  'common.adminMenu.onlineShop': '在线商店',
  'common.formValidate.codeText': '字符',
  'common.adminMenu.charge': '手工记账',
  'common.menu.collection': '系列',
  'common.memoText': '备注',
  'common.openOption': '开启',
  'common.adminMenu.customerList': '顾客列表',
  'common.uploadText': '上传',
  'common.adminMenu.setting': '设置',
  'common.operateFail': '操作失败',
  'common.formValidate.errorInfoText': '信息填写错误，请检查填写内容。',
  'common.adminMenu.customerAnalytics': '顾客报表',
  'common.formValidate.maxLengthTip': '内容超长，不能超过',
  'common.selectTip': '请选择',
  'common.adminMenu.home': '首页',
  'common.networkTimeout': '网络不稳定，请刷新重试',
  'common.adminMenu.shopInfo': '店铺信息',
  'common.discountCodeLengthTip': '请输入3-50位折扣码',
  'common.adminMenu.onlineShopNav': '导航',
  'common.adminMenu.discount': '折扣',
  'common.adminMenu.feedback': '意见反馈',
  'common.menu.home': '主页',
  'common.closeOption': '关闭',
  'common.maxValue': '最大值',
  'common.requireNormsTip': '请选择规格',
  'common.adminMenu.inventoryList': '库存列表',
  'common.uploadLimitFormat': '只能上传jpg/png格式的图片',
  'common.adminMenu.order': '订单',
  'common.fourScore': '4分',
  'common.adminMenu.productList': '商品列表',
  'common.adminMenu.customer': '顾客',
  'common.editText': '编辑',
  'common.serverErrorTip': '服务异常，请稍后重试',
  'common.menu.search': '搜索',
  'common.requireNormsInfoTips': '请完善规格信息',
}
