export default {
  'customer.pleaseSelectScope': 'اخترالعميل - مجموعة',
  'customer.deleteCustomer.sureToDelete': 'هل أنت متأكد من الحذف؟',
  'customer.addCustomer.addAddress': 'إضافة عنوان جديد',
  'customer.customerDetail.remark': 'ملاحظات',
  'customer.addCustomer.state': 'المحافظة',
  'customer.createMessage.sendMessage': 'إرسال رسالة',
  'customer.addCustomer.name': 'الاسم',
  'customer.addCustomer.internationalCode': 'رمز البلد ',
  'customer.pleaseEnterAddress': 'ادخل العنوان',
  'customer.createMessage.mailContent': 'محتوي الرسالة',
  'customer.messageList.messageTitle': 'عنوان الرسالة',
  'customer.createMessage.selectOrderJustONe': 'عملاء ذوي طلب واحد في آخر ٣٠ يوم',
  'customer.addCustomer.deleteAddress': 'حذف',
  'customer.selectTextCustomer': 'العملاء',
  'customer.addCustomerGroup.group': 'إضافة إلى مجموعة',
  'customer.createMessage.smsContent': 'محتوي الرسالة القصيرة',
  'customer.customerDetail.lastLanding': 'آخر ظهور منذ',
  'customer.messageDetail.sendState': 'الحالة',
  'customer.messageDetail.successCustomer': 'الرسائل الناجحة',
  'customer.createMessage.selectAllCustomer': 'اختر جميع العملاء',
  'customer.operation': 'أوامر',
  'customer.pleaseEnterMail': 'ادخال البريد الالكتروني',
  'customer.addCustomer.city': 'المدينة',
  'customer.shippingAddress': 'عنوان التسليم',
  'customer.billingAddress': 'عنوان الدفع',
  'customer.noDataTips': 'لا توجد بيانات حتى الآن',
  'customer.selectTextEmail': 'البريد الالكتروني',
  'customer.createMessage.selectSubscriptionMail': 'مشتركي البريد الإلكتروني',
  'customer.deleteCustomer.cancel': 'إلغاء',
  'customer.selectTextSuccess': 'تم الارسال',
  'customer.selectTextCustomerGroup': 'مجموعة العملاء',
  'customer.addCustomer.lastName': 'الاسم الأخير',
  'customer.addCustomerGroup.selectAll': 'اختر الكل',
  'customer.customerDetail.orderAmount': 'إجمالي الطلب',
  'customer.customerDetail.group': 'المجموعة',
  'customer.messageDetail.activityName': 'اسم العرض',
  'customer.addCustomer.setDefaultAddress': 'تعيين كعنوان رئيسي',
  'customer.placeSelectCountry': 'اختر البلد',
  'customer.messageDetail.messageContent': 'محتوى الرسالة',
  'customer.addCustomerGroup': 'إنشاء مجموعة',
  'customer.pleaseEnterLastName': 'ادخال اللقب',
  'customer.createMessage.selectCustomerAndGroup': 'اختر العميل / مجموعة العملاء',
  'customer.addCustomer.setBillingAddress': 'تعيين كعنوان الدفع',
  'customer.customerDetail.howLongAgo': 'قبل',
  'customer.addCustomerGroup.selectCustomer': 'اختر العميل',
  'customer.customerGroupList': 'مجموعات العملاء',
  'customer.customerDetail.shippingAddress': 'عنوان التسليم',
  'customer.delete': 'حذف',
  'customer.customerDetail.viewOrderDetail': 'عرض الطلب',
  'customer.addCustomer.country': 'البلد',
  'customer.addCustomer.streetAddress': 'العنوان',
  'customer.selectGroup': 'ادخل  مجموعة',
  'customer.addCustomer.mail': 'البريد الإلكتروني',
  'customer.createMessage.emailNote': 'ملاحظة: يمكنك فقط إرسال ما يصل إلى 100 رسالة بريد إلكتروني في اليوم',
  'customer.customerDetail.recentOrders': 'آخر المشتريات',
  'customer.pleaseEnterCity': 'اختر المدينة',
  'customer.deleteCustomer.unBack': 'لا يمكن الرجوع بعد الحذف',
  'customer.addCustomer.remark': 'ملاحظات',
  'customer.addMessage': 'ارسال رسالة تسويقية',
  'customer.addCustomer.billingAddress': 'عنوان الدفع',
  'customer.customerDetail.orderList': 'الطلبات الحديثة',
  'customer.messageDetail.sendChannel': 'طريقة الإرسال',
  'customer.addCustomer.zipCode': 'الرمز البريدي (اختياري)',
  'customer.selectTextAll': 'الكل',
  'customer.customerList.name': 'الاسم',
  'customer.customerList.mailSelect': 'حالة الاشتراك في البريد الإلكتروني',
  'customer.addCustomer.shippingPhone': 'رقم الموبايل',
  'customer.addCustomer.phone': 'رقم الموبايل',
  'customer.subscribed': 'مشترك',
  'customer.createMessage.activityName': 'اسم العرض',
  'customer.addCustomer.cancel': 'إلغاء',
  'customer.createMessage.selectTopCustomer': 'أفضل 10 عملاء من حيث المبلغ الذي تم إنفاقه في اخر30 يوم',
  'customer.addCustomer.defaultAddress': 'العنوان الاساسي',
  'customer.messageDetail.messageTitle': 'عنوان الرسالة',
  'customer.messageDetail.failCustomer': 'فشل الارسال',
  'customer.addCustomerGroup.add': 'إضافة',
  'customer.customerDetail.orderNumber': 'رقم الطلب',
  'customer.addCustomerGroup.customerGroupName': 'اسم المجموعة',
  'customer.customerDetail': 'عرض العميل',
  'customer.createMessage.mailName': 'عنوان الرسالة',
  'customer.detail': 'تفاصيل',
  'customer.addCustomer.save': 'حفظ',
  'customer.customerList': 'كل العملاء',
  'customer.customerList.email': 'البريد الإلكتروني',
  'customer.pleaseEnterFirstName': 'ادخال الاسم',
  'customer.editCustomer': 'تعديل',
  'customer.addCustomer': 'إضافة عميل',
  'customer.customerDetail.orderTotalSpend': 'المبلغ الإجمالي',
  'customer.addCustomerGroup.unGroup': 'بدون مجموعة',
  'customer.deleteCustomer.confirmDelete': 'نعم متأكد',
  'customer.messageList.activityName': 'اسم العرض',
  'customer.selectTextSms': 'الرسائل القصيرة',
  'customer.pleaseEnterInternationalCode': 'ادخل رمز البلد',
  'customer.customerList.phone': 'رقم الموبايل',
  'customer.editCustomerGroup': 'تعديل ',
  'customer.addCustomer.viewAllGroup': 'عرض الكل',
  'customer.customerGroupList.customerCount': 'عملاء',
  'customer.customerList.addCustomer': 'إضافة عميل',
  'customer.noAddressTips': '\'لا يوجد عنوان ، الرجاء الضغط على\' \'عنوان جديد\' لإضافة عنوان',
  'customer.customerDetail.phone': 'رقم الموبايل',
  'customer.customerDetail.mail': 'البريد الإلكتروني',
  'customer.customerDetail.billingAddress': 'عنوان الدفع',
  'customer.placeSelectStateAndGovernorate': 'اختر الولاية',
  'customer.customerList.searchName': 'بحث',
  'customer.messageDetail': 'تفاصيل الرسالة',
  'customer.createMessage.title': 'عنوان الرسالة',
  'customer.addCustomer.basicInformation': 'معلومات عامة',
  'customer.messageList.sendState': 'الحالة',
  'customer.addCustomer.firstName': 'الاسم الأول',
  'customer.addCustomerGroup.search': 'بحث',
  'customer.messageList.sendChannel': 'طريقة الإرسال',
  'customer.customerGroupList.groupName': 'الاسم',
  'customer.pleaseSelectMessageType': 'الرجاء تحديد طريقة الارسال',
  'customer.addCustomer.shippingAddress': 'عنوان التسليم',
  'customer.addCustomerGroup.save': 'حفظ',
  'customer.createMessage.selectNoOrder': 'عملاء من غير طلبات',
  'customer.messageList': 'رسائل',
  'customer.customerDetail.orderCount': 'الطلبات',
  'customer.createMessage.content': 'محتوى الرسالة',
  'customer.makeSureDelete': 'تأكيد الحذف ؟',
  'customer.addCustomer.handle': 'اسم الموظف',
  'customer.createMessage.sendChannel': 'طريقة الإرسال',
  'customer.addCustomer.pleaseEnterGroupName': 'ادخل اسم المجموعة',
  'customer.editAddress': 'تعديل',
  'customer.pleaseEnterMailContent': 'ادخل محتوي الرسالة',
  'customer.addCustomerGroup.customerList': 'العملاء',
  'customer.selectTextFail': 'فشل',
  'customer.addCustomer.group': 'مجموعة',
  'customer.selectTextBeSending': 'يتم الارسال',
  'customer.notSubscribed': 'غير مشترك',
  'customer.customerList.lastVisit': 'آخر ظهور',
  'customer.createMessage.customerScopeType': 'مجموعة العملاء',
  'customer.deleteSuccess': 'تم الحذف',
  'customer.pleaseEnterHandle': 'الرجاء إدخال المعالج',
  'customer.pleaseEnterMessageName': 'الرجاء إدخال اسم الحملة التسويقية',
  'customer.createMessage.smsName': 'عنوان الرسالة القصيرة',
  'customer.edit': 'تعديل',
  'customer.pleaseEnterPhone': 'ادخال رقم الهاتف',
  'customer.addCustomerGroup.cancel': 'إلغاء',
  'customer.addCustomer.address': 'العنوان',
}
