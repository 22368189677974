export default {
  'product.productEdit.salePrice': 'Price',
  'product.productGroupList.downloadExcelText': 'Download',
  'product.comment.createCommentUserSurnamePlaceholder': 'Please type in the user surname',
  'product.productEdit.importSKUNotCoverSameProduct': 'The imported products will not cover the existing products with same name',
  'product.productEdit.batchEditCostPrice': 'Edit cost price',
  'product.productGroupList.operation': 'Operations',
  'product.productGroupList.saveGroup': 'Add Collection',
  'product.comment.batchTurnOnOperationSuccess': 'Turn on reviews',
  'product.comment.productName': 'Product name',
  'product.productEdit.importMultipleTip': 'Variant',
  'product.comment.reviewShow': 'Show the review',
  'product.comment.detailsCommentBatchPublickOperationTipsDescription': 'Once choose to show reviews,  reviews will be visible to your customers on the website',
  'product.productGroupList.addGroup': 'New Collections',
  'product.productEdit.sku': 'SKU (Stock Keeping Unit)',
  'product.productList.title': 'All Products',
  'product.comment.createCommentUserName': 'Name of the reviewer',
  'product.productEdit.requireNormsName': 'Please enter option name',
  'product.productEdit.pricingTitle': 'Pricing',
  'product.productEdit.selectUnit': 'Select item unit',
  'product.productEdit.importSuccess': 'The product has been imported.',
  'product.comment.batchTurnOnOperationTipsTitle': 'Are you sure to turn on reviews for $[X] products?',
  'product.comment.detailsCommentSearchScore': 'Score',
  'product.comment.detailsBatchOperationShow': 'Display',
  'product.productEdit.batchEditOriginalPrice': 'Edit original price',
  'product.inventoryModel.setting': 'Settings',
  'product.productEdit.batchEditStock': 'Edit inventory',
  'product.comment.createCommentAverage': 'Review score',
  'product.productGroupList.coverText': 'Products with the same names will be overwritten. If the uploaded products are multi specification products, they will overwrite the original specifications of the products.',
  'product.productList.selectNeedTips': 'Select at least one item',
  'product.comment.detailsBatchOperationClose': 'Hidden',
  'product.comment.detailsCommentBatchDeleteOperationSuccess': 'Delete reviews',
  'product.comment.reviewHide': 'Hide the review',
  'product.productEdit.costPrice': 'Cost price',
  'product.productEdit.normsEditExplain': 'Add at most 3 product options',
  'product.inventoryList.images': 'Product image',
  'product.productEdit.addNormsPlaceHolder': 'Enter option values',
  'product.comment.detailsCommentSearchIsPublic': 'Review visibility',
  'product.productEdit.importLoadingModalClose': 'Close',
  'product.productGroupList.excelTemplate': 'Sample Excel template',
  'product.productEdit.productNumberTip': 'Enter product quantity',
  'product.productEdit.images': 'Product Images',
  'product.comment.reviewStore': 'The store review',
  'product.productEdit.productNorms': 'Product Options',
  'product.productEdit.title': 'Collections',
  'product.comment.batchTurnOnOperationTipsDescription': 'Once turning on reviews, the related product reviews will be displayed and customers can add reviews',
  'product.productEdit.originalPriceValidateTip': 'Compare at price cannot be less than selling price',
  'product.productEdit.importSuccessTip': 'Product importing has been completed.',
  'product.productEdit.noWeightTip': 'Please fill in the weight of products',
  'product.productGroupList.editGroup': 'Edit Collection',
  'product.productEdit.comparelPriceTip': 'To show a reduced price, move the original price into Compare at price. Enter a lower value into Price.',
  'product.productEdit.publish': 'Publish Product',
  'product.productList.publish': 'Active',
  'product.productEdit.imageSizeTips': 'We recommend uploading images at a ratio of 1:1',
  'product.comment.createCommentUserNamePlaceholder': 'Please type in the user name',
  'product.comment.detailsCommentSearchContent': 'Search review',
  'product.inventoryList.selectNeedTips': 'Select at least one item',
  'product.comment.reviewTurnOn': 'Turn on reviews',
  'product.productList.operationButton': 'Operation',
  'product.productGroupList.downloadExcelTemplateExplain': 'To view an example of the required format.',
  'product.productEdit.productDetail': 'Product Detail',
  'product.comment.reviewTurnOff': 'Turn off reviews',
  'product.comment.detailsCommentBatchHideOperationTipsTitle': 'Are you sure to hide the selected $[X] reviews?',
  'product.comment.reviewEnable': 'Turn on reviews',
  'product.comment.createCommentCreateTimePlaceholder': 'Please select time',
  'product.comment.detailsCommentIsPublic': 'Public ',
  'product.comment.detailsCommentSource': 'Source of review',
  'product.inventoryModel.add': 'Add',
  'product.inventoryList.productName': 'Product name',
  'product.inventoryList.stockCount': 'Inventory amount',
  'product.productEdit.multipleProductGroupName': 'Duplicate product collection name',
  'product.comment.detailsCommentBatchHideOperationTipsDescription': 'Once choose to hide reviews, reviews will be invisible to your customers on the website',
  'product.productEdit.deleteNormsTitle': 'Delete option title/option value',
  'product.productEdit.normsValue': 'Values',
  'product.comment.batchTurnOffOperationTipsTitle': 'Are you sure to turn off  reviews for $[X] products?',
  'product.inventoryModel.selected': 'Selected',
  'product.productGroupList.import': 'Import Products',
  'product.productGroupList.addNewGroup': 'Add New Collection',
  'product.productList.productStatus': 'Status',
  'product.productList.search': 'Apply',
  'product.comment.helpCenter': 'Need Help?',
  'product.inventoryModel.state': 'patterns will be set as status below',
  'product.inventoryList.product': 'Product',
  'product.productEdit.templateOfCSV': 'sample CSV template',
  'product.comment.createCommentProductName': 'Product name',
  'product.common.allProducts': 'All products',
  'product.productEdit.helpImportText': 'Need help importing products?',
  'product.comment.createCommentPictureNumberMax': 'A maximum of nine photos can be added',
  'product.productGroupList.importLocalFile': 'Import locally',
  'product.productEdit.applyToAllTip': 'Apply value to all multi-attributes',
  'product.productEdit.normsInfo': 'Product Variations',
  'product.productList.stockNoTrack': 'Not tracked',
  'product.comment.selectionItems': '$[X] reviews are selected',
  'product.productEdit.importSKUCoverSameProduct': 'The imported products will cover the existing products with same name',
  'product.comment.createForStore': 'By the store',
  'product.productEdit.importNoCover': 'The imported CSV will not overwrite the same products ',
  'product.productEdit.importPreviewTip': 'You are importing a document in Pandova. If error occures in the preview, please cancel importing, edit the document and try again. ',
  'product.productEdit.addPictureByURL': 'Add image from URL',
  'product.productEdit.preview': 'Preview',
  'product.productEdit.importConentSuccess': 'You have successfully imported  goodsNum products to Pandova and now have  skuNum SKU and   imgNum images',
  'product.inventoryModel.keepSale': 'Continue selling after stockout',
  'product.inventoryModel.strategy': 'Bulk edit inventory strategy',
  'product.productEdit.importHelp': 'Need help?',
  'product.productGroupList.selectFile': 'Add file',
  'product.comment.createCommentUserSurname': 'Surname of the reviewer',
  'product.comment.detailsCommentContent': 'Review content',
  'product.comment.detailsCommentBatchDeleteOperationTipsDescription': 'Please note: The deleted review will not be recovered and the website will not display deleted reviews. ',
  'product.productEdit.pastePictureUrl': 'Paste image URL',
  'product.comment.createForCustomer': 'By the customer',
  'product.productGroupList.dragFile': 'or drag-and-drop file to upload',
  'product.productEdit.normsName': 'Option Name',
  'product.comment.batchTurnOffConfirm': 'Turn off',
  'product.productList.status': 'Status',
  'product.comment.batchTurnOffOperationSuccess': 'Turn off reviews',
  'product.productGroupList.selectOther': 'Change file',
  'product.comment.batchTurnOnCancel': 'Cancel',
  'product.productEdit.learnMoreImport': 'Learn more',
  'product.productList.stockAmount': 'Stock',
  'product.comment.createComment': 'Create a review',
  'product.comment.batchTurnOnConfirm': 'Turn on',
  'product.productEdit.importLoadingModalTitle': 'Product importing is in progress',
  'product.productEdit.importInfoTip': 'You will import about',
  'product.productList.batchProductPublish': 'Batch Active ',
  'product.productList.filter': 'Filters',
  'product.productEdit.batchEditRecover': 'restore',
  'product.productEdit.draftOrPublish': 'Active/Draft',
  'product.productGroupList.requireGroupName': 'Please enter collection name',
  'product.comment.detailsEdit': 'Edit',
  'product.productEdit.uploadAndGoOn': 'Upload and continue',
  'product.comment.detailsCommentUser': 'Reviewed by',
  'product.comment.createContentOptional': 'Type in content(optional)',
  'product.productEdit.multipleNorms': 'Options',
  'product.comment.createCommentScorePlaceholder': 'Please give your score',
  'product.productList.batchProductUnPublish': 'Batch Draft ',
  'product.comment.detailsCommentBatchPublicOperationSuccess': 'Display reviews',
  'product.productEdit.selectSkuImage': 'Select Product Image',
  'product.productGroupList.importExcel': 'Import Products in Excel',
  'product.productList.addProduct': 'New Product',
  'product.comment.createPictureOptional': 'Review with a photo (optional)',
  'product.productEdit.importSKUSummaryImages': 'images.',
  'product.inventoryList.title': 'Inventory management',
  'product.productGroupList.importSuccess': 'items imported successfully',
  'product.productEdit.batchEditWeight': 'Edit weight',
  'product.inventoryList.updateStrategy': 'bulk update of inventory strategy',
  'product.productEdit.importSame': 'Overwrite the same products, any existing values will be used for missing columns.',
  'product.productEdit.batchEditPrice': 'Edit price',
  'product.productEdit.importProductSummaryTip': 'products with',
  'product.productEdit.trueProduct': 'Physica product',
  'product.inventoryList.sku': 'SKU',
  'product.productEdit.virtualProduct': 'Virtual product',
  'product.productEdit.saveProduct': 'Save Product',
  'product.productEdit.salePriceValidateTip': 'Selling price cannot be greater than compare at price',
  'product.productEdit.weight': 'Weight',
  'product.comment.detailsCommentBatchPublickOperationTipsTitle': 'Are you sure to display the selected $[X] reviews?',
  'product.productEdit.productName': 'Product Title',
  'product.productEdit.applyToAll': 'All applications',
  'product.productEdit.importImageSumaryTip': 'images. The imported products will not cover the existing products with same name',
  'product.comment.detailsCommentTotal': '$[X] reviews in total',
  'product.productEdit.importSKUSummaryTip': 'SKU and ',
  'product.productList.deleteText': 'Delete',
  'product.comment.detailsCommentBatchHideOperationSuccess': 'Hide reviews',
  'product.productList.name': 'Product',
  'product.productGroupList.adminRun': 'Background Execution',
  'product.productEdit.requireProductName': 'Please enter product name',
  'product.productEdit.productGroup': 'Product Collection',
  'product.productEdit.shortDescription': 'Short Description',
  'product.productGroupList.close': 'Close',
  'product.productList.productName': 'Product Title',
  'product.inventoryList.updateStock': 'bulk update of inventory amount',
  'product.comment.createCommentCreateTime': 'Review time',
  'product.productGroupList.groupName': 'Collection Title',
  'product.productList.unpublish': 'Draft',
  'product.productEdit.importProductByCSV': 'Import products by CSV',
  'product.inventoryModel.tip': 'multi-attributes will be modified',
  'product.comment.detailsAllComment': 'All reviews',
  'product.productEdit.addNormsRepeatTips': 'Option already exists',
  'product.productGroupList.importFail': 'items failed to import',
  'product.comment.editComment': 'Edit reviews',
  'product.productEdit.imporCover': 'The imported CSV will overwrite the same products',
  'product.productEdit.overStockSelling': 'Continue selling when out of stock',
  'product.productList.importProduct': 'Import',
  'product.inventoryModel.stopSale': 'Stop selling after stockout',
  'product.comment.batchTurnOffOperationTipsDescription': 'Once turning off reviews, the related product reviews will not be displayed and customers can not add reviews',
  'product.productEdit.editProduct': 'edit product',
  'product.productEdit.stockCount': 'Stock count',
  'product.productEdit.editNormsDescription': 'This product has multiple options of size, color, etc. ',
  'product.comment.reviewCustomer': 'Customer review',
  'product.inventoryList.strategy': 'Inventory strategy',
  'product.productEdit.importConentFail': 'The following information is not imported. Please add it manually on the editing page of the product',
  'product.comment.detailsDelete': 'Delete',
  'product.productEdit.multipleProductName': 'Repeated product names ',
  'product.productEdit.deleteNormsContent': 'the related SKU information will be cleared once deleting. Continue to delete?',
  'product.productEdit.uploadCSVErrorTip': 'An error occurred during importing the document. Please fix error and try again later. ',
  'product.productEdit.productSkuImage': 'Product Image',
  'product.productEdit.originalPrice': 'Compare at price',
  'product.productEdit.trackStock': 'Track quantity',
  'product.productGroupList.productCount': 'Products',
  'product.comment.average': 'Average score',
  'product.productEdit.addNorms': 'New Option',
  'product.comment.detailsCommentBatchDeleteOperationTipsTitle': 'Are you sure to delete the selected $[X] reviews?',
  'product.productEdit.productType': 'Product type',
  'product.productList.productGroup': 'Collection',
  'product.comment.createCommentIsPublic': 'Public',
  'product.comment.batchTurnOffCancel': 'Cancel',
  'product.productEdit.productPublishStatus': 'Product Status',
  'product.comment.createCommentPageTitle': 'Created reviews',
  'product.comment.detailsBatchOperationHide': 'Hide',
  'product.productEdit.importLoadingModalContent': 'This process may take a while. You can close this dialog box and perform other actions, which will not affect product importing. ',
  'product.productEdit.editText': 'Edit',
  'product.inventoryList.stock': 'Inventory amount',
}
