export default {
  'application.shopMove.attentionText1': 'Customers with the same email will not be relocated.',
  'application.shopMove.attentionText2': 'Videos of products will not be relocated.',
  'application.shopMove.DataSelectErrorText': 'Please select the data to be relocated.',
  'application.shopMove.shopKey': 'API Key',
  'application.shopMove.sameProductTransfer': 'Treatment of the same product name',
  'application.facebookPixel.description': 'Get your items to sell on Facebook and track performance with Pixel feedback.',
  'application.facebookPixel.title': 'facebook pixel',
  'application.shopMove.moveContent': 'Transfer Data',
  'application.shopMove.IsMoving': 'The transfer is in progress',
  'application.shopMove.result': 'Results',
  'application.shopMove.planRepetitionErrorText': 'A transfer task already exists, please try again later!',
  'application.facebookPixel.howToGetId': 'How to get facebook pixel ID?',
  'application.googleAnalytics.description': 'Through google analytics, shop visitors can be tracked,It is helpful for marketing promotion and tracking reports. ',
  'application.facebookPixel.adsLabel': 'connect to facebook pixel',
  'application.shopMove.Jumain.twoStepText': 'Step two, obtain Jumia store authorization',
  'application.shopMove.Jumain.UserId': 'User ID',
  'application.shopMove.Jumain.APIURL': 'API URL',
  'application.shopMove.shopifyStoreAuthorization': 'Shopify Authorization',
  'application.shopMove.moveRemind': 'This process may take some time to complete, and you can view more information in the Transfer Records.',
  'application.googleAnalytics.login': 'log in google analytics',
  'application.shopMove.endTIme': 'End time',
  'application.title': 'APP',
  'application.facebookPixel.adsText': 'helps you track conversions from facebook ADS, optimize ads, build targeted audiendes',
  'application.facebookPixel.id': 'facebook ID',
  'application.googleAnalytics.howToGetId': 'How to get google analytics ID?',
  'application.shopMove.moveProductLang': 'Language of relocated products',
  'application.shopMove.Jumain.firstStepText': 'Step one, enter the Jumia API URL',
  'application.googleAnalytics.adsLabel': 'connect google analytics',
  'application.facebookPixel.login': 'log in facebook pixel',
  'application.shopMove.confirm': 'OK',
  'application.shopMove.attention': 'Note:',
  'application.shopMove.firstStepText': 'Step one, enter the Shopify store URL',
  'application.shopMove.status': 'Status',
  'application.googleAnalytics.adsText': 'add google analytics ID to track website effectiveness via generating numerous reports',
  'application.shopMove.move': 'Relocate',
  'application.shopMove.moveRecord': 'Transfer Records',
  'application.shopMove.twoStepText': 'Step two, moving data settings',
  'application.applicationList.title': 'APP management',
  'application.shopMove.lengthErrorText': 'Cannot exceed 1024 characters.',
  'application.shopMove.shopSource': 'Store source',
  'application.googleAnalytics.title': 'google analytics',
  'application.shopMove.Jumain.migrationContentInstructions': 'We will migrate your store products to Pandova',
  'application.shopMove.shopUrlPlaceholder': 'Please enter your store website',
  'application.shopMove.dutyDataLabel': 'Data to be relocated',
  'application.shopMove.threeStepDeclare3': '3.If this is your first time, click \'Allow custom app development\' to start developing apps on your store',
  'application.shopMove.threeStepDeclare4': '4.Click \'Create an app\', then enter the app name to create',
  'application.shopMove.threeStepDeclare1': '1.Click the menu \'Apps\' in Shopify ADMIN',
  'application.shopMove.threeStepDeclare2': '2.Click \'Develop apps\'',
  'application.shopMove.threeStepDeclare7': '7.Once successfully installed, copy the Shopify \'API Key\' and \'Admin API access token\' to Pandova to complete the transfer of the products',
  'application.shopMove.threeStepDeclare5': '5.Click \'Configure Admin API scopes\', set the permission for \'Customers\', \'Product\' and \'Product listings\' as \'Read and write\'',
  'application.shopMove.threeStepDeclare6': '6.Click \'Save\' and then click \'Install app\' to install the app',
  'application.shopMove.takesDate': 'It takes about 15 minutes to complete.',
  'application.shopMove.detailKnow': 'Learn details',
  'application.shopMove.Jumain.InfoText1': '1. Click \'set up\' menu at the backend of Jumia',
  'application.shopMove.Jumain.InfoText2': '2. Click \'Integration Management\'',
  'application.shopMove.Jumain.InfoText3': '3. Copy Jumia’s \'User ID\' and \'API Key\' to Pandova, and the migration will be completed. ',
  'application.shopMove.dutyDataErrorText': 'Please select the treatment of product transfer.',
  'application.shopMove.desc': 'Got an online store somewhere else? Import it to Pandova!',
  'application.shopMove.shopLink': 'Store website',
  'application.shopMove.getShopifyLink': 'Obtain Shopify link',
  'application.shopMove.startTIme': 'Start time',
  'application.googleAnalytics.id': 'google analytics ID',
  'application.shopMove.shopKeyPassword': 'Admin API access token',
  'application.shopMove.threeStepText': 'Step three, obtain Shopify store authorization',
  'application.shopMove.Jumain.APIKEY': 'API Key',
  'application.shopMove.title': 'Store Transfer',
}
