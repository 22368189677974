export default {
  'onlineShop.themeDesign.newProduct': '最近商品',
  'onlineShop.theme.addThemeButtonText': '添加主题',
  'onlineShop.themeDesign.globalSubButtonTextColor': '次要按钮文本颜色',
  'onlineShop.themeDesign.componentAttrBESTTitle': '推荐商品',
  'onlineShop.themeDesign.componentAttrSubscriptionSubDefaultTitle': '订阅我们的独家折扣，交易，和最新的商品。',
  'onlineShop.themeDesign.makeSureDelete': '确认要删除吗？',
  'onlineShop.common.helpCenterEntrance': '如何装修店铺？',
  'onlineShop.themeDesign.componentAttrProductLink': '商品链接',
  'onlineShop.nav.menuItem': '菜单项',
  'onlineShop.themeDesign.componentAttrShowButtomLine': '是否展示底部分割线',
  'onlineShop.theme.currentThemeInfoText': '这是客户在访问您的商城时会看到的页面样式',
  'onlineShop.themeDesign.componentAttrsMaxNum': '最大数量',
  'onlineShop.theme.currentThemeTitle': '当前主题',
  'onlineShop.themeDesign.componentAttrBgc': '背景颜色',
  'onlineShop.common.publishButtonText': '发布',
  'onlineShop.themeDesign.componentAttrButtonDefaultSubTitle': '按钮文本',
  'onlineShop.themeDesign.componentAttrSubscriptionDefaultTitle': '现在就订阅',
  'onlineShop.themeDesign.globalSubButtonBgColor': '次要按钮背景颜色',
  'onlineShop.themeDesign.globalTwitterLink': 'Twitter',
  'onlineShop.themeDesign.componentAttrTwoTitle': '二级标题',
  'onlineShop.themeDesign.componentAttrMobileMenuTextColor': '移动端菜单文案颜色',
  'onlineShop.themeDesign.componentAttrsAlignCenter': '居中',
  'onlineShop.themeDesign.componentAttrBarDefault': '欢迎光临本店',
  'onlineShop.themeDesign.globalFontSize': '标题字号',
  'onlineShop.themeDesign.componentAttrLogoWidth': 'logo宽度',
  'onlineShop.themeDesign.globalPrimaryButtonTextColor': '主要按钮文本颜色',
  'onlineShop.themeDesign.componentAttrLabelDefaultTitle': '文案',
  'onlineShop.themeDesign.componentAttrTitlePlaceholder': '请输入标题',
  'onlineShop.themeDesign.menuSelectionPlaceholder': '选择菜单',
  'onlineShop.themeDesign.componentAttrSlideHeight': '轮播图高度',
  'onlineShop.themeDesign.globalTextBgcColor': '文本背景颜色',
  'onlineShop.themeDesign.componentAttrLink': '跳转链接',
  'onlineShop.themeDesign.componentAttrSlideWidth': '轮播图宽度',
  'onlineShop.themeDesign.componentAttrsliderTextShow': '是否展示文本',
  'onlineShop.common.publishAddSaveButtonText': '保存并发布',
  'onlineShop.themeDesign.componentSlideshow': '轮播图',
  'onlineShop.themeDesign.globalPrimaryButtonBgColor': '主要按钮背景颜色',
  'onlineShop.themeDesign.componentAllProduct': '全部商品',
  'onlineShop.themeDesign.componentAttrSliderImage': '轮播图片',
  'onlineShop.themeDesign.globalYouTubePlaceholder': '示例： https://www.youtube.com/pandova',
  'onlineShop.themeDesign.globalContentTextFontWeight': '正文字重',
  'onlineShop.theme.myShopOperation': '操作',
  'onlineShop.common.customButtonText': '自定义',
  'onlineShop.common.moreButtonText': '更多',
  'onlineShop.common.renameNameButtonText': '重命名',
  'onlineShop.theme.marketTitle': '主题市场',
  'onlineShop.themeDesign.globalCommodityPrice': '商品原价',
  'onlineShop.themeDesign.componentAttrTopPadding': '顶部内边距',
  'onlineShop.themeDesign.blockText': '模块',
  'onlineShop.themeDesign.LeavePageTitle': '未保存的更改',
  'onlineShop.nav.addMenuItem': '添加菜单项',
  'onlineShop.page.titlePlaceholder': '举例：服务条款、常见问答',
  'onlineShop.themeDesign.componentAttrMarginTop': '顶部边距',
  'onlineShop.themeDesign.componentAttrSlideSpeed': '轮播速度',
  'onlineShop.themeDesign.menuTitle': '菜单',
  'onlineShop.themeDesign.componentAttrButtonPadding': '底部内边距',
  'onlineShop.themeDesign.componentRecommendProduct': '推荐商品',
  'onlineShop.themeDesign.componentFooter': '页脚',
  'onlineShop.themeDesign.componentBanner': '图文',
  'onlineShop.nav.menuLinkPlaceholder': '粘贴链接或选择页面',
  'onlineShop.theme.headerTitle': '商城装修',
  'onlineShop.nav.menuItemNoData': '此菜单没有任何项目',
  'onlineShop.themeDesign.componentAttrsFullLabel': '全屏',
  'onlineShop.themeDesign.globalColor': '颜色',
  'onlineShop.themeDesign.componentAttrsPaddingLabel': '左右边距',
  'onlineShop.themeDesign.MenuComponentDesign': '装饰',
  'onlineShop.common.previewShopButtonText': '查看店铺',
  'onlineShop.themeDesign.HomeText': '首页',
  'onlineShop.themeDesign.componentHeader': '头部',
  'onlineShop.themeDesign.componentAttrButtonText': '按钮文本',
  'onlineShop.themeDesign.componentAttrPrimaryButton': '主要按钮',
  'onlineShop.themeDesign.componentAttrProductCollection': '商品集',
  'onlineShop.themeDesign.componentAnnouncement': '公告栏',
  'onlineShop.themeDesign.categoryTitleProduct': '商品',
  'onlineShop.themeDesign.globalTwitterPlaceholder': '示例：https://twitter.com/pandova',
  'onlineShop.themeDesign.globalSubButtonBdColor': '次要按钮边框颜色',
  'onlineShop.themeDesign.componentAttrMinorButton': '次要按钮',
  'onlineShop.themeDesign.StartDesign': '开始装修',
  'onlineShop.themeDesign.globalContentTextFontFamily': '正文字体',
  'onlineShop.themeDesign.componentAttrImageBannerDefaultTitle': '图文标题',
  'onlineShop.themeDesign.globalContentTextFontSize': '正文尺寸',
  'onlineShop.themeDesign.componentAttrButtonStyle': '按钮样式',
  'onlineShop.themeDesign.componentAttrProductGroup': '商品分组',
  'onlineShop.themeDesign.componentAttrsAlignLeft': '左对齐',
  'onlineShop.themeDesign.componentAttrIsShowEmail': '是否展示邮件',
  'onlineShop.themeDesign.saveButtonText': '保存',
  'onlineShop.themeDesign.globalFacebookLink': 'Facebook',
  'onlineShop.themeDesign.globalInstagramPlaceholder': '示例：http://instagram.com/pandova',
  'onlineShop.themeDesign.componentAttrsAlignSmall': '小',
  'onlineShop.themeDesign.componentAttrMobileMenuBgc': '移动端菜单背景',
  'onlineShop.common.titleColor': '标题颜色',
  'onlineShop.page.updateTime': '更新时间',
  'onlineShop.themeDesign.componentAttrButtonLink': '按钮链接',
  'onlineShop.themeDesign.componentAttrPersonageName': '个人中心',
  'onlineShop.themeDesign.componentAttrImgRadius': '图片圆角',
  'onlineShop.themeDesign.componentAttrsSelectLarge': '大',
  'onlineShop.page.content': '内容',
  'onlineShop.themeDesign.componentAttrRadius': '圆角',
  'onlineShop.themeDesign.componentAttrTextColor': '文本颜色',
  'onlineShop.themeDesign.globalCommodityDiscountedPrice': '商品折扣价',
  'onlineShop.themeDesign.componentAttrsAlignRIght': '右对齐',
  'onlineShop.nav.link': '链接',
  'onlineShop.themeDesign.componentAttrSelectPlaceholder': '请选择',
  'onlineShop.themeDesign.globalPinterestPlaceholder': '示例：https://pinterest.com/pandova',
  'onlineShop.themeDesign.MenuComponentLibrary': '资源库',
  'onlineShop.themeDesign.globalGuidanceText': '添加你的社交媒体链接',
  'onlineShop.themeDesign.globalPinterestText': 'Pinterest',
  'onlineShop.themeDesign.componentAttrSlideDefaultSubTitle': '图片轮播二级标题',
  'onlineShop.theme.myShopTitle': '我的主题库',
  'onlineShop.themeDesign.componentAttrsNormal': '正常',
  'onlineShop.themeDesign.componentAttrSlideDefaultTitle': '图片轮播一级标题',
  'onlineShop.themeDesign.componentAttrSecondaryTitle': '订阅标题',
  'onlineShop.theme.themeMarketInfoExplainText': 'Pandova提供免费的主题旨在提供最佳的商城定制效果',
  'onlineShop.themeDesign.globalContentText': '正文颜色',
  'onlineShop.themeDesign.componentAttrGoodsSetDefault': '商品集',
  'onlineShop.common.deleteButtonText': '删除',
  'onlineShop.themeDesign.addSection': '添加组件',
  'onlineShop.themeDesign.globalInstagramText': 'Instagram',
  'onlineShop.themeDesign.globalFontFamily': '字体',
  'onlineShop.themeDesign.componentAttrSecondaryMenuColor': '二级菜单文案颜色',
  'onlineShop.themeDesign.globalImage': '图片',
  'onlineShop.themeDesign.addComponent': '添加组件',
  'onlineShop.themeDesign.componentAttrSelected': '选择',
  'onlineShop.themeDesign.pageHome': '首页',
  'onlineShop.theme.myShopLastUpdateTime': '最后修改时间',
  'onlineShop.themeDesign.componentAttrsContainerWidth': '容器宽度',
  'onlineShop.theme.myShopInfoExplainText': '在此管理您的所有的装修主题，您也可以通过发布新主题来替换线上的页面样式效果',
  'onlineShop.theme.myShopThemeName': '主题名称',
  'onlineShop.nav.new': '新菜单',
  'onlineShop.page.pageTitle': '页面标题',
  'onlineShop.themeDesign.globalFacebookPlaceholder': '示例：https://facebook.com/pandova',
  'onlineShop.themeDesign.componentAttrSecondarySubTitle': '订阅二级标题',
  'onlineShop.themeDesign.quickLinks': '快速链接',
  'onlineShop.themeDesign.componentProductCollection': '商品集',
  'onlineShop.nav.menuTitle': '菜单标题',
  'onlineShop.themeDesign.MenuComponentGlobalSet': '全局设置',
  'onlineShop.themeDesign.componentAttrProductDetail': '商品详情',
  'onlineShop.themeDesign.componentAttrTextPlaceholder': '请输入文案',
  'onlineShop.themeDesign.componentAttrLogo': 'logo图片',
  'onlineShop.themeDesign.addBlock': '添加块',
  'onlineShop.themeDesign.globalPrimaryButtonBdColor': '主要按钮边框颜色',
  'onlineShop.themeDesign.categoryTextLink': '文本链接',
  'onlineShop.themeDesign.globalTextColor': '文案颜色',
  'onlineShop.themeDesign.componentAttrsAlignMode': '对齐方式',
  'onlineShop.theme.goStoreText': '在线商城',
  'onlineShop.themeDesign.componentAttrHome': '首页',
  'onlineShop.page.title': '标题',
  'onlineShop.themeDesign.componentAttrsBold': '加粗',
  'onlineShop.themeDesign.close': '关闭',
  'onlineShop.nav.menuTitlePlaceholder': '举例：页脚菜单',
  'onlineShop.common.previewButtonText': '预览',
  'onlineShop.themeDesign.componentAttrText': '文案',
  'onlineShop.themeDesign.menuNew': '创建新菜单',
  'onlineShop.page.new': '新页面',
  'onlineShop.themeDesign.componentAttrTitle': '标题',
  'onlineShop.themeDesign.componentAttrReplace': '更换',
  'onlineShop.themeDesign.globalSocialMedia': '社交媒体',
  'onlineShop.themeDesign.componentAttrButtonMargin': '底部边距',
  'onlineShop.nav.tips': '您可以在此设置菜单，装修店铺顶部导航、页脚时，可手动关联对应的菜单。',
  'onlineShop.themeDesign.LeavePageContentText': '如果您离开此页面，任何未保存的更改都将丢失。',
  'onlineShop.themeDesign.componentAttrSecondaryMenuBgcColor': '二级菜单背景颜色',
  'onlineShop.themeDesign.componentAttrsBolder': '更粗的',
  'onlineShop.themeDesign.componentAttrsLighter': '更轻的',
  'onlineShop.common.subTitleColor': '二级标题颜色',
  'onlineShop.themeDesign.componentAttrImgTransparency': '图片透明度',
  'onlineShop.themeDesign.globalYouTubeText': 'YouTube',
  'onlineShop.themeDesign.componentAttrsAlignMiddle': '中',
  'onlineShop.themeDesign.categoryImageLink': '图片链接',
  'onlineShop.themeDesign.componentAttrTopOuterMargin': '顶部外边距',
  'onlineShop.common.copyButtonText': '复制',
}
