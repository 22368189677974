export default {
  'order.orderList.phoneNumber': '电话号码',
  'order.orderDetail.logistics.overview.noSend': '未发货，暂无配送信息',
  'order.orderDetail.logistics.overview.NotifyShipment': '通知客户发货情况',
  'order.orderEdit.selectStatus': '选择支付状态',
  'order.orderDetail.shipped': '已发货',
  'order.throw.copy.text': '复制链接',
  'order.orderList.orderFilter': '订单筛选',
  'order.orderDetail.logistics.overview.CancelSend': '取消发货',
  'order.orderDetail.shippingStatus': '配送状态',
  'order.orderDetail.orderSummary': '订单统计',
  'order.orderDetail.canceled': '已取消',
  'order.orderDetail.logistics.overview.CancelFulfillment': '取消商品发货',
  'order.orderDetail.copyLinkDescription': '您可以复制链接然后发送给您的顾客，您的顾客可以直接通过此链接结账。',
  'order.orderList.payMethodName': '付款方式',
  'order.orderEdit.markToFormalTips': '标记成功后无法再次修改，请确认信息是否正确。',
  'order.orderDetail.logistics.overview.EditShipmentInformation': '编辑发货信息',
  'order.orderList.orderAmount': '订单金额',
  'order.throw.list.action': '操作',
  'order.orderList.orderStatus': '订单状态',
  'order.orderEdit.moneyAmount': '金额',
  'order.orderDetail.logistics.overview.Carrier': '运输承运商',
  'order.orderEdit.moneyPercentage': '比例',
  'order.orderEdit.markToFormalOrder': '标记为正式订单',
  'order.orderDetail.productMemo': '商品备注',
  'order.orderEdit.doNotOperate': '这笔订单正在支付中，暂时不能编辑',
  'order.orderDetail.logistics.overview.AddInformation': '添加发货信息',
  'order.orderDetail.logistics.overview.SendInformation': '立即将发货信息发送给客户',
  'order.orderDetail.productInfo': '商品信息',
  'order.orderEdit.paid': '已支付',
  'order.orderList.StartTime': '订单开始时间',
  'order.throw.send.success': '已在 sendTime 向客户发送了一封提醒电子邮件',
  'order.orderEdit.selectCustomer': '选择顾客',
  'order.orderDetail.logistics.AddDeliveryInformation': '添加发货信息',
  'order.orderList.deliveredOrder': '已发货的订单',
  'order.orderEdit.discountValueValidator': '折扣金额不可大于商品总价',
  'order.orderDetail.preview': '预览',
  'order.orderDetail.unPaid': '待付款',
  'order.orderEdit.editOrder': '编辑草稿订单',
  'order.orderEdit.discountPercentCheck': '折扣比例不可大于100',
  'order.orderList.saleChannel': '销售渠道',
  'order.orderDetail.logistics.overview.EditShipment': '编辑发货',
  'order.orderDetail.shippingSelect': '配送选项',
  'order.orderEdit.orderMemo': '订单备注',
  'order.orderDetail.sendPaymentLink': '发送付款链接',
  'order.orderEdit.unPaid': '未支付',
  'order.orderEdit.editProduct': '编辑商品',
  'order.orderDetail.sendNote': '发送票据',
  'order.orderEdit.saleChannel': '销售渠道',
  'order.orderList.isDraftOrder': '是否草稿订单',
  'order.orderDetail.productName': '商品名称',
  'order.orderDetail.transactionNo': '交易单号',
  'order.throw.status.recovery': '召回状态',
  'order.orderDetail.logistics.TrackingNumber': '运单号',
  'order.orderList.customerName': '顾客姓名',
  'order.orderDetail.shippingFee': '运费',
  'order.orderDetail.billText': '账单',
  'order.orderDetail.logistics.overview.CancelConfirmMessage': '该商品已发货，确认要取消发货么？',
  'order.orderEdit.discountType': '折扣类型',
  'order.orderEdit.selectAddress': '选择地址',
  'order.orderDetail.productUnitPrice': '商品单价',
  'order.orderDetail.productAmount': '商品数量',
  'order.orderDetail.orderNo': '订单号',
  'order.throw.order.placed': '下单时间',
  'order.orderDetail.relationInfo': '联系信息',
  'order.throw.throw.title': '弃单',
  'order.orderDetail.markPaidTips': '如果您手动收到付款，请将此订单标记为已付款',
  'order.orderDetail.pickUpInStore': '到店自取',
  'order.orderDetail.pickUpInLogistics': '物流配送',
  'order.orderList.unPaidOrder': '未支付的订单',
  'order.orderEdit.totalAmount': '总计',
  'order.orderDetail.orderSource': '订单来自',
  'order.orderEdit.productMemo': '备注',
  'order.orderEdit.orderList': '商品列表',
  'order.orderDetail.logistics.overview.HasShipped': '已发货，暂无配送信息',
  'order.orderEdit.manualAddOrder': '手工录入订单',
  'order.throw.detail.btn': '详情',
  'order.orderDetail.customerInfo': '顾客信息',
  'order.orderDetail.memoRequiredTip': '请填写备注',
  'order.orderList.draftStatus': '是否草稿订单',
  'order.orderDetail.tax': '税',
  'order.orderDetail.newOrderNotification': '店铺有新的COD订单',
  'order.orderEdit.unManualAddOrder': '商城订单',
  'order.orderEdit.discount': '折扣',
  'order.orderDetail.processing': '待发货',
  'order.orderDetail.paymentLinkCopedToast': '付款链接已复制',
  'order.orderEdit.selectProduct': '选择商品',
  'order.throw.send.customer': '确定给customer顾客发送召回邮件吗？',
  'order.orderDetail.exportText': '导出',
  'order.orderList.title': '订单列表',
  'order.orderDetail.logistics.Carrier': '承运商',
  'order.orderEdit.shippingFee': '运费',
  'order.orderDetail.logistics.Confirm': '确认',
  'order.orderEdit.totalGoodsPrice': '小计',
  'order.orderEdit.orderStartDate': '下单时间',
  'order.orderEdit.payMethod': '支付方式',
  'order.orderDetail.logistics.overview.ConfirmSend': '确认发货',
  'order.orderDetail.sendOrder': '发送账单',
  'order.orderEdit.addProduct': '创建新商品',
  'order.orderDetail.markPaidText': '标记已支付',
  'order.orderDetail.payStatus': '支付状态',
  'order.orderList.orderNo': '订单号',
  'order.orderList.EndTime': '订单截止时间',
  'order.orderDetail.productDiscount': '商品折扣',
  'order.orderDetail.logistics.DeliveryProgress': '物流跟踪信息',
  'order.throw.list.sent.status': '发送状态',
  'order.orderDetail.logistics.overview.Cancel': '取消',
  'order.orderEdit.formatOrder': '正式订单',
  'order.orderList.orderFilterStatus': '订单状态',
  'order.orderDetail.returned ': '已退回',
  'order.orderDetail.paymentInfo': '支付信息',
  'order.orderEdit.tax': '税',
  'order.orderList.allOrder': '所有订单',
  'order.orderList.completedOrder': '已完成的订单',
  'order.throw.send.btn.text': '发送召回邮件',
  'order.orderEdit.saveProductInfo': '保存',
  'order.orderDetail.logistics.noDetail': '暂无物流信息',
  'order.orderDetail.cancelOrder': '订单取消',
  'order.orderEdit.addOrder': '添加草稿订单',
  'order.orderDetail.newOrder': '新订单',
  'order.throw.send.success.toast': '召回邮件发送成功',
  'order.orderDetail.shippingAddress': '收货地址',
  'order.orderEdit.customerSelectRequireTip': '请选择顾客',
  'order.orderEdit.saveToOfficial': '保存订单记录',
  'order.orderDetail.totalGoodsPrice': '商品小计',
  'order.orderList.searchOrder': '查询',
  'order.orderEdit.addNewCustomer': '创建新顾客',
  'order.orderDetail.orderMemo': '订单备注',
  'order.orderDetail.orderDetailText': '订单详情',
  'order.orderDetail.payInfo': '支付信息',
  'order.orderDetail.requestPay': '请求付款',
  'order.orderList.addOrder': '创建草稿订单',
  'order.orderDetail.completed': '已完成',
  'order.orderEdit.deleteProduct': '删除商品',
  'order.orderDetail.logistics.deliverGoods': '为商品发货',
  'order.orderDetail.billAddress': '账单地址',
  'order.orderList.orderStartTime': '下单时间',
  'order.orderList.willDealOrder': '待处理的订单',
  'order.orderDetail.totalAmount': '总计',
  'order.orderDetail.logistics.completeInformation': '请填写完整的物流信息',
  'order.orderEdit.draftOrder': '草稿订单',
  'order.orderEdit.productAmount': '商品数量',
}
