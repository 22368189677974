export default {
  'onlineShop.themeDesign.newProduct': 'توصية جديدة',
  'onlineShop.theme.addThemeButtonText': 'اضافة سمة',
  'onlineShop.themeDesign.globalSubButtonTextColor': 'لون نص الزر الثانوي',
  'onlineShop.themeDesign.componentAttrBESTTitle': 'توصية',
  'onlineShop.themeDesign.componentAttrSubscriptionSubDefaultTitle': 'اشترك معنا للحصول على الخصومات الحصرية والصفقات وأحدث البضائع.',
  'onlineShop.themeDesign.makeSureDelete': 'تأكيد الحذف ؟',
  'onlineShop.common.helpCenterEntrance': 'كيف تقوم بتزين المتجر؟',
  'onlineShop.themeDesign.componentAttrProductLink': 'رابط المنتج',
  'onlineShop.nav.menuItem': 'موضوع القائمة',
  'onlineShop.themeDesign.componentAttrShowButtomLine': 'هل يظهر خط التقسيم السفلي؟',
  'onlineShop.theme.currentThemeInfoText': 'هذا هو نمط الصفحة الذي سيراه العملاء عند زيارتهم للسوق الخاص بك',
  'onlineShop.themeDesign.componentAttrsMaxNum': 'الاعلي',
  'onlineShop.theme.currentThemeTitle': 'الموضوع الحالي',
  'onlineShop.themeDesign.componentAttrBgc': 'لون الخلفية',
  'onlineShop.common.publishButtonText': 'نشر',
  'onlineShop.themeDesign.componentAttrButtonDefaultSubTitle': 'زر كتابة',
  'onlineShop.themeDesign.componentAttrSubscriptionDefaultTitle': 'إشترك الآن',
  'onlineShop.themeDesign.globalSubButtonBgColor': 'لون خلفية الزر الثانوي',
  'onlineShop.themeDesign.globalTwitterLink': 'تويتر',
  'onlineShop.themeDesign.componentAttrTwoTitle': 'العنوان الثانوي',
  'onlineShop.themeDesign.componentAttrMobileMenuTextColor': 'لون نص قائمة الجوال',
  'onlineShop.themeDesign.componentAttrsAlignCenter': 'توسيط',
  'onlineShop.themeDesign.componentAttrBarDefault': 'مرحبًا بكم في متجرنا',
  'onlineShop.themeDesign.globalFontSize': 'حجم خط العنوان',
  'onlineShop.themeDesign.componentAttrLogoWidth': 'عرض الشعار',
  'onlineShop.themeDesign.globalPrimaryButtonTextColor': 'لون خط الزر الاساسي',
  'onlineShop.themeDesign.componentAttrLabelDefaultTitle': 'المحتوى الافتراضي',
  'onlineShop.themeDesign.componentAttrTitlePlaceholder': 'الرجاء إدخال العنوان',
  'onlineShop.themeDesign.menuSelectionPlaceholder': 'اختر القائمة',
  'onlineShop.themeDesign.componentAttrSlideHeight': 'ارتفاع شرائح العرض',
  'onlineShop.themeDesign.globalTextBgcColor': 'لون نص الخلفية',
  'onlineShop.themeDesign.componentAttrLink': 'الرابط',
  'onlineShop.themeDesign.componentAttrSlideWidth': 'عرض شرائح العرض',
  'onlineShop.themeDesign.componentAttrsliderTextShow': 'عرض النص أم لا ',
  'onlineShop.common.publishAddSaveButtonText': 'حفظ ونشر',
  'onlineShop.themeDesign.componentSlideshow': 'عرض الشرائح',
  'onlineShop.themeDesign.globalPrimaryButtonBgColor': 'لون خلفية الزر الأساسي',
  'onlineShop.themeDesign.componentAllProduct': 'المنتجات',
  'onlineShop.themeDesign.componentAttrSliderImage': 'صور دائرية',
  'onlineShop.themeDesign.globalYouTubePlaceholder': ' https://www.youtube.com/pandova',
  'onlineShop.themeDesign.globalContentTextFontWeight': 'نص أساسي',
  'onlineShop.theme.myShopOperation': 'العمليات',
  'onlineShop.common.customButtonText': 'تخصيص',
  'onlineShop.common.moreButtonText': 'المزيد',
  'onlineShop.common.renameNameButtonText': 'اعادة تسمية',
  'onlineShop.theme.marketTitle': 'متجر السمات',
  'onlineShop.themeDesign.globalCommodityPrice': 'السعر الأصلي',
  'onlineShop.themeDesign.componentAttrTopPadding': 'الحشو العلوي',
  'onlineShop.themeDesign.blockText': 'وحدة',
  'onlineShop.themeDesign.LeavePageTitle': 'مغادرة هذه الصفحة ، ستفقد جميع البيانات غير المحفوظة في هذه الصفحة؟',
  'onlineShop.nav.addMenuItem': 'إضافة موضوع قائمة',
  'onlineShop.page.titlePlaceholder': 'مثل: شروط الخدمة، الأسئلة الشائعة',
  'onlineShop.themeDesign.componentAttrMarginTop': 'الهامش العلوي',
  'onlineShop.themeDesign.componentAttrSlideSpeed': 'سرعة التدوير',
  'onlineShop.themeDesign.menuTitle': 'القائمة',
  'onlineShop.themeDesign.componentAttrButtonPadding': 'الحشو السفلي',
  'onlineShop.themeDesign.componentRecommendProduct': 'العناصر الموصى بها',
  'onlineShop.themeDesign.componentFooter': 'تذييل',
  'onlineShop.themeDesign.componentBanner': 'الرسومات',
  'onlineShop.nav.menuLinkPlaceholder': 'قم بنسخ و لصق الرابط أو اختر الصفحة',
  'onlineShop.theme.headerTitle': 'ديكور المتجر',
  'onlineShop.nav.menuItemNoData': 'لا يوجد محتوى',
  'onlineShop.themeDesign.componentAttrsFullLabel': 'تكبير الشاشة',
  'onlineShop.themeDesign.globalColor': 'اللون',
  'onlineShop.themeDesign.componentAttrsPaddingLabel': 'الهوامش اليمنى واليسرى',
  'onlineShop.themeDesign.MenuComponentDesign': 'تزيين',
  'onlineShop.common.previewShopButtonText': 'عرض',
  'onlineShop.themeDesign.HomeText': 'الصفحة الرئيسية',
  'onlineShop.themeDesign.componentHeader': 'العنوان',
  'onlineShop.themeDesign.componentAttrButtonText': 'زر كتابة',
  'onlineShop.themeDesign.componentAttrPrimaryButton': 'الزر الرئيسي',
  'onlineShop.themeDesign.componentAttrProductCollection': 'المجموعات',
  'onlineShop.themeDesign.componentAnnouncement': 'لوحة إعلانات',
  'onlineShop.themeDesign.categoryTitleProduct': 'المنتج',
  'onlineShop.themeDesign.globalTwitterPlaceholder': ' https://twitter.com/pandova',
  'onlineShop.themeDesign.globalSubButtonBdColor': 'لون حد الزر الثانوي',
  'onlineShop.themeDesign.componentAttrMinorButton': 'الزر الثانوي',
  'onlineShop.themeDesign.StartDesign': 'بدء التزيين',
  'onlineShop.themeDesign.globalContentTextFontFamily': 'شكل الخط',
  'onlineShop.themeDesign.componentAttrImageBannerDefaultTitle': 'العنوان الافتراضي للبانر',
  'onlineShop.themeDesign.globalContentTextFontSize': 'حجم الخط',
  'onlineShop.themeDesign.componentAttrButtonStyle': 'نمط الزر',
  'onlineShop.themeDesign.componentAttrProductGroup': 'المجموعات',
  'onlineShop.themeDesign.componentAttrsAlignLeft': 'المحاذاة لليسار',
  'onlineShop.themeDesign.componentAttrIsShowEmail': 'اظهار البريد الالكتروني',
  'onlineShop.themeDesign.saveButtonText': 'حفظ',
  'onlineShop.themeDesign.globalFacebookLink': 'فيسبوك',
  'onlineShop.themeDesign.globalInstagramPlaceholder': ' http://instagram.com/pandova',
  'onlineShop.themeDesign.componentAttrsAlignSmall': 'صغير',
  'onlineShop.themeDesign.componentAttrMobileMenuBgc': 'خلفية قائمة المحمول',
  'onlineShop.common.titleColor': 'لوم العنوان ',
  'onlineShop.page.updateTime': 'وقت التحديث',
  'onlineShop.themeDesign.componentAttrButtonLink': 'رابط الزر',
  'onlineShop.themeDesign.componentAttrPersonageName': 'المركز الشخصي',
  'onlineShop.themeDesign.componentAttrImgRadius': 'زوايا مستديرة للصورة',
  'onlineShop.themeDesign.componentAttrsSelectLarge': 'كبير',
  'onlineShop.page.content': 'المحتوى',
  'onlineShop.themeDesign.componentAttrRadius': 'مستديرة',
  'onlineShop.themeDesign.componentAttrTextColor': 'لون النص',
  'onlineShop.themeDesign.globalCommodityDiscountedPrice': 'السعر بعد الخصم',
  'onlineShop.themeDesign.componentAttrsAlignRIght': 'المحاذاة لليمين',
  'onlineShop.nav.link': 'الرابط',
  'onlineShop.themeDesign.componentAttrSelectPlaceholder': 'الرجاء التحديد',
  'onlineShop.themeDesign.globalPinterestPlaceholder': ' https://pinterest.com/pandova',
  'onlineShop.themeDesign.MenuComponentLibrary': 'المصادر',
  'onlineShop.themeDesign.globalGuidanceText': 'أضف روابط وسائل التواصل الاجتماعي الخاصة بك',
  'onlineShop.themeDesign.globalPinterestText': 'بينتيريست',
  'onlineShop.themeDesign.componentAttrSlideDefaultSubTitle': 'العنوان الفرعي للشريحة',
  'onlineShop.theme.myShopTitle': 'مكتبة موضوعاتي',
  'onlineShop.themeDesign.componentAttrsNormal': 'عادي',
  'onlineShop.themeDesign.componentAttrSlideDefaultTitle': 'العنوان الافتراضي للشريحة',
  'onlineShop.themeDesign.componentAttrSecondaryTitle': 'عنوان الاشترالك',
  'onlineShop.theme.themeMarketInfoExplainText': 'باندوفا قم بتوفير سمات مجانية مصممة لتوفير أفضل تأثير لتخصيص المتجر',
  'onlineShop.themeDesign.globalContentText': 'لون النص',
  'onlineShop.themeDesign.componentAttrGoodsSetDefault': 'مجموعة المنتجات',
  'onlineShop.common.deleteButtonText': 'حذف',
  'onlineShop.themeDesign.addSection': 'إضافة وحدة قياس / موديول',
  'onlineShop.themeDesign.globalInstagramText': 'انستغرام',
  'onlineShop.themeDesign.globalFontFamily': 'الخط',
  'onlineShop.themeDesign.componentAttrSecondaryMenuColor': 'لون نسخ القائمة الثانوية',
  'onlineShop.themeDesign.globalImage': 'الصورة',
  'onlineShop.themeDesign.addComponent': 'أضف المكونات',
  'onlineShop.themeDesign.componentAttrSelected': 'تحديد',
  'onlineShop.themeDesign.pageHome': 'الصفحة الرئيسية',
  'onlineShop.theme.myShopLastUpdateTime': 'اخر وقت للتعديل',
  'onlineShop.themeDesign.componentAttrsContainerWidth': 'عرض الحاوية',
  'onlineShop.theme.myShopInfoExplainText': 'قم بإدارة جميع سمات الزخرفة الخاصة بك هنا ، يمكنك أيضًا استبدال تأثيرات نمط الصفحة عبر الإنترنت من خلال نشر سمات جديدة',
  'onlineShop.theme.myShopThemeName': 'اسم السمة',
  'onlineShop.nav.new': 'قائمة جديدة',
  'onlineShop.page.pageTitle': 'عنوان الصفحة',
  'onlineShop.themeDesign.globalFacebookPlaceholder': ' https://facebook.com/pandova',
  'onlineShop.themeDesign.componentAttrSecondarySubTitle': 'اشترك في العناوين الثانوية',
  'onlineShop.themeDesign.quickLinks': 'روابط سريعة',
  'onlineShop.themeDesign.componentProductCollection': 'مجموعة المنتج',
  'onlineShop.nav.menuTitle': 'العنوان',
  'onlineShop.themeDesign.MenuComponentGlobalSet': 'الاعدادات العامة',
  'onlineShop.themeDesign.componentAttrProductDetail': 'تفاصيل المنتج',
  'onlineShop.themeDesign.componentAttrTextPlaceholder': 'الرجاء إدخال المحتوى',
  'onlineShop.themeDesign.componentAttrLogo': 'الشعار',
  'onlineShop.themeDesign.addBlock': 'إضافة كُتلة',
  'onlineShop.themeDesign.globalPrimaryButtonBdColor': 'لون حد الزر الأساسي',
  'onlineShop.themeDesign.categoryTextLink': 'رابط النص',
  'onlineShop.themeDesign.globalTextColor': 'لون النص',
  'onlineShop.themeDesign.componentAttrsAlignMode': 'المحاذاة',
  'onlineShop.theme.goStoreText': 'المتجر الاونلاين',
  'onlineShop.themeDesign.componentAttrHome': 'الصفحة الرئيسية',
  'onlineShop.page.title': 'العنوان',
  'onlineShop.themeDesign.componentAttrsBold': 'بالخط العريض',
  'onlineShop.themeDesign.close': 'غلق',
  'onlineShop.nav.menuTitlePlaceholder': 'مثل: قائمة التذييل',
  'onlineShop.common.previewButtonText': 'عرض',
  'onlineShop.themeDesign.componentAttrText': 'النص',
  'onlineShop.themeDesign.menuNew': 'إنشاء قائمة جديدة',
  'onlineShop.page.new': 'صفحة جديدة',
  'onlineShop.themeDesign.componentAttrTitle': 'العنوان',
  'onlineShop.themeDesign.componentAttrReplace': 'تغيير',
  'onlineShop.themeDesign.globalSocialMedia': 'وسائل التواصل الاجتماعي',
  'onlineShop.themeDesign.componentAttrButtonMargin': 'الهامش السفلي',
  'onlineShop.nav.tips': 'يمكنك إعداد القائمة هنا. و يمكن ربط القوائم عند تصميم خريطة الموقع العليا و قائمة تذييل المتجر',
  'onlineShop.themeDesign.LeavePageContentText': 'هل متأكد من مغادرة الصفحة ؟',
  'onlineShop.themeDesign.componentAttrSecondaryMenuBgcColor': 'لون خلفية القائمة الثانوية',
  'onlineShop.themeDesign.componentAttrsBolder': 'أكثر سمكا',
  'onlineShop.themeDesign.componentAttrsLighter': 'اخف وزنا',
  'onlineShop.common.subTitleColor': 'لون العنوان الثانوي',
  'onlineShop.themeDesign.componentAttrImgTransparency': 'شفافية الصورة',
  'onlineShop.themeDesign.globalYouTubeText': ' يوتيوب',
  'onlineShop.themeDesign.componentAttrsAlignMiddle': 'متوسط',
  'onlineShop.themeDesign.categoryImageLink': 'رابط الصورة',
  'onlineShop.themeDesign.componentAttrTopOuterMargin': 'الهامش العلوي\'',
  'onlineShop.common.copyButtonText': 'نسخ',
}
