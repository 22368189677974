export default {
  'sms.smsCount.type': '类型',
  'sms.smsPurchaseList.rechargeUser': '充值人',
  'sms.smsPurchaseList.item': '条',
  'sms.smsPurchaseList.smsPurchaseTitle': '短信充值',
  'sms.smsCount.content': '短信内容',
  'sms.smsCount.scene': '短信场景',
  'sms.smsPurchaseList.rechargeState': '充值状态',
  'sms.smsPurchaseList.used': '短信已使用',
  'sms.smsCount.sendNumber': '发送短信数量',
  'sms.animation': '自动',
  'sms.commandShift': '手动',
  'sms.smsPurchaseList.rechargeTime': '充值时间',
  'sms.smsPurchaseList.smsPurchaseSMS': 'SMS',
  'sms.smsCount.from': '来源',
  'sms.smsPurchaseList.rechargeAmount': '充值金额',
  'sms.smsCount.recharge': '购买短信',
  'sms.smsPurchaseList.smsResidual': '短信剩余',
  'sms.smsPurchaseList.rechargeNumber': '充值数量',
  'sms.smsPurchaseList.recharge': '购买短信',
  'sms.smsCount.sendDate': '发送日期',
  'sms.smsPurchaseList.title': '短信充值记录',
  'sms.smsCount.title': '短信统计',
  'sms.smsPurchaseList.residual': '剩余',
}
