export default {
  'customer.pleaseSelectScope': 'Select customer/group',
  'customer.deleteCustomer.sureToDelete': 'Are you sure?',
  'customer.addCustomer.addAddress': 'New Address',
  'customer.customerDetail.remark': 'Notes',
  'customer.addCustomer.state': 'State',
  'customer.createMessage.sendMessage': 'Send Message',
  'customer.addCustomer.name': 'Name',
  'customer.addCustomer.internationalCode': 'Country code',
  'customer.pleaseEnterAddress': 'Enter address',
  'customer.createMessage.mailContent': 'Message content',
  'customer.messageList.messageTitle': 'Message Title',
  'customer.createMessage.selectOrderJustONe': 'Customers with only 1 order in recent 30 days',
  'customer.addCustomer.deleteAddress': 'Delete',
  'customer.selectTextCustomer': 'Customers',
  'customer.addCustomerGroup.group': 'Group',
  'customer.createMessage.smsContent': 'SMS content',
  'customer.customerDetail.lastLanding': 'Last seen about ... ago.',
  'customer.messageDetail.sendState': 'State',
  'customer.messageDetail.successCustomer': 'Succeeded to send',
  'customer.createMessage.selectAllCustomer': 'Select all customers',
  'customer.operation': 'Operation',
  'customer.pleaseEnterMail': 'Enter email',
  'customer.addCustomer.city': 'City',
  'customer.shippingAddress': 'Shipped To',
  'customer.billingAddress': 'Billed To',
  'customer.noDataTips': 'No Customer',
  'customer.selectTextEmail': 'Email',
  'customer.createMessage.selectSubscriptionMail': 'Email subscribers',
  'customer.deleteCustomer.cancel': 'Cancel',
  'customer.selectTextSuccess': 'Sent',
  'customer.selectTextCustomerGroup': 'Customer group',
  'customer.addCustomer.lastName': 'Last Name',
  'customer.addCustomerGroup.selectAll': 'Select all',
  'customer.customerDetail.orderAmount': 'Order Total',
  'customer.customerDetail.group': 'Tags',
  'customer.messageDetail.activityName': 'Campaign Name',
  'customer.addCustomer.setDefaultAddress': 'Set as default',
  'customer.placeSelectCountry': 'Select country',
  'customer.messageDetail.messageContent': 'Message Content',
  'customer.addCustomerGroup': 'Create Group',
  'customer.pleaseEnterLastName': 'Enter last name',
  'customer.createMessage.selectCustomerAndGroup': 'Select Customer/Group',
  'customer.addCustomer.setBillingAddress': 'Set as billing address',
  'customer.customerDetail.howLongAgo': '... ago',
  'customer.addCustomerGroup.selectCustomer': 'Choose Customer',
  'customer.customerGroupList': 'Customer Group',
  'customer.customerDetail.shippingAddress': 'Shipping Address',
  'customer.delete': 'Delete',
  'customer.customerDetail.viewOrderDetail': 'View Order',
  'customer.addCustomer.country': 'Country',
  'customer.addCustomer.streetAddress': 'Address',
  'customer.selectGroup': 'Select Group',
  'customer.addCustomer.mail': 'Email',
  'customer.createMessage.emailNote': 'Note: you can only send up to 100 emails a day.',
  'customer.customerDetail.recentOrders': 'Recent Purchases',
  'customer.pleaseEnterCity': 'Enter city',
  'customer.deleteCustomer.unBack': 'You will not be able to revert this!',
  'customer.addCustomer.remark': 'Notes',
  'customer.addMessage': 'Send marketing message',
  'customer.addCustomer.billingAddress': 'Billing Address',
  'customer.customerDetail.orderList': 'Recent orders',
  'customer.messageDetail.sendChannel': 'Message Channel',
  'customer.addCustomer.zipCode': 'Zip Code (optional)',
  'customer.selectTextAll': 'All',
  'customer.customerList.name': 'Name',
  'customer.customerList.mailSelect': 'Email Subscription Status',
  'customer.addCustomer.shippingPhone': 'Phone Number',
  'customer.addCustomer.phone': 'Phone Number',
  'customer.subscribed': 'Subscribed',
  'customer.createMessage.activityName': 'Campaign Name',
  'customer.addCustomer.cancel': 'Cancel',
  'customer.createMessage.selectTopCustomer': 'Top 10 of customers by amount spent in recent 30 days',
  'customer.addCustomer.defaultAddress': 'Default shipping address',
  'customer.messageDetail.messageTitle': 'Message Title',
  'customer.messageDetail.failCustomer': 'Failed to send',
  'customer.addCustomerGroup.add': 'Add',
  'customer.customerDetail.orderNumber': 'Order No.',
  'customer.addCustomerGroup.customerGroupName': 'Create Group',
  'customer.customerDetail': 'View Customer',
  'customer.createMessage.mailName': 'Message Title',
  'customer.detail': 'Details',
  'customer.addCustomer.save': 'Save',
  'customer.customerList': 'All Customers',
  'customer.customerList.email': 'Email',
  'customer.pleaseEnterFirstName': 'Enter first name',
  'customer.editCustomer': 'Edit',
  'customer.addCustomer': 'Add customer',
  'customer.customerDetail.orderTotalSpend': 'Total Spend',
  'customer.addCustomerGroup.unGroup': 'Ungrouped',
  'customer.deleteCustomer.confirmDelete': 'Yes, delete it!',
  'customer.messageList.activityName': 'Campaign Name',
  'customer.selectTextSms': 'SMS',
  'customer.pleaseEnterInternationalCode': 'Enter country code',
  'customer.customerList.phone': 'Phone',
  'customer.editCustomerGroup': 'Edit',
  'customer.addCustomer.viewAllGroup': 'View all',
  'customer.customerGroupList.customerCount': 'Customers',
  'customer.customerList.addCustomer': 'Add Customer',
  'customer.noAddressTips': 'There is no address, please click \'new address\' to add.',
  'customer.customerDetail.phone': 'Phone',
  'customer.customerDetail.mail': 'Email',
  'customer.customerDetail.billingAddress': 'Billing Address',
  'customer.placeSelectStateAndGovernorate': 'Select state',
  'customer.customerList.searchName': 'Search',
  'customer.messageDetail': 'Message Details',
  'customer.createMessage.title': 'Message Title',
  'customer.addCustomer.basicInformation': 'Basic information',
  'customer.messageList.sendState': 'State',
  'customer.addCustomer.firstName': 'First Name',
  'customer.addCustomerGroup.search': 'Search',
  'customer.messageList.sendChannel': 'Message Channel',
  'customer.customerGroupList.groupName': 'Name',
  'customer.pleaseSelectMessageType': 'Select messaging channel',
  'customer.addCustomer.shippingAddress': 'Shipping Address',
  'customer.addCustomerGroup.save': 'Save',
  'customer.createMessage.selectNoOrder': 'Customers with 0 orders',
  'customer.messageList': 'Messages',
  'customer.customerDetail.orderCount': 'Orders',
  'customer.createMessage.content': 'Message Content',
  'customer.makeSureDelete': 'Confirm to delete?',
  'customer.addCustomer.handle': 'Handle',
  'customer.createMessage.sendChannel': 'Message Channel',
  'customer.addCustomer.pleaseEnterGroupName': 'Enter group name',
  'customer.editAddress': 'Edit',
  'customer.pleaseEnterMailContent': 'Enter message content',
  'customer.addCustomerGroup.customerList': 'Customers',
  'customer.selectTextFail': 'Failed',
  'customer.addCustomer.group': 'Group',
  'customer.selectTextBeSending': 'Sending',
  'customer.notSubscribed': 'Unsubscribed',
  'customer.customerList.lastVisit': 'Last Seen',
  'customer.createMessage.customerScopeType': 'Customer Group',
  'customer.deleteSuccess': 'Deleted successfully',
  'customer.pleaseEnterHandle': 'Enter handler',
  'customer.pleaseEnterMessageName': 'Enter campaign name',
  'customer.createMessage.smsName': 'SMS title',
  'customer.edit': 'Edit',
  'customer.pleaseEnterPhone': 'Enter the phone number',
  'customer.addCustomerGroup.cancel': 'Cancel',
  'customer.addCustomer.address': 'Address',
}
