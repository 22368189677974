export default {
  'discount.discountListEdit.atTheRatio ': 'Percentage',
  'discount.discountListEdit.specifyGroup': 'Specify product group',
  'discount.deleteSuccess': 'Delete successfully',
  'discount.discountListEdit.specifyProducts': 'Specify product',
  'discount.discountList.add': 'New Discount ',
  'discount.discountListEdit.tipContent': 'Promote your discount code with sharable link through email or social media. Your customers can get the discount when paying by the link.',
  'discount.discountList.selectGoodsGroup': 'Select product group',
  'discount.discountList.usageCount': 'Usage Count',
  'discount.discountList.discountState': 'State',
  'discount.discountList.startTime': 'Valid From',
  'discount.discountList.title': 'Discounts',
  'discount.discountList.operation': 'Operation',
  'discount.discountEdit.placeInputScopeNumber': 'Please enter a number between 0 and 100',
  'discount.discountListEdit.getShareButton': 'Get sharing link',
  'discount.discountListEdit.dialogTitle': 'Get sharable link',
  'discount.discountList.makeDiscountCode': 'Generate code',
  'discount.discountListEdit.shareButton': 'Share',
  'discount.discountList.minCartValue': 'Minimum Cart Value (Optional)',
  'discount.discountListEdit.fixedAmount ': 'Fixed',
  'discount.discountList.descriptionForDiscountCode': 'The customer will enter the discount code at checkout.',
  'discount.discountList.maxDiscountValue': 'Max Discount (Optional)',
  'discount.discountList.close': 'Disable',
  'discount.discountList.open': 'Enable',
  'discount.discountList.discountValue': 'Value',
  'discount.discountListEdit.startTime': 'Start time',
  'discount.discountList.descriptionForDiscount': 'Description',
  'discount.discountListEdit.endTime': 'End time',
  'discount.discountListEdit.textNext': 'What is next step?',
  'discount.discountList.discountType': 'Discount Type',
  'discount.discountList.discountCode': 'Discount Code',
  'discount.title': 'Discounts',
  'discount.discountList.selectGoods': 'Select product',
  'discount.discountList.endTime': 'Valid Till',
  'discount.discountList.minimumRequirement': 'Minimum requirements',
  'discount.discountList.useScope': 'Usage limits',
  'discount.discountListEdit.dialogContent': 'You can share discount link with customers via email or social media. Your customers can use this discount when paying the order. ',
  'discount.dicountCodeCheckTip': 'Only letters or numbers can be entered',
  'discount.discountListEdit.allProducts': 'All products',
  'discount.discountList.placeInput': 'Input cannot be empty',
  'discount.discountList.detail': 'Details',
  'discount.discountAdd.selectedItems': 'Selected',
  'discount.discountList.effectiveDate': 'Effective date',
  'discount.makeSureDelete': 'Are you sure you want to delete?',
  'discount.discountList.delete': 'Delete',
  'discount.discountList.edit': 'Edit',
  'discount.discountListEdit.copyButton': 'Copy link',
  'discount.discountListEdit.tipTitle': 'Successfully created',
}
