export default {
  'application.shopMove.attentionText1': '邮箱相同的顾客将不会被搬迁',
  'application.shopMove.attentionText2': '商品的视频不会被搬迁',
  'application.shopMove.DataSelectErrorText': '请选择需要搬迁的数据。',
  'application.shopMove.shopKey': '店铺密钥（API Key）',
  'application.shopMove.sameProductTransfer': '相同产品名称的处理方式',
  'application.facebookPixel.description': '让你的商品在Facebook上售卖，通过 Pixel的数据反馈可以追踪效果。',
  'application.facebookPixel.title': 'facebook pixel',
  'application.shopMove.moveContent': '搬迁内容',
  'application.shopMove.IsMoving': '正在进行搬家',
  'application.shopMove.result': '执行结果',
  'application.shopMove.planRepetitionErrorText': '已经存在一个搬家任务，请稍后再试！',
  'application.facebookPixel.howToGetId': '怎么样获取到facebook pixel ID',
  'application.googleAnalytics.description': '通过google analytics, 可以跟踪店铺的访客，有助于进行营销推广，跟踪报告。',
  'application.facebookPixel.adsLabel': '连接到facebook pixel',
  'application.shopMove.Jumain.twoStepText': '第二步，获取Jumia店铺授权',
  'application.shopMove.Jumain.UserId': '用户ID',
  'application.shopMove.Jumain.APIURL': 'API网址',
  'application.shopMove.shopifyStoreAuthorization': 'Shopify店铺授权',
  'application.shopMove.moveRemind': '完成此过程可能需要一段时间，可以在搬家记录查看更多信息。',
  'application.googleAnalytics.login': '登录google analytiics',
  'application.shopMove.endTIme': '结束时间',
  'application.title': '应用',
  'application.facebookPixel.adsText': '帮助跟踪facebook的广告效果，优化广告，建立目标客户',
  'application.facebookPixel.id': 'Facebook ID ',
  'application.googleAnalytics.howToGetId': '怎么样获取到谷歌分析ID',
  'application.shopMove.moveProductLang': '迁移商品的语言',
  'application.shopMove.Jumain.firstStepText': '第一步，输入 Jumia API URL',
  'application.googleAnalytics.adsLabel': '连接google analytics',
  'application.facebookPixel.login': '登录facebook pixel',
  'application.shopMove.confirm': '确定',
  'application.shopMove.attention': '注意：',
  'application.shopMove.firstStepText': '第一步，输入店铺信息',
  'application.shopMove.status': '状态',
  'application.googleAnalytics.adsText': '连接谷歌分析ID，统计网站效果的分析',
  'application.shopMove.move': '搬家',
  'application.shopMove.moveRecord': '搬家记录',
  'application.shopMove.twoStepText': '第二步，数据搬迁设置',
  'application.applicationList.title': '应用管理',
  'application.shopMove.lengthErrorText': '不能超过1024个字符。',
  'application.shopMove.shopSource': '店铺来源',
  'application.googleAnalytics.title': '谷歌分析',
  'application.shopMove.Jumain.migrationContentInstructions': '我们会将您店铺的产品迁移到Pandova',
  'application.shopMove.shopUrlPlaceholder': '请输入店铺网址',
  'application.shopMove.dutyDataLabel': '需要搬迁的数据',
  'application.shopMove.threeStepDeclare3': '3.如果这是您的第一次，请单击“允许自定义应用程序开发”开始在您的应用商店上开发应用程序',
  'application.shopMove.threeStepDeclare4': '4.单击“创建应用”，然后输入要创建的应用名称',
  'application.shopMove.threeStepDeclare1': '1.进入Shopify后台“应用”',
  'application.shopMove.threeStepDeclare2': '2.点击“开发应用”',
  'application.shopMove.threeStepDeclare7': '7.成功安装后，将Shopify的“API密钥”和“管理API访问令牌”复制到Pandova，以完成产品的重新定位',
  'application.shopMove.threeStepDeclare5': '5.点击“配置管理API范围”，将“客户”、“产品”和“产品列表”的权限设置为“读写”',
  'application.shopMove.threeStepDeclare6': '6.单击“保存”，然后单击“安装应用”安装应用',
  'application.shopMove.takesDate': '大约需要15分钟才能完成。',
  'application.shopMove.detailKnow': '详细了解',
  'application.shopMove.Jumain.InfoText1': '1、在Jumia后台点击“设置”菜单',
  'application.shopMove.Jumain.InfoText2': '2、点击“集成管理”',
  'application.shopMove.Jumain.InfoText3': '3、将Jumia的“User ID”和“API Key”复制到 Pandova ，即可完成迁移',
  'application.shopMove.dutyDataErrorText': '请选择商品搬迁处理方式',
  'application.shopMove.desc': '从Shopify等电商平台一键搬迁商品等数据',
  'application.shopMove.shopLink': '店铺链接',
  'application.shopMove.getShopifyLink': '获取Shopify链接',
  'application.shopMove.startTIme': '开始时间',
  'application.googleAnalytics.id': '谷歌分析ID',
  'application.shopMove.shopKeyPassword': '管理令牌（Admin API access token）',
  'application.shopMove.threeStepText': '第三步，获取Shopify店铺授权',
  'application.shopMove.Jumain.APIKEY': 'API密钥',
  'application.shopMove.title': '店铺搬家',
}
