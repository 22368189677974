export default {
  'charge.chargeList.descriptionOfFees': 'التعليمات',
  'charge.chargeList.addGroup': 'إضافة فئة',
  'charge.chargeList.inputAmount': 'ادخل كمية',
  'charge.chargeList.editGroup': 'المحاسبة اليدوية - قائمة مجموعة المصاريف - تحرير المجموعة',
  'charge.chargeList.title': 'المصاريف',
  'charge.chargeList.groupDescription': 'الوصف',
  'charge.chargeList.groupTitle': 'فئات المصاريف',
  'charge.chargeList.date': 'التاريخ',
  'charge.chargeList.require': ' لا يمكن أن تكون فارغة',
  'charge.chargeList.amount': 'المبلغ',
  'charge.chargeList.addTitle': 'ادخال رسوم',
  'charge.chargeList.selectType': 'اختر فئة',
  'charge.chargeList.delete': 'حذف',
  'charge.deleteSuccess': 'تم الحذف بنجاح',
  'charge.typeName': 'العنوان',
  'charge.chargeList.edit': 'تعديل',
  'charge.chargeList.groupName': 'العنوان',
  'charge.chargeGroup.title': 'تجميع التكلفة',
  'charge.makeSureDelete': 'هل أنت متأكد أنك تريد حذف؟',
  'charge.chargeList.editTitle': 'رسوم التعديل',
  'charge.chargeList.selectDate': 'اختر وقت',
  'charge.chargeList.operation': 'أوامر',
}
