export default {
  'account.register.emailCheckCode': 'Email verification code',
  'account.shopAdd.shopNameFormatTip': 'Up to 50 characters',
  'account.register.falseCheckCode': 'Incorrect verification code',
  'account.register.reSend': 'Resend',
  'account.forgetPassword.checkCode': 'Verification code',
  'account.login.linkManager': 'Please contact the store owner',
  'account.shopAdd.shopInfo': 'Store Information',
  'account.login.register': 'Sign up',
  'account.shopAdd.domainUrl': 'Domain name',
  'account.register.passwordFalseFormat': 'Password must be 8-16 digits and recommended to contain letters and numbers',
  'account.resetPassword.currentAccount': 'Current account',
  'account.register.goAgree': 'By proceeding, you agree to',
  'account.resetPassword.newPassword': 'New password',
  'account.shopAdd.addShop': 'Create store',
  'account.shopAdd.freeUse': 'Submit',
  'account.register.sendMail': 'Send code',
  'account.resetPassword.backToLogin': 'Back to log in',
  'account.forgetPassword.falseMail': 'Email does not exist',
  'account.register.accountExist': 'Already have an account?',
  'account.register.privacy': 'Privacy Policy',
  'account.shopList.allShops': 'Stores',
  'account.shopAdd.shopDomainFormatTip': 'Up to 50 characters, which can be numbers or characters or \'-\' but cannot start with \'-\'',
  'account.register.passwordNoCommon': 'Inconsistent password',
  'account.orderDetail.paymentTime': 'Payment time',
  'account.resetPassword.password': 'Password',
  'account.register.success': 'Register successful',
  'account.register.confirmPassword': 'Confirm password',
  'account.login.canNotUse': 'The current invitation link has expired',
  'account.register.codeMailTips': 'Please fill in the verification code sent to your email',
  'account.register.firstName': 'First name',
  'account.resetPassword.passwordNoCommon': 'Inconsistent password',
  'account.forgetPassword.sendCheckMailButton': 'Send code',
  'account.register.and': 'and',
  'account.forgetPassword.requireText': 'Please fill in this field',
  'account.login.falseMail': 'Email does not exist',
  'account.register.password': 'Password',
  'account.resetPassword.passwordFalseLength': '8 to 16 characters in length',
  'account.login.onAccount': 'Don’t have an account？',
  'account.register.createButton': 'Create',
  'account.login.password': 'Password',
  'account.register.passwordCheckTips': '8-16 digits and recommended to contain letters and numbers',
  'account.login.forgetPassword': 'Forget password?',
  'account.forgetPassword.passwordMail': 'Please fill in the verification code sent to your email',
  'account.register.accountLogin': 'Log in',
  'account.forgetPassword.reSend': 'Resend',
  'account.shopAdd.country': 'Country/region',
  'account.login.mail': 'Email',
  'account.register.email': 'Email',
  'account.shopAdd.business': 'What business do you want to run?(Optional)',
  'account.forgetPassword.explainText': 'We need your email and verification code to make sure this is your account',
  'account.forgetPassword.falseCheckCode': 'Incorrect verification code',
  'account.login.back': 'Back',
  'account.resetPassword.reset': 'Reset password',
  'account.forgetPassword.email': 'Email',
  'account.resetPassword.passwordFalseFormat': 'Password must be 8-16 digits and recommended to contain letters and numbers',
  'account.forgetPassword.goOn': 'Continue',
  'account.register.serviceTerms': 'Terms of Service',
  'account.register.createAccount': 'Create your account',
  'account.register.mailExisted': 'This email has already been registered',
  'account.resetPassword.confirmButton': 'Confirm',
  'account.resetPassword.finish': 'Password setting finished',
  'account.login.login': 'Log in',
  'account.register.passwordPlaceHolder': '8 to 16 characters in length',
  'account.register.nextToCreateShop': 'Next step',
  'account.shopAdd.shopName': 'Store name',
  'account.login.falsePassword': 'Incorrect password',
  'account.resetPassword.confirmPassword': 'Confirm the password',
  'account.resetPassword.passwordRequireText': 'Password should be 8 to 16 characters in length',
  'account.register.lastName': 'Last name',
  'account.shopAdd.completeInfo': 'Complete the following information and open a shop for free.',
  'account.resetPassword.requireText': 'Please fill in this field',
  'account.register.requireTrueMail': 'Please enter the correct email',
  'account.register.passwordFalseLength': 'Password should be 8 to 16 characters in length',
  'account.resetPassword.oldPassword': 'Original password',
  'account.register.checkCode': 'Verification code',
  'account.register.requireText': 'Please fill in this field',
  'account.resetPassword.back': 'Back',
  'account.register.createFirstShop': 'Complete information',
}
