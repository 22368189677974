export default {
  'report.saleOverview.pagePath': 'Page name',
  'report.saleOverview.discounts': 'Discounts',
  'report.selectTextFifteenDaysAgo': 'Last 15 days',
  'report.saleOverview.totalOrdersTIps': 'The total number of orders in all sales channels within the statistical time.',
  'report.saleOverview.checkOut': 'Reached checkout',
  'report.saleOverview.unitPriceTIps': 'The payment amount of each customer within the statistical time.',
  'report.saleOverview': 'Data Overview',
  'report.customerStatistics.customerTotal': 'Total Customers',
  'report.saleOverview.expenses': 'Expenses',
  'report.saleOverview.orderNumber': 'Order No.',
  'report.saleOverview.orderCreateTimes': 'Order time',
  'report.saleOverview.hotPageTips': 'During the statistical time, the most visited web pages of the online store and visits per page.',
  'report.customerStatistics.unitPrice': 'Avg. Spend / Customer',
  'report.saleOverview.sessionsConverted': 'Sessions converted',
  'report.customerStatistics.returningVisitors': 'Returning Customers',
  'report.saleOverview.offlineSales': 'Offline Sales',
  'report.saleOverview.storeOrderConversion': 'Order conversion rate of online store',
  'report.saleOverview.totalOrders': 'Total Orders',
  'report.saleOverview.salesMargin': 'Gross Profit',
  'report.saleOverview.onlineSales': 'Online Sales',
  'report.saleOverview.shippingSpend': 'Shipping Spend',
  'report.customerStatistics.ordersTop': 'Top 10 customers by number of orders',
  'report.saleOverview.unitPrice': 'Price per customer transaction',
  'report.selectTextYesterday': 'Yesterday',
  'report.comparison': 'Compare',
  'report.saleOverview.salesVolumeTIps': 'During the statistical time, net sales (total sales minus discounts and returns) plus taxes and freight.',
  'report.saleOverview.monetaryUnit': 'Currency',
  'report.selectTextSevenDaysAgo': 'Last 7 days',
  'report.title': 'Analytics',
  'report.saleOverview.salesVolume': 'Sales',
  'report.customerStatistics': 'Customer Analysis',
  'report.saleOverview.orderTransferTIps': 'During the statistical time, the percentage of visits converted into orders to the total visits.',
  'report.saleOverview.addToCart': 'Added to cart',
  'report.saleOverview.hotSaleTopTips': 'In the statistical time, top 10 products of the sales.',
  'report.customerStatistics.newCustomers': 'New Customers',
  'report.saleOverview.times': 'times',
  'report.saleOverview.product': 'Product',
  'report.selectTextToday': 'Today',
  'report.saleOverview.hotSaleTop': 'Top 10 Trending products (by unit sales)',
  'report.saleOverview.onlineVisits': 'Online Store Visits',
  'report.selectTextThirtyDaysAgo': 'Last 30 days',
  'report.saleOverview.hotPage': 'Popular page',
  'report.saleOverview.totalSales': 'Total Sales',
  'report.saleOverview.salesAmount': 'Sales',
  'report.customerStatistics.amountTop': 'Top 10 customers by volume',
  'report.saleOverview.orderPaymentAmount': 'payment amount',
  'report.saleOverview.pageViewCount': 'Visits',
  'report.dayAfter': 'days ago',
}
