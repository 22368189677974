export default {
  'setting.payment.creditCardRateEG': 'البطاقة البنكية 2.25٪ + 2 جنيه ؛ المحفظة الإلكترونية 2.25٪ + 2 جنيه ؛ كود الدفع 2٪',
  'setting.store.stateAndGovernorate': 'المحافظة',
  'setting.payment.businessLaterDescription': 'إذا كنت ترغب في تفعيل حساب تجاري، يرجى تفعيله كحساب فردي أولاً، ثم قم بالتواصل مع خدمة العملاء للترقية إلى حساب تجاري',
  'setting.store.industry': 'نوع القطاع',
  'setting.store.titleDescription': 'عرض وتحديث تفاصيل متجرك',
  'setting.addStaff.sendInvitation': 'إرسال دعوة',
  'setting.tax.pleaseEnterTaxDescription': 'ادخال الشرح الضريبي ',
  'setting.payment.receiptSumDescription': 'إجمالي مبلغ عمليات الدفع أونلاين الناجحة',
  'setting.payment.agreeUseData': 'أوافق على استخدام بياناتي بالتوافق مع ذلك.',
  'setting.payment.activate': 'تفعيل',
  'setting.account.phone': 'رقم الموبايل',
  'setting.store.city': 'المدينة',
  'setting.store.title': 'اعدادات المتجر',
  'setting.payment.selectIndustry': 'حدد قطاع الصناعة',
  'setting.payment.underReview': 'قيد المراجعة',
  'setting.add': 'اضافة',
  'setting.logistics.customShipping': 'تكلفة شحن مخصصة لمنطقة معينة',
  'setting.logisticsEdit.description': 'الوصف',
  'setting.payment.paymentActivated': 'تم تفعيل الدفع عن طريق OPay. يمكنك استلام المدفوعات الآن',
  'setting.account.titleDescription': 'اللغات التي يمكن للعملاء استخدامها عند عرض المتاجر عبر الإنترنت.',
  'setting.shopSetting.onlineLanguage': 'لغة المتجر الإلكتروني',
  'setting.payment.createEnterpriseAccount': 'إنشاء حساب OPay للمؤسسة',
  'setting.payment.cancel': 'إالغاء',
  'setting.domainList.actions': 'تشغيل',
  'setting.domainList.course': 'عرض التعليمات',
  'setting.pleaseEnterMail': 'الرجاء إدخال البريد الإلكتروني الخاص بك',
  'setting.logistics.titleDescription': 'إدارة كيفية إرسال المنتجات للعملاء',
  'setting.logistics.addShippingForOther': 'إضافة تكلفة شحن لمناطق أخرى',
  'setting.notifications.title': 'الإشعارات',
  'setting.smsManage.title': 'إدارة الرسائل القصيرة',
  'setting.payment.businessContactInfo': 'معلومات الاتصال المهنية',
  'setting.payment.uploadOwnerAddress': 'تحميل دليل على عنوان المالك',
  'setting.store.postcode': 'الرمز البريدي',
  'setting.notifications.messageSendDate': 'التاريخ',
  'setting.payment.businessLogoLimitDescription': 'قم بتحميل الصور ك jpg / jepg / png / pdf فقط',
  'setting.selectTextGeneralStaff': 'طاقم عمل',
  'setting.payment.bossInfo': 'معلومات صاحب العمل',
  'setting.store.streetNumber': 'العنوان',
  'setting.payment.bankAccountName': 'اسم الحساب المصرفي',
  'setting.payment.amountToSettleDescription': 'المبلغ الإجمالي الذي لم يتم تسويته في حسابك البنكي بعد قيام العملاء بالدفع وتم خصم رسوم الخدمة بنجاح.',
  'setting.logistics.openCodTips': 'تم تفعيل الدفع عند الاستلام',
  'setting.store.description': 'الوصف',
  'setting.account.storeLanguageDescription': 'هذه هي لغة العرض للعملاء الزائرين في متجرك.',
  'setting.tax.editTax': 'تعديل',
  'setting.domain.bindDomainQuantityHint': 'يمكن ربط نطاقين للموقع (دومين) فقط على الأكثر',
  'setting.payment.settlementPeriod': 'فترة التسوية',
  'setting.payment.bankName': 'اسم البنك',
  'setting.payment.businessType': 'ما هو مجال عملك؟',
  'setting.logistics.name': 'العنوان',
  'setting.logistics.pickInStore': ' استلام المنتج في المتجر',
  'setting.domainList.mainDomain': 'أساسي',
  'setting.account.preferredLanguageDescription': 'سيتم استخدام هذه اللغة بشكل افتراضي عند تسجيل الدخول. ولا تؤثر على إعدادات اللغة للعملاء الزائرين في متجرك الإلكتروني.',
  'setting.payment.helpEmail': 'البريد الإلكتروني للدعم',
  'setting.selectTextYes': 'يوجد',
  'setting.logistics.isShowEarnings': 'مرئي في صفحة اتمام الطلب',
  'setting.chargeManage.title': 'ادارة المصاريف',
  'setting.logistics.shippingFreeDescription': 'لا يحتاج العملاء دفع تكلفة الشحن عند إرسال الطلب ',
  'setting.tax.percentage': 'نسبة الضريبة',
  'setting.account.lastName': 'الاسم الأخير',
  'setting.selectTextNo': 'لا يوجد',
  'setting.payment.description': 'تم عرض صفحة طلب الدفع علي موقعك ',
  'setting.payment.selectSettlementType': 'حدد نوع التسوية',
  'setting.payment.account': 'رقم الحساب',
  'setting.staff.mail': 'البريد الإلكتروني',
  'setting.pleaseEnterLastName': 'الرجاء ادخال اللقب',
  'setting.payment.ImprovingInfoAddLimit': 'لن تتم معالجة أي تسوية حتى اكتمال إعداد الحساب.',
  'setting.logistics.regionName': 'اسم المنطقة',
  'setting.domain.requiredValue': 'القيمة المطلوبة',
  'setting.logistics.reserveOrder': 'حفظ الطلب',
  'setting.staff.addStaff': 'إضافة موظف',
  'setting.payment.selectBank': 'حدد اسم البنك',
  'setting.payment.todayReceipt': 'مبلغ مدفوعات اليوم',
  'setting.payment.socialMedia': 'الأعمال التجارية على وسائل التواصل الاجتماعي',
  'setting.payment.uploadSignId': 'تحميل هوية الموقع (صاحب العمل)',
  'setting.payment.businessDescription': 'وصف خدمات الأعمال',
  'setting.pleaseEnterCarriage': 'الرجاء إدخال رسوم الشحن',
  'setting.payment.openFail': 'فشل في الفتح!',
  'setting.charge.title': ' تفاصيل المصاريف',
  'setting.account.resetPassword': 'إعادة تعيين كلمة المرور',
  'setting.logistics.customShippingDescription': 'يجب أن تحدد تكلفة الشحن لمناطق مختلفة. لا يمكن للعملاء إرسال الطلبات إذا لم تقم بتحديد تكلفة الشحن للمنطقة الخاصة بالعميل  ',
  'setting.store.cancel': 'إلغاء',
  'setting.logistics.stopCodTips': 'تم إيقاف الدفع عند الاستلام',
  'setting.chargeManage.titleDescription': 'الإعدادات - إدارة الفواتير - xxx (وصف إعادة شحن الرسائل القصيرة)',
  'setting.payment.dailyReceiptLimit': 'الحد اليومي لاستلام المدفوعات',
  'setting.payment.merchantInfo': 'معلومات التاجر',
  'setting.logisticsEdit.name': 'العنوان',
  'setting.pleaseSelectIndustry': 'تحديد المهنة',
  'setting.payment.idType': 'نوع الهوية الشخصية',
  'setting.payment.activeStatus': 'مفعل',
  'setting.payment.transactionFee': 'رسوم الخدمة',
  'setting.payment.creditCardRateNg': '1.5% كحد أقصى 2000 لكل معاملة',
  'setting.payment.untilSettlementBeforeReview': 'أي تسوية سيتم تجهيزها حتى يتم الانتهاء من مراجعة الحسابات .',
  'setting.store.tagline': '',
  'setting.payment.upload': 'تحميل',
  'setting.tax.pleaseEnterTaxPercentage': 'ادخل النسبة الضريبية',
  'setting.staff.titleDescription': 'إدارة ما يمكن للموظفين رؤيته أو القيام به في متجرك',
  'setting.payment.enterAccount': 'أدخل رقم الحساب',
  'setting.account.storeLanguage': 'لغة المتجر الإلكتروني',
  'setting.logistics.modifyText': 'هل أنت متأكد من حذف إعدادات تكلفة الشحن؟ لن يتمكن العملاء من القيام بعملية الشراء',
  'setting.payment.activatePayment': 'تفعيل الدفع عن طريق OPay',
  'setting.logistics.shippingType': 'طريقة الشحن',
  'setting.logistics.selectAdministrativeRegion': 'اختر المنطقة',
  'setting.payment.dailySettlementDescription': 'ستتم معالجة التسوية اليومية.',
  'setting.account.language': 'اللغة',
  'setting.store.whatsApp': 'واتساب',
  'setting.payment.wallet': 'المحفظة',
  'setting.domain.list': 'لائحة نطاقات الموقع (الدومين)',
  'setting.tax.name': 'الضريبة',
  'setting.payment.cashier': 'صفحة الدفع',
  'setting.logistics.price': 'التكلفة',
  'setting.payment.idNumber': 'رقم الهوية الشخصية',
  'setting.staff.title': 'إدارة الموظفين',
  'setting.store.storeName': 'اسم المتجر',
  'setting.payment.noOpenDescription': 'قم بإنشاء حساب الدفع OPay الخاص بك بنقرة واحدة. ',
  'setting.payment.selectSocialMedia': 'حدد وسائل التواصل الاجتماعي',
  'setting.logistics.shippingFlat': 'تكلفة شحن ثابتة',
  'setting.logisticsEdit.price': 'التكلفة',
  'setting.store.storeUrl': 'رابط المتجر',
  'setting.payment.checkAccount': 'التحقق من الحساب البنكي',
  'setting.payment.businessNameEg': 'الاسم التجاري بالانجليزية',
  'setting.makeSureDelete': 'هل أنت متأكد أنك تريد حذف؟',
  'setting.payment.ownerAddress': 'عنوان المالك',
  'setting.logistics.shippingAmount': 'مبلغ تكلفة الشحن',
  'setting.logistics.courierDelivery': 'البريد السريع',
  'setting.payment.needHelp': 'هل تحتاج مساعدة؟',
  'setting.shopSetting.onlineLanguageLabel': 'اللغات',
  'setting.domain.formDomainPlaceholder': 'مثال لاسم النطاق: example.com',
  'setting.payment.title': 'الدفع',
  'setting.selectTextShopManager': 'صاحب العمل ',
  'setting.logistics.title': 'تكلفة الشحن',
  'setting.domainList.createDate': 'التاريخ',
  'setting.payment.settlementIntervalDescription': 'دورة تسوية المدفوعات في حسابك البنكي بعد قيام العملاء بالدفع بنجاح',
  'setting.logistics.stopCodSuccessTips': 'تم إيقاف الدفع عند الاستلام',
  'setting.payment.amountToBeSettled': 'مبلغ التسوية',
  'setting.store.addressDescription': 'سيظهر هذا العنوان في فاتورتك. يمكنك تحرير العنوان المستخدم لحساب الشحن في إعدادات الشحن. قد يؤثر مكان عملك الأساسي على التطبيقات المتوفرة في متجرك',
  'setting.payment.businessTypeDetail': 'نوع الحساب',
  'setting.payment.currency': 'العملة',
  'setting.logistics.free': 'مجانا',
  'setting.payment.modifyEmailText': 'تغيير البريد الإلكتروني',
  'setting.payment.address': 'العنوان',
  'setting.domain.describe': 'نطاق الموقع (الدومين) في رابط العملاء الذين يزورون المتجر الإلكتروني',
  'setting.tax.title': 'ضرائب',
  'setting.title': 'إدارة الإعدادات',
  'setting.payment.and': 'و',
  'setting.payment.selectIdType': 'حدد نوع الهوية الشخصية',
  'setting.store.country': 'الدولة',
  'setting.logistics.openCodModelTips': 'بمجرد التفعيل، يمكن للعملاء اختيار الدفع عند الاستلام كطريقة للدفع',
  'setting.domainList.bindExistingDomain': 'ربط نطاق المجال(الدومين) الحالي',
  'setting.payment.website': 'رابط موقع الأعمال التجارية',
  'setting.logistics.currentUsed': 'الحالي',
  'setting.pleaseEnterInternationalCode': 'ادخل رمز الدولة',
  'setting.edit': 'تعديل',
  'setting.payment.settingPaymentAccountHelp': 'كيفية إعداد حساب دفع OPay؟',
  'setting.staff.search': 'بحث',
  'setting.account.password': 'كلمة المرور',
  'setting.logistics.shippingFlatDescription': 'سيقوم العميل بدفع تكلفة الشحن المحددة فقط، و ذلك يشمل التوصيل إلى جميع المناطق',
  'setting.payment.contactInformation': 'معلومات الاتصال',
  'setting.payment.birthday': 'تاريخ الميلاد',
  'setting.addStaff.staffDescription': 'السماح للموظفين بالدخول إلى متجرك عن طريق إرسال دعوات لهم',
  'setting.staff.creatTime': 'وقت الإنشاء',
  'setting.logistics.addFreeShipping': 'إضافة تكلفة شحن',
  'setting.store.currencyDescription': 'هذه العملة المحددة لبيع منتجك.',
  'setting.payment.enterBvn': 'أدخل BVN',
  'setting.payment.enterPhone': 'أدخل رقم الهاتف',
  'setting.addStaff.contactStoreManage': 'يرجى التواصل مع مدير المتجر',
  'setting.payment.accountInfo': 'معلومات الحساب البنكي',
  'setting.domain.configContent': 'مطلوب إعداد محتوى توجيه النطاق و',
  'setting.payment.area': 'المنطقة',
  'setting.logistics.pickUpDescription': 'من أجل تسهيل استلام المنتج للعميل، يرجى إدخال معلومات موقع الاستلام، والتي سيتم عرضها عند قيام العميل بعملية الدفع',
  'setting.domain.domain': 'نطاق الموقع (الدومين)',
  'setting.logistics.getShopInfoFail': 'فشل في الحصول على معلومات المتجر',
  'setting.addStaff.mail': 'البريد الإلكتروني',
  'setting.domainList.linked': 'متصل',
  'setting.payment.socialMediaAccount': 'موقع / حساب وسائل الاعلام الاجتماعية',
  'setting.domain.bind': 'تحقق من الاتصال',
  'setting.payment.phone': 'رقم الهاتف',
  'setting.logistics.selectShippingType': 'اختر نوع تكلفة الشحن',
  'setting.logistics.pickUpAddress': 'موقع الاستلام',
  'setting.logistics.openCodTips\t': 'تم تفعيل الدفع عند الاستلام',
  'setting.payment.toOpen': 'تفعيل',
  'setting.chargeGroup.titleDescription': 'وصف قائمة تجميع المصاريف',
  'setting.payment.enterAccountName': 'أدخل اسم الحساب',
  'setting.tax.addTax': 'ضريبة جديدة',
  'setting.payment.bankInfo': 'معلومات الحساب المصرفي',
  'setting.logistics.editShipping': 'تعديل',
  'setting.payment.phoneNumber': 'رقم الهاتف',
  'setting.payment.Individual': 'فردي',
  'setting.payment.selectState': 'يختار',
  'setting.store.save': 'حفظ',
  'setting.account.cancel': 'إلغاء',
  'setting.internationalCode': 'رمز الدولة',
  'setting.payment.businessLater': 'تجاري (سيتم تفعيله لاحقا)',
  'setting.selectTextRead': 'مقروءة',
  'setting.payment.createPersonalAccount': 'إنشاء حساب OPay شخصي',
  'setting.payment.withinHours': 'خلال ٢٤ ساعة',
  'setting.domain.bindFail': 'خطأ في التحقق',
  'setting.domainList.united': 'غير متصل',
  'setting.message.loadingAllText': '',
  'setting.store.storeMessageDescription': 'سيتخدم عملائك و باندوفا هذه المعلومات للتواصل معك',
  'setting.staff.delete': 'حذف',
  'setting.payment.businessLogo': 'شعار العمل',
  'setting.domain.domainInputVerify': 'الرجاء إدخال نطاق الموقع (الدومين) الصحيح الخاص بك.',
  'setting.payment.enterTradingName': 'أدخل الاسم التجاري',
  'setting.logisticsEdit.isShowStore': 'إظهار في المتجر',
  'setting.pleaseSelectLanguage': 'الرجاء تحديد لغة',
  'setting.payment.opaySuccessDescription': 'تمت الموافقة على حساب الدفع الخاص بك. يمكنك الذهاب إلى OPay لمزيد من المعلومات!',
  'setting.tax.compoundTax': 'ضرائب إضافية',
  'setting.pleaseSelectState': 'تحديد المنطقة',
  'setting.payment.LogoLimitDescription': 'لا يمكن أن يتجاوز حجم الصورة 512 كيلو بايت',
  'setting.payment.titleDescription': 'تمكين وإدارة مزودي خدمة الدفع للمتاجر',
  'setting.logistics.shippingName': 'اسم تكلفة الشحن',
  'setting.store.storeCurrency': 'عملة المتجر',
  'setting.addStaff.staff': 'موظف',
  'setting.logistics.stopCod': 'تم إيقاف الدفع عند الاستلام',
  'setting.logistics.addFlatShipping': 'إضافته كتكلفة شحن ثابتة',
  'setting.account.details': 'التفاصيل',
  'setting.tax.description': 'الوصف',
  'setting.addStaff.cancel': 'إلغاء',
  'setting.delete': 'حذف',
  'setting.deleteSuccess': 'تم الحذف بنجاح',
  'setting.notifications.messageTitle': 'المحتوى',
  'setting.logistics.specificRegion': 'منطقة محددة',
  'setting.addStaff.urlInvalid': 'انتهى صلاحية رابط الدعوة',
  'setting.payment.signatoryName': 'توقيع الاسم ',
  'setting.logisticsEdit.save': 'حفظ',
  'setting.payment.providedByOpay': 'سيتم تقديم خدمة الدفع عن طريق OPay ',
  'setting.payment.bankAccount': 'رقم الحساب المصرفي',
  'setting.logistics.addShipping': 'إضافة',
  'setting.payment.submit': 'إرسال',
  'setting.payment.bankSwift': 'كود SWIFT الخاص ببنك الشركة',
  'setting.staff.state': 'الحالة',
  'setting.tax.save': 'حفظ',
  'setting.payment.unOpenPayment': 'OPay',
  'setting.payment.bankToAccount': 'الحساب البنكي',
  'setting.domain.promptInfoText': 'تحتاج إلى تسجيل الدخول باستخدام حساب المزود الخاص بك وتغيير الإعدادات قبل أن تتمكن من الاتصال بنطاق الموقع (الدومين) الخاص بك. تحقق من الاتصال للتأكد من تعيين نطاق الموقع (الدومين) الخاص بك بشكل صحيح.',
  'setting.store.phone': 'رقم الموبايل',
  'setting.payment.governorate': 'المحافظة',
  'setting.payment.goToOpay': 'اذهب إلى OPay',
  'setting.account.title': 'إعدادات الحساب',
  'setting.store.storeMessage': 'تفاصيل المتجر',
  'setting.payment.reviewFailed': 'لقد فشل السماح بربط حساب الدفع علي اوباي',
  'setting.tax.pleaseEnterTaxName': 'ادخال الاسم الضريبي',
  'setting.payment.settlementCycle': 'فترة التسوية',
  'setting.account.mail': 'البريد الإلكتروني',
  'setting.account.firstName': 'الاسم الأول',
  'setting.logistics.pickUpTitle': 'تم استلام طلب جديد',
  'setting.selectTextUnread': 'غير مقروءة',
  'setting.store.storeLogo': 'شعار المتجر',
  'setting.payment.selectBirthday': 'اختر تاريخ الميلاد',
  'setting.payment.opened': 'مفعل',
  'setting.payment.optional': '(اختياري)',
  'setting.payment.transactionFeeText': 'رسوم العملية ',
  'setting.payment.creditCardRate': '0',
  'setting.payment.limitNow': 'حد مجموعتك الآن',
  'setting.logistics.addToSpecificRegion': 'إضافته إلى منطقة محددة',
  'setting.payment.toComplete': 'قم بتعبئة المعلومات الشخصية',
  'setting.logistics.closeCodModelTips': 'عند إلغاء الطلب، سيتم إعادة تخزين المنتج',
  'setting.payment.willOpenPayment': 'سيتم تفعيل الدفع',
  'setting.payment.toOpenMode': 'تفعيل طريقة الدفع',
  'setting.account.preferredLanguage': 'اللغة المفضلة',
  'setting.payment.openSuccess': 'تهانينا ، يمكنك الآن البدء في البيع.',
  'setting.payment.registerEmailTip': 'بريدك الإلكتروني المسجل هو',
  'setting.payment.termsOfUse': 'تعليمات الاستخدام',
  'setting.payment.dailyReceiptLimitDescription': 'الحد الأقصى اليومي لإجمالي مبلغ عمليات الدفع أونلاين الناجحة',
  'setting.store.address': 'عنوان المتجر',
  'setting.payment.completeAccountSetup': 'إكمال إعداد الحساب',
  'setting.shopSetting.onlineLanguageDescription': 'ستستخدم العملاء هذه اللغة عند تصفح متجرك على الانترنت .',
  'setting.staff.activityTime': 'وقت التفعيل',
  'setting.payment.enterAddress': 'أدخل العنوان',
  'setting.payment.tradingName': 'الاسم التجاري',
  'setting.logistics.settingText': 'تأكيد حفظ الإعدادات',
  'setting.detail': 'تفاصيل',
  'setting.logistics.stopCodDescription': 'بمجرد الإلغاء، لن يتمكن العملاء من اختيار الدفع عند الاستلام كطريقة للدفع',
  'setting.payment.uploadFile': 'تحميل الملفات',
  'setting.payment.failDescription': 'المعذرة!!! لم تتم الموافقة على حساب الدفع الخاص بك.',
  'setting.payment.bvn': 'BVN',
  'setting.domainList.status': 'الحالة',
  'setting.store.selectFile': 'اختر ملف',
  'setting.payment.cashierConfig': 'إعدادات الدفع',
  'setting.domain.tautologyBind': 'تحقق مرة أخرى',
  'setting.domainList.delete': 'حذف',
  'setting.payment.businessCategory': 'فئة العمل',
  'setting.payment.enterWebsite': 'أدخل رابط موقع الأعمال التجارية',
  'setting.payment.receiptSum': 'إجمالي مبلغ المدفوعات',
  'setting.payment.privacyPolicy': 'سياسة الخصوصية',
  'setting.pleaseEnterTransport': 'الرجاء إدخال اسم طريقة الشحن',
  'setting.payment.receipt': 'الدفع',
  'setting.payment.haveRead': 'أؤكد أنني قد قرأت OPay',
  'setting.mailFailed': 'إدارة الطريقة التي يقوم بها المتجر بتحصيل الضرائب',
  'setting.payment.settlementType': 'نوع التسوية',
  'setting.payment.selectCity': 'يختار',
  'setting.payment.industryInformation': 'معلومات قطاع الصناعة',
  'setting.pleaseEnterFirstName': 'الرجاء ادخال الاسم ',
  'setting.logistics.shippingFree': 'شحن مجاني',
  'setting.domainList.settingMainDomain': 'تعيين كنطاق موقع (دومين) أساسي',
  'setting.payment.accountName': 'إسم الحساب',
  'setting.payment.paymentOpened': 'تم تفعيل دفع OPay',
  'setting.payment.businessLogoDescription': 'نوصيك باستخدام شعار بأبعاد 100 بكسل × 100 بكسل للحصول على أفضل النتائج في نموذج الدفع. الحجم الأقصى: 512 كيلو بايت',
  'setting.logistics.shippingMethodsLabel': 'أنواع الشحن',
  'setting.payment.creditCardRateText': 'رسوم العملية ',
  'setting.payment.residentialAddress': 'عنوان السكن',
  'setting.payment.underReviewDescription': 'لن تتم عملية التسوية حتى تكتمل المراجعة.',
  'setting.charge.titleDescription': 'وصف تفاصيل المصاريف',
  'setting.logistics.addCustomShipping': 'إضافة تكلفة شحن إلى منطقة محددة',
  'setting.payment.otherFile': 'أخرى (تحميل مستندات أخرى) (اختياري)',
  'setting.payment.nearestMark': 'أقرب معلم',
  'setting.operation': 'أوامر',
  'setting.account.editPassword': 'تعديل كلمة المرور',
  'setting.payment.needSetUpSoon': 'لن تتم معالجة أي تسوية حتى اكتمال إعداد الحساب. تحتاج إلى إنشاء حسابك في أقرب وقت ممكن ، وإلا فسيتم تجميد حسابك بعد شهر واحد من فتحه.',
  'setting.payment.limit': 'حد مجموعتك الحالية هو',
  'setting.shipping': 'الشحن',
  'setting.buttonTips': 'فقط صاحب المتجر لديه هذا الإذن',
  'setting.payment.industry': 'قطاع الصناعة',
  'setting.payment.chooseFile': 'اختر ملف للتحميل （صورة أو PDF）',
  'setting.addStaff.lastName': 'الاسم الأخير',
  'setting.payment.enterBusiness': 'أدخل عملك',
  'setting.smsManage.titleDescription': 'وصف إحصائيات الرسائل القصيرة',
  'setting.payment.businessNameArabic': 'الاسم التجاري بالعربية',
  'setting.logistics.editSpecificRegion': 'تعديل منطقة محددة',
  'setting.payment.subCategory': 'تصنيف فرعي',
  'setting.account.save': 'حفظ',
  'setting.payment.todayReceiptDescription': 'إجمالي مبلغ عمليات الدفع أونلاين الناجحة اليوم',
  'setting.addStaff.firstName': 'الاسم الأول',
  'setting.payment.verifyMailTitle': 'تأكيد البريد الإلكتروني',
  'setting.payment.bankBranch': 'فرع البنك',
  'setting.payment.opayKycSuccessDescription': 'مبروك، تم تفعيل حساب الدفع الخاص بك',
  'setting.pleaseEnterStoreName': 'ادخل اسم المتجر',
  'setting.staff.name': 'الاسم',
  'setting.logistics.addSpecificRegion': 'إضافة منطقة محددة',
  'setting.tax.titleDescription': 'إدارة الطريقة التي يقوم بها المتجر بتحصيل الضرائب',
  'setting.payment.review': 'قيد المراجعة',
  'setting.payment.cod': 'الدفع عند الاستلام',
  'setting.payment.backEmail': 'البريد الإلكتروني لرد المبالغ المدفوعة',
  'setting.payment.ImprovingInfoOpenSettlement': 'انقر أدناه لإكمال إعداد الحساب.',
}
