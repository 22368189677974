export default {
  'order.orderList.phoneNumber': 'Phone',
  'order.orderDetail.logistics.overview.noSend': 'The item is unshipped, so no delivery information is available yet',
  'order.orderDetail.logistics.overview.NotifyShipment': 'Notify the customer of the shipment status',
  'order.orderEdit.selectStatus': 'Select Payment Status',
  'order.orderDetail.shipped': 'Shipped',
  'order.throw.copy.text': 'Copy link',
  'order.orderList.orderFilter': 'Filters',
  'order.orderDetail.logistics.overview.CancelSend': 'Cancel fulfillment',
  'order.orderDetail.shippingStatus': 'Shipping Status',
  'order.orderDetail.orderSummary': 'Orders',
  'order.orderDetail.canceled': 'Cancelled',
  'order.orderDetail.logistics.overview.CancelFulfillment': 'Cancel fulfillment',
  'order.orderDetail.copyLinkDescription': 'You can copy this link and send to your customers. They can pay via this link. ',
  'order.orderList.payMethodName': 'Payment Option',
  'order.orderEdit.markToFormalTips': 'After the mark is successful, it cannot be modified again. Please confirm whether the information is correct.',
  'order.orderDetail.logistics.overview.EditShipmentInformation': 'Edit a shipment ',
  'order.orderList.orderAmount': 'Total',
  'order.throw.list.action': 'Action',
  'order.orderList.orderStatus': 'Status',
  'order.orderEdit.moneyAmount': 'Amount',
  'order.orderDetail.logistics.overview.Carrier': 'Carrier',
  'order.orderEdit.moneyPercentage': 'Percentage',
  'order.orderEdit.markToFormalOrder': 'Mark as normal order',
  'order.orderDetail.productMemo': 'Notes',
  'order.orderEdit.doNotOperate': 'This order can not be edited as it is under processing',
  'order.orderDetail.logistics.overview.AddInformation': 'Add shipment information',
  'order.orderDetail.logistics.overview.SendInformation': 'Send the shipment information to the customer',
  'order.orderDetail.productInfo': 'Items',
  'order.orderEdit.paid': 'Paid',
  'order.orderList.StartTime': 'Order Date (From)',
  'order.throw.send.success': 'A reminder email to recover abandoned carts has been sent to customer at sendTime',
  'order.orderEdit.selectCustomer': 'Select Customer',
  'order.orderDetail.logistics.AddDeliveryInformation': 'Add Information',
  'order.orderList.deliveredOrder': 'Shipped',
  'order.orderEdit.discountValueValidator': 'The discount amount cannot be greater than the total price of the goods',
  'order.orderDetail.preview': 'Preview',
  'order.orderDetail.unPaid': 'Unpaid',
  'order.orderEdit.editOrder': 'Edit draft order',
  'order.orderEdit.discountPercentCheck': 'Discount ratio cannot be greater than 100',
  'order.orderList.saleChannel': 'Sales Channel',
  'order.orderDetail.logistics.overview.EditShipment': 'Edit a shipment ',
  'order.orderDetail.shippingSelect': 'Shipping Options',
  'order.orderEdit.orderMemo': 'Notes (optional)',
  'order.orderDetail.sendPaymentLink': 'Send payment link',
  'order.orderEdit.unPaid': 'Not paid',
  'order.orderEdit.editProduct': 'Edit',
  'order.orderDetail.sendNote': 'Send Receipt',
  'order.orderEdit.saleChannel': 'Sales Channel',
  'order.orderList.isDraftOrder': 'Active/Draft',
  'order.orderDetail.productName': 'Item',
  'order.orderDetail.transactionNo': 'Transaction No.',
  'order.throw.status.recovery': 'Recovery status',
  'order.orderDetail.logistics.TrackingNumber': 'Tracking number',
  'order.orderList.customerName': 'Customer',
  'order.orderDetail.shippingFee': 'Shipping fee',
  'order.orderDetail.billText': 'Invoice',
  'order.orderDetail.logistics.overview.CancelConfirmMessage': 'The product has been shipped. Are you certain that you want to cancel the shipment?',
  'order.orderEdit.discountType': 'Discount Type',
  'order.orderEdit.selectAddress': 'Select address',
  'order.orderDetail.productUnitPrice': 'Price',
  'order.orderDetail.productAmount': 'Qty',
  'order.orderDetail.orderNo': 'Order No.',
  'order.throw.order.placed': 'Order placed',
  'order.orderDetail.relationInfo': 'Contact information',
  'order.throw.throw.title': 'Abandoned carts',
  'order.orderDetail.markPaidTips': 'If you have received cash on delivery, please mark it as \'paid\'',
  'order.orderDetail.pickUpInStore': 'Pick up in store ',
  'order.orderDetail.pickUpInLogistics': 'Courier service',
  'order.orderList.unPaidOrder': 'Unpaid',
  'order.orderEdit.totalAmount': 'Total Amount',
  'order.orderDetail.orderSource': 'via',
  'order.orderEdit.productMemo': 'Notes',
  'order.orderEdit.orderList': 'Products',
  'order.orderDetail.logistics.overview.HasShipped': 'The seller has shipped your order but no delivery information is available yet',
  'order.orderEdit.manualAddOrder': 'Manually',
  'order.throw.detail.btn': 'Details',
  'order.orderDetail.customerInfo': 'Customer',
  'order.orderDetail.memoRequiredTip': 'Please input notes',
  'order.orderList.draftStatus': 'Active/Draft',
  'order.orderDetail.tax': 'Tax',
  'order.orderDetail.newOrderNotification': 'New COD order is received',
  'order.orderEdit.unManualAddOrder': 'Online store',
  'order.orderEdit.discount': 'Discount',
  'order.orderDetail.processing': 'To be shipped',
  'order.orderDetail.paymentLinkCopedToast': 'Payment link copied',
  'order.orderEdit.selectProduct': 'Select Products',
  'order.throw.send.customer': 'Are you sure to send a recovery email to the customer?',
  'order.orderDetail.exportText': 'Export',
  'order.orderList.title': 'All Orders',
  'order.orderDetail.logistics.Carrier': 'Carrier',
  'order.orderEdit.shippingFee': 'Shipping Fee',
  'order.orderDetail.logistics.Confirm': 'Confirm',
  'order.orderEdit.totalGoodsPrice': 'Subtotal',
  'order.orderEdit.orderStartDate': 'Order Date',
  'order.orderEdit.payMethod': 'Payment Mode',
  'order.orderDetail.logistics.overview.ConfirmSend': 'Confirm a shipment',
  'order.orderDetail.sendOrder': 'Send Invoice',
  'order.orderEdit.addProduct': 'New Product',
  'order.orderDetail.markPaidText': 'Marked as paid',
  'order.orderDetail.payStatus': 'Payment Status',
  'order.orderList.orderNo': 'Order',
  'order.orderList.EndTime': 'Order Date (To)',
  'order.orderDetail.productDiscount': 'Discount',
  'order.orderDetail.logistics.DeliveryProgress': 'Delivery progress',
  'order.throw.list.sent.status': 'Send status',
  'order.orderDetail.logistics.overview.Cancel': 'Cancel',
  'order.orderEdit.formatOrder': 'Active',
  'order.orderList.orderFilterStatus': 'Order Status',
  'order.orderDetail.returned ': 'Returned',
  'order.orderDetail.paymentInfo': 'Payment',
  'order.orderEdit.tax': 'Taxes',
  'order.orderList.allOrder': 'Orders',
  'order.orderList.completedOrder': 'Completed',
  'order.throw.send.btn.text': 'Send the recovery email',
  'order.orderEdit.saveProductInfo': 'Save',
  'order.orderDetail.logistics.noDetail': 'No logistics information is available',
  'order.orderDetail.cancelOrder': 'The order is cancelled',
  'order.orderEdit.addOrder': 'Create draft order',
  'order.orderDetail.newOrder': 'New Order',
  'order.throw.send.success.toast': 'The recovery email has been sent successfully',
  'order.orderDetail.shippingAddress': 'Delivery Address',
  'order.orderEdit.customerSelectRequireTip': 'Please select a customer',
  'order.orderEdit.saveToOfficial': 'Record Sale',
  'order.orderDetail.totalGoodsPrice': 'Subtotal',
  'order.orderList.searchOrder': 'Apply',
  'order.orderEdit.addNewCustomer': 'Create a new customer',
  'order.orderDetail.orderMemo': 'Notes',
  'order.orderDetail.orderDetailText': 'Order details',
  'order.orderDetail.payInfo': 'Payment',
  'order.orderDetail.requestPay': 'Request Payment',
  'order.orderList.addOrder': 'Create draft order',
  'order.orderDetail.completed': 'Completed',
  'order.orderEdit.deleteProduct': 'Remove',
  'order.orderDetail.logistics.deliverGoods': 'Ship the product',
  'order.orderDetail.billAddress': 'Billing Address',
  'order.orderList.orderStartTime': 'Date',
  'order.orderList.willDealOrder': 'Pending',
  'order.orderDetail.totalAmount': 'Total',
  'order.orderDetail.logistics.completeInformation': 'Please complete the logistics information',
  'order.orderEdit.draftOrder': 'Draft',
  'order.orderEdit.productAmount': 'Quantity',
}
