export default {
  'home.quickMoveShop': 'Store Transfer',
  'home.kycInfo.accountFeozen': 'Your OPay account has been frozen. Money collection and settlement are disabled. ',
  'home.shippingTypeHasSet': 'Shipping type set',
  'home.paymentMethodExplanation': 'Choose which payment method your store will offer.',
  'home.addDomainOperationText': 'Add Domain',
  'home.viewPaymentDetail': 'View details',
  'home.kycInfo.paymentReceived': 'Payment received',
  'home.productAdded': 'Product added',
  'home.kycInfo.comma': ',',
  'home.payment.goSet': 'Set now',
  'home.goSettingUpdateShipping': 'You can go to Settings to modify the shipping types.',
  'home.completedStepsPercent': 'Complete',
  'home.adminLanguage': 'Preferred language',
  'home.kycInfo.accountNotset': 'Your account has been frozed because you have not set OPay account.',
  'home.addProductExplanation': 'Add the physical or digital products you want to sell.',
  'home.shopThemeExplanation': 'Create a better customer experience with customized themes and smart modules.',
  'home.selectThemeOperationText': 'Select Themes',
  'home.shopNeed': ' Shop needs',
  'home.shippingFee': 'Shipping fee',
  'home.domainSetting': 'Add Your Own Domain',
  'home.kycInfo.onlyBeUsed': 'can only be used for',
  'home.dowloadText': 'Download',
  'home.addOtherProduct': 'Add another product',
  'home.title': 'Home',
  'home.kycInfo.accountPayment': 'This account has received payment',
  'home.todaySales': 'Sales',
  'home.shopInfoComplete': 'Complete store information',
  'home.kycInfo.willFrozen': 'Account will be frozen after',
  'home.shopMallPreview': 'View your store',
  'home.productAddedGuide': 'You can go to Product page to add more products.',
  'home.kycInfo.maxAccount': 'Maximum collection amount',
  'home.kycInfo.remaining': 'remaining ',
  'home.logout': 'Log out',
  'home.todayPageVisit': 'Page view',
  'home.paymentActivated': 'Payment activated',
  'home.kycInfo.setNow': 'Set now',
  'home.shopHasInit': 'Initialization setup completed.',
  'home.kycInfo.daysOfBracket': 'days',
  'home.kycInfo.NotSettle': 'can not settle',
  'home.shopMoveTip': 'Got an online store somewhere else? Import it to Pandova!',
  'home.creatShop': 'Create store',
  'home.domainSettingExplain': 'Adding a custom domain will make your store stand out.',
  'home.shopThemeUpdated': 'Store theme updated',
  'home.todayProcess': 'Progress of today',
  'home.shopTheme': 'Customize Your Store',
  'home.kycInfo.limitForMonry': 'There is limit for money collection as you have not set OPay account.',
  'home.addProduct': 'Add product',
  'home.checkShopping': 'View shipping fee',
  'home.dowloadPandovaApp': 'DOWNLOAD PANDOVA APP!',
  'home.goSettingUpdatePayment': 'You can go to Settings to modify the payment settings.',
  'home.customTheme': 'Customize Your Store',
  'home.shopInitSteps': '4 steps left to complete the initialization settings of your store',
  'home.todayOrderNumber': 'Orders',
  'home.paymentMethod': 'Payment',
  'home.fillToAddressAndCurrency': 'Make your store more trustworthy by adding an address, WhatsApp number, and more information.',
  'home.someStepsForInit': 'steps left to complete the initialization settings of your store.',
  'home.kycInfo.expirationFrozen': 'Account will be frozen after expiration',
  'home.completeStoreOperationText': 'Store Information',
  'home.todayUserVisit': 'Visitors',
  'home.kycInfo.setOpayAccount': 'Please set OPay account as soon as possible to use service.',
  'home.shippingFeeExplanation': 'Choose a shipping method and fee for your customers.',
  'home.goAdd': 'Add now',
  'home.accountSetting': 'Account Settings',
  'home.goDecorate': 'Decorate now',
  'home.settingWarnTips': 'Just a few steps left to activate your store',
  'home.kycInfo.days': 'days',
  'home.moreSettingTip': 'Add additional customization to your store to boost your sales.',
  'home.checkPayment': 'View payment settings',
  'home.shopThemeGuide': 'You can go to Online store page to design a store theme',
}
