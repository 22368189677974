export default {
  'setting.payment.creditCardRateEG': 'Bank card 2.25%+2EGP; Wallet 2.25%+2EGP; ref.code 2%',
  'setting.store.stateAndGovernorate': 'State/Governorate',
  'setting.payment.businessLaterDescription': 'If you want to activate the business type, please activate it as individual first and contact customer service to upgrade to business',
  'setting.store.industry': 'Industry',
  'setting.store.titleDescription': 'View and update your store details',
  'setting.addStaff.sendInvitation': 'Send invitation',
  'setting.tax.pleaseEnterTaxDescription': 'Enter tax description',
  'setting.payment.receiptSumDescription': 'All successful online order amount',
  'setting.payment.agreeUseData': 'I Agree To The Use Of My Data In Line Therewith.',
  'setting.payment.activate': 'Activate',
  'setting.account.phone': 'Phone number',
  'setting.store.city': 'City',
  'setting.store.title': 'Store settings',
  'setting.payment.selectIndustry': 'Select Industry',
  'setting.payment.underReview': 'Under review',
  'setting.add': 'New Tax',
  'setting.logistics.customShipping': 'Customized regional shipping fee',
  'setting.logisticsEdit.description': 'Description',
  'setting.payment.paymentActivated': 'OPay payment has been activated. Start receiving payments now. ',
  'setting.account.titleDescription': 'Languages that customers can use when viewing online stores.',
  'setting.shopSetting.onlineLanguage': 'Online store language',
  'setting.payment.createEnterpriseAccount': 'Create enterprise OPay account',
  'setting.payment.cancel': 'Cancel',
  'setting.domainList.actions': 'Operation',
  'setting.domainList.course': 'View instructions',
  'setting.pleaseEnterMail': 'Please enter freight',
  'setting.logistics.titleDescription': 'Manage how you send products to customers',
  'setting.logistics.addShippingForOther': 'Add shipping fee for other regions',
  'setting.notifications.title': 'Notifications',
  'setting.smsManage.title': 'SMS Records',
  'setting.payment.businessContactInfo': 'Business Contact Information',
  'setting.payment.uploadOwnerAddress': 'Upload Proof Of Owner Address',
  'setting.store.postcode': 'Postcode',
  'setting.notifications.messageSendDate': 'Date',
  'setting.payment.businessLogoLimitDescription': 'You can only upload pictures in jpg / jepg / png / pdf format',
  'setting.selectTextGeneralStaff': 'Staff',
  'setting.payment.bossInfo': 'Business Owner Information',
  'setting.store.streetNumber': 'Address',
  'setting.payment.bankAccountName': 'Bank Account Name',
  'setting.payment.amountToSettleDescription': 'The total amount that is not settled to your bank account after the customers make the payment and the processing fee is deducted successfully.',
  'setting.logistics.openCodTips': 'Cash on Delivery is enabled',
  'setting.store.description': 'Description',
  'setting.account.storeLanguageDescription': 'This is the display language for your store visitors.',
  'setting.tax.editTax': 'Edit',
  'setting.domain.bindDomainQuantityHint': 'Only 3 domains can be bound at most',
  'setting.payment.settlementPeriod': 'Settlement period',
  'setting.payment.bankName': 'Bank Name',
  'setting.payment.businessType': 'What does your business do？',
  'setting.logistics.name': 'Title',
  'setting.logistics.pickInStore': 'Support in-store picking',
  'setting.domainList.mainDomain': 'Primary',
  'setting.account.preferredLanguageDescription': 'This language will be used by default when you log in. It does not affect the language settings for customers who visit your online store.',
  'setting.payment.helpEmail': 'Support Email',
  'setting.selectTextYes': 'Yes',
  'setting.logistics.isShowEarnings': 'Visible at checkout',
  'setting.chargeManage.title': 'Expenses',
  'setting.logistics.shippingFreeDescription': 'Your customers do not need to pay the shipping fee when placing order.',
  'setting.tax.percentage': 'Tax Percentage',
  'setting.account.lastName': 'Last name',
  'setting.selectTextNo': 'No',
  'setting.payment.description': 'In your online store, your customer can use this payment.',
  'setting.payment.selectSettlementType': 'Select Settlement Type',
  'setting.payment.account': 'Account Number',
  'setting.staff.mail': 'Email',
  'setting.pleaseEnterLastName': 'Enter last name',
  'setting.payment.ImprovingInfoAddLimit': 'no settlement will be processed until account setup is completed.',
  'setting.logistics.regionName': 'Region name',
  'setting.domain.requiredValue': 'required value',
  'setting.logistics.reserveOrder': 'Reserve the order',
  'setting.staff.addStaff': 'Add Staff',
  'setting.payment.selectBank': 'Select Bank Name',
  'setting.payment.todayReceipt': 'Today’s received payment',
  'setting.payment.socialMedia': 'Business on Social Media',
  'setting.payment.uploadSignId': 'Upload Signatory ID (Business Owner)',
  'setting.payment.businessDescription': 'Description of business services',
  'setting.pleaseEnterCarriage': 'Please enter freight',
  'setting.payment.openFail': 'Failed to open!',
  'setting.charge.title': 'Expense Details',
  'setting.account.resetPassword': 'Reset login password',
  'setting.logistics.customShippingDescription': 'You need to configure shipping fees for difference regions in the country. Your customers can not place order if the shipping fee for their region has not been configured. ',
  'setting.store.cancel': 'Cancel',
  'setting.logistics.stopCodTips': 'Cash on Delivery is disabled',
  'setting.chargeManage.titleDescription': 'Record various expenses of the store.',
  'setting.payment.dailyReceiptLimit': 'Daily cap for receiving payment',
  'setting.payment.merchantInfo': 'Merchant Information',
  'setting.logisticsEdit.name': 'Title',
  'setting.pleaseSelectIndustry': 'Select industry',
  'setting.payment.idType': 'ID Type',
  'setting.payment.activeStatus': 'Active',
  'setting.payment.transactionFee': 'The transaction fee ',
  'setting.payment.creditCardRateNg': '1.5% per transaction capped at ₦2000',
  'setting.payment.untilSettlementBeforeReview': 'no settlement will be processed until the account review is completed.',
  'setting.store.tagline': 'Store title',
  'setting.payment.upload': 'Upload',
  'setting.tax.pleaseEnterTaxPercentage': 'Enter tax percentage',
  'setting.staff.titleDescription': 'Manage what employees can see or do in your store',
  'setting.payment.enterAccount': 'Enter Account Number',
  'setting.account.storeLanguage': 'Online store language',
  'setting.logistics.modifyText': 'Are you sure to clear shipping fee setting? Customers will not be able to place order. ',
  'setting.payment.activatePayment': 'Activate OPay payment',
  'setting.logistics.shippingType': 'Delivery Methods',
  'setting.logistics.selectAdministrativeRegion': 'Select administrative region',
  'setting.payment.dailySettlementDescription': 'daily settlement will be processed.',
  'setting.account.language': 'Language',
  'setting.store.whatsApp': 'WhatsApp',
  'setting.payment.wallet': 'wallet',
  'setting.domain.list': 'Domain list',
  'setting.tax.name': 'Tax Name',
  'setting.payment.cashier': 'Payment',
  'setting.logistics.price': 'Price',
  'setting.payment.idNumber': 'ID Number',
  'setting.staff.title': 'Staff management',
  'setting.store.storeName': 'Store Name',
  'setting.payment.noOpenDescription': 'One click to create your OPay payment account.',
  'setting.payment.selectSocialMedia': 'Select Social Media',
  'setting.logistics.shippingFlat': 'flat shipping fee',
  'setting.logisticsEdit.price': 'Price',
  'setting.store.storeUrl': 'Store link',
  'setting.payment.checkAccount': 'Check the recipient account ',
  'setting.payment.businessNameEg': 'Business Name In English',
  'setting.makeSureDelete': 'Are you sure you want to delete?',
  'setting.payment.ownerAddress': 'Owner Address',
  'setting.logistics.shippingAmount': 'Shipping fee amount',
  'setting.logistics.courierDelivery': 'Support courier delivery',
  'setting.payment.needHelp': 'Need Help?',
  'setting.shopSetting.onlineLanguageLabel': 'Languages',
  'setting.domain.formDomainPlaceholder': 'Domain e.g. example.com',
  'setting.payment.title': 'Payment',
  'setting.selectTextShopManager': 'Owner',
  'setting.logistics.title': 'Shipping fee',
  'setting.domainList.createDate': 'Date',
  'setting.payment.settlementIntervalDescription': 'The settlement cycle of the funds to your bank account after the customers make the payment successfully',
  'setting.logistics.stopCodSuccessTips': 'Cash on Delivery is disabled',
  'setting.payment.amountToBeSettled': 'Amount to be settled',
  'setting.store.addressDescription': 'This address will appear on your invoice. You can edit the address used to calculate freight in shipment settings. Your primary place of business may affect the apps available in your store.',
  'setting.payment.businessTypeDetail': 'Business type',
  'setting.payment.currency': 'Currency',
  'setting.logistics.free': 'Free',
  'setting.payment.modifyEmailText': 'Change email',
  'setting.payment.address': 'Address',
  'setting.domain.describe': 'Domain in the link of customers visiting the online store',
  'setting.tax.title': 'Tax',
  'setting.title': 'Settings management',
  'setting.payment.and': 'And',
  'setting.payment.selectIdType': 'Select ID Type',
  'setting.store.country': 'Country',
  'setting.logistics.openCodModelTips': 'Once enabled, customers can select COD as their payment option when making the payment',
  'setting.domainList.bindExistingDomain': 'Bind existing domain',
  'setting.payment.website': 'Business website link',
  'setting.logistics.currentUsed': 'Current',
  'setting.pleaseEnterInternationalCode': 'Enter country code',
  'setting.edit': 'Edit',
  'setting.payment.settingPaymentAccountHelp': 'How to set OPay payment account?',
  'setting.staff.search': 'Search',
  'setting.account.password': 'Password',
  'setting.logistics.shippingFlatDescription': 'Delivery service covers the whole countries. Your customer only need to pay the shipping fee you configured',
  'setting.payment.contactInformation': 'Contact Information',
  'setting.payment.birthday': 'Birthday',
  'setting.addStaff.staffDescription': '',
  'setting.staff.creatTime': 'Invitation time',
  'setting.logistics.addFreeShipping': 'Add shipping fee',
  'setting.store.currencyDescription': 'This is the currency for your product sale.',
  'setting.payment.enterBvn': 'Enter BVN',
  'setting.payment.enterPhone': 'Enter Phone Number',
  'setting.addStaff.contactStoreManage': 'Please contact the store administrator',
  'setting.payment.accountInfo': 'Recipient account information',
  'setting.domain.configContent': 'the domain directive content is required,',
  'setting.payment.area': 'Area',
  'setting.logistics.pickUpDescription': 'To make it more convenient for customers to pick the products in your store, please type in the pickup location. This address will be shown when the customer makes the payment',
  'setting.domain.domain': 'Domain',
  'setting.logistics.getShopInfoFail': 'An error occurred while acquiring store information',
  'setting.addStaff.mail': 'Email',
  'setting.domainList.linked': 'Connected',
  'setting.payment.socialMediaAccount': 'Website/Social media account',
  'setting.domain.bind': 'Bind',
  'setting.payment.phone': 'Phone Number',
  'setting.logistics.selectShippingType': 'Select shipping fee type',
  'setting.logistics.pickUpAddress': 'Pickup Location',
  'setting.logistics.openCodTips\t': 'Cash on Delivery is enabled',
  'setting.payment.toOpen': 'Open',
  'setting.chargeGroup.titleDescription': 'Record all kinds of expenses in the store.',
  'setting.payment.enterAccountName': 'Enter Account Name',
  'setting.tax.addTax': 'New Tax',
  'setting.payment.bankInfo': 'Bank Account Information',
  'setting.logistics.editShipping': 'Edit',
  'setting.payment.phoneNumber': 'Phone Number',
  'setting.payment.Individual': 'Individual',
  'setting.payment.selectState': 'Select',
  'setting.store.save': 'Save',
  'setting.account.cancel': 'Cancel',
  'setting.internationalCode': 'Country code',
  'setting.payment.businessLater': 'Business (Will be enabled later)',
  'setting.selectTextRead': 'Read',
  'setting.payment.createPersonalAccount': 'Create personal OPay account',
  'setting.payment.withinHours': 'Within 24 hours',
  'setting.domain.bindFail': 'Binding failed',
  'setting.domainList.united': 'Disconnected',
  'setting.message.loadingAllText': '',
  'setting.store.storeMessageDescription': 'Pandova and your customers will use this information to contact you.',
  'setting.staff.delete': 'Delete',
  'setting.payment.businessLogo': 'Business Logo',
  'setting.domain.domainInputVerify': 'Please enter your correct domain.',
  'setting.payment.enterTradingName': 'Enter Trading Name',
  'setting.logisticsEdit.isShowStore': 'Visible on web checkout',
  'setting.pleaseSelectLanguage': 'Select a language',
  'setting.payment.opaySuccessDescription': 'Your payment account has been approved. You can go to OPay for more information!',
  'setting.tax.compoundTax': 'Compound Tax',
  'setting.pleaseSelectState': 'Select state',
  'setting.payment.LogoLimitDescription': 'Logo size cannot exceed 512KB',
  'setting.payment.titleDescription': 'Enable and manage payment service providers for stores',
  'setting.logistics.shippingName': 'Shipping fee name',
  'setting.store.storeCurrency': 'Store currency',
  'setting.addStaff.staff': 'Staff',
  'setting.logistics.stopCod': 'Cash on Delivery is disabled',
  'setting.logistics.addFlatShipping': 'Add as flat shipping fee',
  'setting.account.details': 'Details',
  'setting.tax.description': 'Description',
  'setting.addStaff.cancel': 'Cancel',
  'setting.delete': 'Delete',
  'setting.deleteSuccess': 'Delete successfully',
  'setting.notifications.messageTitle': 'Content',
  'setting.logistics.specificRegion': 'Specific region',
  'setting.addStaff.urlInvalid': 'The invitation link has expired',
  'setting.payment.signatoryName': 'Signatory Name',
  'setting.logisticsEdit.save': 'Save',
  'setting.payment.providedByOpay': 'The payment service will be provided by OPay',
  'setting.payment.bankAccount': 'Bank Account Number',
  'setting.logistics.addShipping': 'Add',
  'setting.payment.submit': 'Submit',
  'setting.payment.bankSwift': 'Company Bank SWIFT Code',
  'setting.staff.state': 'Status',
  'setting.tax.save': 'Save',
  'setting.payment.unOpenPayment': 'OPay',
  'setting.payment.bankToAccount': 'bank account',
  'setting.domain.promptInfoText': 'You need to log in with your provider account and change the settings before you can connect to your domain. Verify the connection to make sure that your domain is set properly.',
  'setting.store.phone': 'Phone',
  'setting.payment.governorate': 'Governorate',
  'setting.payment.goToOpay': 'Go to OPay',
  'setting.account.title': 'Account Settings',
  'setting.store.storeMessage': 'Store Details',
  'setting.payment.reviewFailed': 'Opay payment account binding approval failed.',
  'setting.tax.pleaseEnterTaxName': 'Enter tax name',
  'setting.payment.settlementCycle': 'Settlement cycle is',
  'setting.account.mail': 'Email',
  'setting.account.firstName': 'First name',
  'setting.logistics.pickUpTitle': 'New order is received',
  'setting.selectTextUnread': 'Unread',
  'setting.store.storeLogo': 'Store Logo',
  'setting.payment.selectBirthday': 'Select Birthday',
  'setting.payment.opened': 'Opened',
  'setting.payment.optional': ' (Optional)',
  'setting.payment.transactionFeeText': 'Transaction fee',
  'setting.payment.creditCardRate': '0',
  'setting.payment.limitNow': 'Your collection limit is now',
  'setting.logistics.addToSpecificRegion': 'Add to specific region',
  'setting.payment.toComplete': 'Fill in KYC information',
  'setting.logistics.closeCodModelTips': 'When an order is cancelled, the product will be returned to the warehouse',
  'setting.payment.willOpenPayment': 'payment will be activated',
  'setting.payment.toOpenMode': 'Open payment mode',
  'setting.account.preferredLanguage': 'Preferred language',
  'setting.payment.openSuccess': 'Congratulations, you can now start selling.',
  'setting.payment.registerEmailTip': 'Your register email is ',
  'setting.payment.termsOfUse': 'Terms Of Use',
  'setting.payment.dailyReceiptLimitDescription': 'Daily cap on all successful online order amount',
  'setting.store.address': 'Store address',
  'setting.payment.completeAccountSetup': 'Complete account setup',
  'setting.shopSetting.onlineLanguageDescription': 'Your customers will use this language when browsing online stores.',
  'setting.staff.activityTime': 'Join time',
  'setting.payment.enterAddress': 'Enter Address',
  'setting.payment.tradingName': 'Trading Name',
  'setting.logistics.settingText': 'Confirm to save settings',
  'setting.detail': 'Details',
  'setting.logistics.stopCodDescription': 'Once COD disabled, customers are not able to select it as payment option',
  'setting.payment.uploadFile': 'Upload Files',
  'setting.payment.failDescription': 'Sorry!!! Your payment account was not approved.',
  'setting.payment.bvn': 'BVN',
  'setting.domainList.status': 'Status',
  'setting.store.selectFile': 'Choose File',
  'setting.payment.cashierConfig': 'Payment configuration',
  'setting.domain.tautologyBind': 'Retry',
  'setting.domainList.delete': 'Delete',
  'setting.payment.businessCategory': 'Business Category',
  'setting.payment.enterWebsite': 'Enter Business website link',
  'setting.payment.receiptSum': 'Accumulated received payment',
  'setting.payment.privacyPolicy': 'Privacy Policy',
  'setting.pleaseEnterTransport': 'Enter the name of transportation type',
  'setting.payment.receipt': 'Payment',
  'setting.payment.haveRead': 'I Confirm That I Have Read OPay',
  'setting.mailFailed': 'Manage the way the store collects taxes',
  'setting.payment.settlementType': 'Settlement Type',
  'setting.payment.selectCity': 'Select',
  'setting.payment.industryInformation': 'Industry Information',
  'setting.pleaseEnterFirstName': 'Enter your name',
  'setting.logistics.shippingFree': 'Free shipping',
  'setting.domainList.settingMainDomain': 'Set as primary domain',
  'setting.payment.accountName': 'Account Name',
  'setting.payment.paymentOpened': 'Opay Checkout payment has been opened.',
  'setting.payment.businessLogoDescription': 'We recommend you use a logo with dimension 100px by 100pxfor best results on the checkout form. Max Size: 512 KB',
  'setting.logistics.shippingMethodsLabel': 'Supported Delivery Methods',
  'setting.payment.creditCardRateText': 'Processing fee',
  'setting.payment.residentialAddress': 'Residential Address',
  'setting.payment.underReviewDescription': 'Settlement will not be process until review is completed.',
  'setting.charge.titleDescription': 'Expense category can classify expenses.',
  'setting.logistics.addCustomShipping': 'Add shipping fee for specific region',
  'setting.payment.otherFile': 'Other (Upload Other Documents) (Optional)',
  'setting.payment.nearestMark': 'Nearest Land mark',
  'setting.operation': 'Operation',
  'setting.account.editPassword': 'Edit password',
  'setting.payment.needSetUpSoon': 'no settlement will be processed until account setup is completed. You need to set up your account as soon as possible, otherwise your account will be frozen after one month of opening.',
  'setting.payment.limit': 'Your current collection limit is ',
  'setting.shipping': 'Shipping',
  'setting.buttonTips': 'Only the store manager has the permission.',
  'setting.payment.industry': 'Industry',
  'setting.payment.chooseFile': 'Choose File To Upload（Image or PDF）',
  'setting.addStaff.lastName': 'Last Name',
  'setting.payment.enterBusiness': 'Enter your business',
  'setting.smsManage.titleDescription': 'SMS records sent to customers',
  'setting.payment.businessNameArabic': 'Business Name In Arabic',
  'setting.logistics.editSpecificRegion': 'Edit specific region',
  'setting.payment.subCategory': 'Sub-Category',
  'setting.account.save': 'Save',
  'setting.payment.todayReceiptDescription': 'All successful online order amount of today',
  'setting.addStaff.firstName': 'First Name',
  'setting.payment.verifyMailTitle': 'Please confirm email',
  'setting.payment.bankBranch': 'Bank Branch',
  'setting.payment.opayKycSuccessDescription': 'Congratulations, your payment account has been approved.',
  'setting.pleaseEnterStoreName': 'Enter store name',
  'setting.staff.name': 'Name',
  'setting.logistics.addSpecificRegion': 'Add specific region',
  'setting.tax.titleDescription': 'Manage the way the store collects taxes',
  'setting.payment.review': 'Under review',
  'setting.payment.cod': 'Cash on Delivery',
  'setting.payment.backEmail': 'Chargeback Email',
  'setting.payment.ImprovingInfoOpenSettlement': 'Click below to complete account setup.',
}
