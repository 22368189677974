export default {
  'activity.landing.descText': 'Quick and Easy Setup - Everything you need to start selling online, from store builder to real time data tracking.',
  'activity.landing.freeSubTitle': 'Create An Online Store in 5 Minutes',
  'activity.landing.featureTitle3': 'Reliable Payment',
  'activity.landing.featureTitle2': 'Dashboard',
  'activity.landing.featureTitle1': 'Customized Storefront',
  'activity.landing.startButtonText2': 'Get My Free Account Now',
  'activity.landing.startButtonText1': 'Start Sell',
  'activity.landing.footerTitle': 'Sell Online with FREE Pandova Store',
  'activity.landing.freeTitle': 'Making Selling Online for Free',
  'activity.landing.featureDesc3': 'Besides OPay online payment, Pandova also provides popular payment methods such as bank cards, credit cards, mobile wallets, etc., so that your customers can pay in their own way, making shopping easy and safe.',
  'activity.landing.featureDesc1': 'Pandova gives merchants the option to select from a variety of templates to create their outlet instantly.',
  'activity.landing.featureDesc2': 'The dashboard is an all-in-one inventory management system that gives merchants an overview of their business. Merchants can manage products, orders, track delivery and lots more!',
}
