export default {
  'product.productEdit.salePrice': '价格',
  'product.productGroupList.downloadExcelText': '下载',
  'product.comment.createCommentUserSurnamePlaceholder': '请输入用户姓氏',
  'product.productEdit.importSKUNotCoverSameProduct': '导入将不会覆盖任何现有产品具有相同产品名称的产品。',
  'product.productEdit.batchEditCostPrice': '编辑成本价',
  'product.productGroupList.operation': '操作',
  'product.productGroupList.saveGroup': '保存分组',
  'product.comment.batchTurnOnOperationSuccess': '评价开启成功',
  'product.comment.productName': '商品名称',
  'product.productEdit.importMultipleTip': '多属性',
  'product.comment.reviewShow': '公开评价',
  'product.comment.detailsCommentBatchPublickOperationTipsDescription': '评论公开后，您的客户就可以在您的网站看到评论',
  'product.productGroupList.addGroup': '新建分组',
  'product.productEdit.sku': 'SKU（商品库存编码）',
  'product.productList.title': '商品列表',
  'product.comment.createCommentUserName': '评价用户名字',
  'product.productEdit.requireNormsName': '请输入规格名称',
  'product.productEdit.pricingTitle': '定价',
  'product.productEdit.selectUnit': '选择单位',
  'product.productEdit.importSuccess': '产品已导入完成',
  'product.comment.batchTurnOnOperationTipsTitle': '确认开启$[X]个商品的评价？',
  'product.comment.detailsCommentSearchScore': '评分',
  'product.comment.detailsBatchOperationShow': '公开',
  'product.productEdit.batchEditOriginalPrice': '编辑原价',
  'product.inventoryModel.setting': '设置',
  'product.productEdit.batchEditStock': '编辑库存',
  'product.comment.createCommentAverage': '评价分数',
  'product.productGroupList.coverText': '同名产品将被覆盖。如果上传的产品是多规格产品，它们将覆盖产品的原始规格。',
  'product.productList.selectNeedTips': '至少选择一项',
  'product.comment.detailsBatchOperationClose': '关闭',
  'product.comment.detailsCommentBatchDeleteOperationSuccess': '删除成功',
  'product.comment.reviewHide': '隐藏评价',
  'product.productEdit.costPrice': '成本价',
  'product.productEdit.normsEditExplain': '最多添加三个商品规格',
  'product.inventoryList.images': '商品图片',
  'product.productEdit.addNormsPlaceHolder': '请输入规格值',
  'product.comment.detailsCommentSearchIsPublic': '评价公开状态',
  'product.productEdit.importLoadingModalClose': '关闭',
  'product.productGroupList.excelTemplate': 'Excel模版',
  'product.productEdit.productNumberTip': '输入商品数量',
  'product.productEdit.images': '产品图片',
  'product.comment.reviewStore': '自建评价',
  'product.productEdit.productNorms': '产品规格',
  'product.productEdit.title': '商品分组列表',
  'product.comment.batchTurnOnOperationTipsDescription': '开启后，将展示此商品相关的评价内容，客户可发表此商品的评价',
  'product.productEdit.originalPriceValidateTip': '原价不可小于价格',
  'product.productEdit.importSuccessTip': '产品已导入完成',
  'product.productEdit.noWeightTip': '请填写商品重量信息',
  'product.productGroupList.editGroup': '编辑分组',
  'product.productEdit.comparelPriceTip': '若要显示折扣价格，请在“原价”中输入产品的原始价格，在“价格”中输入较低的价格。',
  'product.productEdit.publish': '发布产品',
  'product.productList.publish': '上架',
  'product.productEdit.imageSizeTips': '我们建议上传1:1比例的图片',
  'product.comment.createCommentUserNamePlaceholder': '请输入用户名字',
  'product.comment.detailsCommentSearchContent': '搜索评价内容',
  'product.inventoryList.selectNeedTips': '至少选择一项',
  'product.comment.reviewTurnOn': '开启评价',
  'product.productList.operationButton': '操作按钮',
  'product.productGroupList.downloadExcelTemplateExplain': '以查看所需格式的示例',
  'product.productEdit.productDetail': '商品详细',
  'product.comment.reviewTurnOff': '关闭评价',
  'product.comment.detailsCommentBatchHideOperationTipsTitle': '确定将选中的$[X]条评论隐藏吗',
  'product.comment.reviewEnable': '启用评价',
  'product.comment.createCommentCreateTimePlaceholder': '请选择时间',
  'product.comment.detailsCommentIsPublic': '是否公开',
  'product.comment.detailsCommentSource': '评价来源',
  'product.inventoryModel.add': '添加',
  'product.inventoryList.productName': '商品名称',
  'product.inventoryList.stockCount': '库存数量',
  'product.productEdit.multipleProductGroupName': '商品分组名称重复',
  'product.comment.detailsCommentBatchHideOperationTipsDescription': '评论隐藏后，您的客户就看不到被隐藏的评价',
  'product.productEdit.deleteNormsTitle': '删除该规格/规格值',
  'product.productEdit.normsValue': '规格值',
  'product.comment.batchTurnOffOperationTipsTitle': '确认关闭$[X]个商品的评价？',
  'product.inventoryModel.selected': '已选中的',
  'product.productGroupList.import': '导入商品',
  'product.productGroupList.addNewGroup': '添加新分组',
  'product.productList.productStatus': '商品状态',
  'product.productList.search': '查询',
  'product.comment.helpCenter': '需要帮助？',
  'product.inventoryModel.state': '个款式，将被置成以下状态',
  'product.inventoryList.product': '商品',
  'product.productEdit.templateOfCSV': 'CSV模版',
  'product.comment.createCommentProductName': '商品名称',
  'product.common.allProducts': '全部商品',
  'product.productEdit.helpImportText': '需要帮助导入商品？',
  'product.comment.createCommentPictureNumberMax': '最多可添加9张图片',
  'product.productGroupList.importLocalFile': '本地文件导入',
  'product.productEdit.applyToAllTip': '将值应用于所有多属性',
  'product.productEdit.normsInfo': '产品规格信息',
  'product.productList.stockNoTrack': '未追踪',
  'product.comment.selectionItems': '已选择$[X]项',
  'product.productEdit.importSKUCoverSameProduct': '导入将会覆盖任何现有产品具有相同产品名称的产品。',
  'product.comment.createForStore': '自建',
  'product.productEdit.importNoCover': '导入将不会覆盖现有相同的产品。',
  'product.productEdit.importPreviewTip': '您正将一个文件导入 Pandova。如果此预览有问题，您可以取消导入，然后修改文件后重新导入。',
  'product.productEdit.addPictureByURL': '从URL添加图片',
  'product.productEdit.preview': '预览',
  'product.productEdit.importConentSuccess': '您已成功导入  goodsNum 产品到Pandova，共有 skuNum SKU，imgNum 张图片。',
  'product.inventoryModel.keepSale': '缺货后继续销售',
  'product.inventoryModel.strategy': '批量修改库存策略',
  'product.productEdit.importHelp': '需要帮助？',
  'product.productGroupList.selectFile': '选择文件',
  'product.comment.createCommentUserSurname': '评价用户姓氏',
  'product.comment.detailsCommentContent': '评价内容',
  'product.comment.detailsCommentBatchDeleteOperationTipsDescription': '删除后将不能找回，且您的网站也不会展示删除的评论，请谨慎操作',
  'product.productEdit.pastePictureUrl': '粘贴图片URL',
  'product.comment.createForCustomer': '客户',
  'product.productGroupList.dragFile': '或者拖拽文件',
  'product.productEdit.normsName': '规格名称',
  'product.comment.batchTurnOffConfirm': '关闭',
  'product.productList.status': '商品状态',
  'product.comment.batchTurnOffOperationSuccess': '评价关闭成功',
  'product.productGroupList.selectOther': '选择其他文件',
  'product.comment.batchTurnOnCancel': '取消',
  'product.productEdit.learnMoreImport': '了解详情',
  'product.productList.stockAmount': '商品库存',
  'product.comment.createComment': '新建评价',
  'product.comment.batchTurnOnConfirm': '开启',
  'product.productEdit.importLoadingModalTitle': '产品导入正在进行中',
  'product.productEdit.importInfoTip': '您将导入大约',
  'product.productList.batchProductPublish': '批量上架',
  'product.productList.filter': '过滤器',
  'product.productEdit.batchEditRecover': '恢复',
  'product.productEdit.draftOrPublish': '已发布/草稿',
  'product.productGroupList.requireGroupName': '请输入分组名称',
  'product.comment.detailsEdit': '编辑',
  'product.productEdit.uploadAndGoOn': '上传并继续',
  'product.comment.detailsCommentUser': '评价者',
  'product.comment.createContentOptional': '输入内容（可选）',
  'product.productEdit.multipleNorms': '多规格',
  'product.comment.createCommentScorePlaceholder': '请评分',
  'product.productList.batchProductUnPublish': '批量下架',
  'product.comment.detailsCommentBatchPublicOperationSuccess': '评论公开成功',
  'product.productEdit.selectSkuImage': '选择商品图片',
  'product.productGroupList.importExcel': '从Excel导入商品',
  'product.productList.addProduct': '添加商品',
  'product.comment.createPictureOptional': '评价图片（可选）',
  'product.productEdit.importSKUSummaryImages': '张图片。',
  'product.inventoryList.title': '库存管理',
  'product.productGroupList.importSuccess': '条商品导入成功',
  'product.productEdit.batchEditWeight': '编辑重量',
  'product.inventoryList.updateStrategy': '批量更新库存策略',
  'product.productEdit.importSame': '覆盖相同产品，现有的值将用于任何缺少的列。',
  'product.productEdit.batchEditPrice': '编辑价格',
  'product.productEdit.importProductSummaryTip': '个产品，共有',
  'product.productEdit.trueProduct': '实物商品',
  'product.inventoryList.sku': 'SKU',
  'product.productEdit.virtualProduct': '虚拟商品',
  'product.productEdit.saveProduct': '保存产品',
  'product.productEdit.salePriceValidateTip': '价格不可大于原价',
  'product.productEdit.weight': '重量',
  'product.comment.detailsCommentBatchPublickOperationTipsTitle': '确定将选中的$[X]条评论公开吗',
  'product.productEdit.productName': '商品名称',
  'product.productEdit.applyToAll': '全部应用',
  'product.productEdit.importImageSumaryTip': '张图片。导入将不会覆盖任何现有产品具有相同产品名称的产品。',
  'product.comment.detailsCommentTotal': '共$[X]条评价数',
  'product.productEdit.importSKUSummaryTip': 'SKU 和',
  'product.productList.deleteText': '删除',
  'product.comment.detailsCommentBatchHideOperationSuccess': '评论隐藏成功',
  'product.productList.name': '商品名称',
  'product.productGroupList.adminRun': '后台运行',
  'product.productEdit.requireProductName': '请输入商品名称',
  'product.productEdit.productGroup': '商品分组',
  'product.productEdit.shortDescription': '商品简介',
  'product.productGroupList.close': '关闭',
  'product.productList.productName': '商品名称',
  'product.inventoryList.updateStock': '批量更新库存数量',
  'product.comment.createCommentCreateTime': '评价时间',
  'product.productGroupList.groupName': '分组名称',
  'product.productList.unpublish': '下架',
  'product.productEdit.importProductByCSV': '通过CSV导入商品',
  'product.inventoryModel.tip': '个多属性将更改',
  'product.comment.detailsAllComment': '全部评价',
  'product.productEdit.addNormsRepeatTips': '规格中已存在',
  'product.productGroupList.importFail': '条商品导入失败',
  'product.comment.editComment': '编辑评价',
  'product.productEdit.imporCover': '导入将会覆盖现有相同的产品。',
  'product.productEdit.overStockSelling': '缺货继续销售',
  'product.productList.importProduct': '导入商品',
  'product.inventoryModel.stopSale': '缺货后停止销售',
  'product.comment.batchTurnOffOperationTipsDescription': '关闭后，将不展示此商品相关的评价内容，客户不可发表此商品的评价',
  'product.productEdit.editProduct': '编辑商品',
  'product.productEdit.stockCount': '库存数量',
  'product.productEdit.editNormsDescription': '此产品有多个选项,例如不同的尺寸或颜色',
  'product.comment.reviewCustomer': '客户评价',
  'product.inventoryList.strategy': '库存策略',
  'product.productEdit.importConentFail': '以下信息未导入成功，您可以在产品编辑页手动添加。',
  'product.comment.detailsDelete': '删除',
  'product.productEdit.multipleProductName': '商品名称重复',
  'product.productEdit.deleteNormsContent': '删除后，下方的关联SKU信息会被清除，是否删除？',
  'product.productEdit.uploadCSVErrorTip': '导入文件时出错，请在修复错误后再次尝试导入该文件。',
  'product.productEdit.productSkuImage': '商品图片',
  'product.productEdit.originalPrice': '原价',
  'product.productEdit.trackStock': '追踪数量',
  'product.productGroupList.productCount': '产品数量',
  'product.comment.average': '平均分',
  'product.productEdit.addNorms': '添加规格',
  'product.comment.detailsCommentBatchDeleteOperationTipsTitle': '确定将选中的$[X]条评论删除吗？',
  'product.productEdit.productType': '商品类型',
  'product.productList.productGroup': '商品分组',
  'product.comment.createCommentIsPublic': '是否公开',
  'product.comment.batchTurnOffCancel': '取消',
  'product.productEdit.productPublishStatus': '产品状态',
  'product.comment.createCommentPageTitle': '自建评价',
  'product.comment.detailsBatchOperationHide': '隐藏',
  'product.productEdit.importLoadingModalContent': '完成此过程可能需要一段时间。您可以关闭此对话框然后继续操作其它功能，不会影响产品导入。',
  'product.productEdit.editText': '编辑',
  'product.inventoryList.stock': '库存数量',
}
