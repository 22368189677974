export default {
  'charge.chargeList.descriptionOfFees': '费用说明',
  'charge.chargeList.addGroup': '添加分组',
  'charge.chargeList.inputAmount': '输入金额',
  'charge.chargeList.editGroup': '编辑分组',
  'charge.chargeList.title': '费用列表',
  'charge.chargeList.groupDescription': '分组描述',
  'charge.chargeList.groupTitle': '分组列表',
  'charge.chargeList.date': '日期',
  'charge.chargeList.require': '不能为空',
  'charge.chargeList.amount': '金额',
  'charge.chargeList.addTitle': '添加费用',
  'charge.chargeList.selectType': '请选择类目',
  'charge.chargeList.delete': '删除',
  'charge.deleteSuccess': '删除成功',
  'charge.typeName': '类目名称',
  'charge.chargeList.edit': '编辑',
  'charge.chargeList.groupName': '分组名称',
  'charge.chargeGroup.title': '费用分组',
  'charge.makeSureDelete': '确认要删除吗？',
  'charge.chargeList.editTitle': '编辑费用',
  'charge.chargeList.selectDate': '选择时间',
  'charge.chargeList.operation': '操作',
}
