export default {
  'sms.smsCount.type': 'النوع',
  'sms.smsPurchaseList.rechargeUser': 'اسم المشتري',
  'sms.smsPurchaseList.item': 'وحدة',
  'sms.smsPurchaseList.smsPurchaseTitle': 'سعر الرسالة',
  'sms.smsCount.content': 'محتوى الرسالة',
  'sms.smsCount.scene': 'نوع الرسائل',
  'sms.smsPurchaseList.rechargeState': 'الحالة',
  'sms.smsPurchaseList.used': 'مستعملة',
  'sms.smsCount.sendNumber': 'عدد الرسائل المرسلة',
  'sms.animation': 'تلقائي',
  'sms.commandShift': 'يدوي',
  'sms.smsPurchaseList.rechargeTime': 'وقت الشراء',
  'sms.smsPurchaseList.smsPurchaseSMS': 'الرسالة',
  'sms.smsCount.from': 'المصدر',
  'sms.smsPurchaseList.rechargeAmount': 'مبلغ الشراء',
  'sms.smsCount.recharge': 'شراء الرسائل',
  'sms.smsPurchaseList.smsResidual': 'متبقي',
  'sms.smsPurchaseList.rechargeNumber': 'كمية الشراء',
  'sms.smsPurchaseList.recharge': 'شراء رسائل',
  'sms.smsCount.sendDate': 'وقت الإرسال',
  'sms.smsPurchaseList.title': 'سجل شراء الرسائل',
  'sms.smsCount.title': 'سجل الرسائل',
  'sms.smsPurchaseList.residual': 'الباقي',
}
