export default {
  'common.clearText': 'فارغ',
  'common.NeedHelp': 'هل تحتاج مساعدة؟',
  'common.adminMenu.reviews': 'التعليقات /التقييمات',
  'common.operationText': 'العمليات',
  'common.adminMenu.taxSetting': 'ضرائب',
  'common.adminMenu.chargeGroupList': 'فئات المصاريف',
  'common.adminMenu.productGroup': 'المجموعات',
  'common.resetText': 'إعادة عيين',
  'common.minValue': 'الادني',
  'common.adminMenu.staff': 'طاقم العمل',
  'common.adminMenu.smsRecords': 'سجل الرسائل النصية',
  'common.adminMenu.dataReview': 'الإحصائيات',
  'common.totalAmount': 'الكل',
  'common.adminMenu.analytics': 'التحليلات',
  'common.adminMenu.rechargeRecords': 'سجل شراء الرسائل النصية',
  'common.adminMenu.paymentSetting': 'اعدادات المدفوعات',
  'common.moreFilters': ' المزيد من الفلاتر',
  'common.validateNumber': 'الرجاء إدخال رقم',
  'common.adminMenu.chargeList': 'كل المصاريف',
  'common.batchOperation': 'كمية العمليات المطلوبة',
  'common.deleteText': 'حذف',
  'common.lessThan': 'اقل من ',
  'common.remove': 'حذف',
  'common.adminMenu.shopTheme': 'تصميم المتجر \'التصميم\'',
  'common.menu.product': 'منتج',
  'common.finishText': 'إتمام',
  'common.adminMenu.smsSetting': 'الرسائل النصية',
  'common.adminMenu.product': 'المنتجات',
  'common.oneScore': 'نقطة واحدة',
  'common.back': 'رجوع',
  'common.twoScore': 'نقطتين',
  'common.saveText': 'حفظ',
  'common.adminMenu.customerGroup': 'مجموعات العملاء',
  'common.cancelText': 'الغاء',
  'common.confirm': 'موافق',
  'common.uploadLimitSize': 'يجب ألا يزيد حجم الصور عن 10 ميغا بايت',
  'common.adminMenu.onlineShopPage': 'صفحة',
  'common.adminMenu.message': 'الرسائل',
  'common.menu.customPage': 'صفحة مخصصة',
  'common.adminMenu.shippingFee': 'تكاليف الشحن',
  'common.adminMenu.accountSetting': 'إعدادات الحساب',
  'common.threeScore': '3 نقاط',
  'common.adminMenu.domain': 'نطاق الموقع (الدومين)',
  'common.networkError': 'انقطع الاتصال بالشبكة. يرجى التحقق من الشبكة',
  'common.fiveScore': '5 نقاط',
  'common.confirmBtnText': 'نعم',
  'common.greaterThan': 'اكبر من',
  'common.adminMenu.onlineShop': 'المتجر الإلكتروني',
  'common.formValidate.codeText': 'حروف',
  'common.adminMenu.charge': 'المصاريف',
  'common.menu.collection': 'مجموعة',
  'common.memoText': 'ملاحظة',
  'common.openOption': 'تفعيل',
  'common.adminMenu.customerList': 'قائمة العملاء',
  'common.uploadText': 'تحميل',
  'common.adminMenu.setting': 'الإعدادات',
  'common.operateFail': 'فشل',
  'common.formValidate.errorInfoText': 'تم ملء البيانات بشكل غير صحيح. يرجى التحقق من المحتوى.',
  'common.adminMenu.customerAnalytics': 'تحليلات العملاء',
  'common.formValidate.maxLengthTip': 'المحتوى طويل جدا أن يكون أكثر من',
  'common.selectTip': 'يختار',
  'common.adminMenu.home': 'الرئيسية',
  'common.networkTimeout': 'الاتصال بالإنترنت غير مستقر ،يرجى إعادة تحميل الصفحة والمحاولة مرة أخرى في وقت لاحق.',
  'common.adminMenu.shopInfo': 'إعدادات المتجر',
  'common.discountCodeLengthTip': 'الرجاء إدخال رمز خصم 3-50 حرفًا.',
  'common.adminMenu.onlineShopNav': 'خريطة الموقع',
  'common.adminMenu.discount': 'خصومات',
  'common.adminMenu.feedback': 'آراء و اقتراحات',
  'common.menu.home': 'صفحة رئيسية',
  'common.closeOption': 'غلق',
  'common.maxValue': 'الاعلي',
  'common.requireNormsTip': 'الرجاء تحديد اختيار',
  'common.adminMenu.inventoryList': 'قائمة المخزون',
  'common.uploadLimitFormat': 'قم بتحميل الصور بتنسيق jpg / png فقط',
  'common.adminMenu.order': 'الطلبات',
  'common.fourScore': '4 نقاط',
  'common.adminMenu.productList': 'كل المنتجات',
  'common.adminMenu.customer': 'العملاء',
  'common.editText': 'تعديل',
  'common.serverErrorTip': 'الخدمة غير متوفرة، يرجى إعادة المحاولة في وقت لاحق',
  'common.menu.search': 'بحث',
  'common.requireNormsInfoTips': ' يرجى تحسين مواصفات المعلومات ',
}
