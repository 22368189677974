export default {
  'activity.landing.descText': '快速简便的设置——从商店建设者到实时数据跟踪，一切你需要开始在线销售的东西。',
  'activity.landing.freeSubTitle': '5分钟内创建一个在线商店',
  'activity.landing.featureTitle3': '可靠的支付',
  'activity.landing.featureTitle2': '仪表板',
  'activity.landing.featureTitle1': '定制店面',
  'activity.landing.startButtonText2': '现在就拿到我的免费账户',
  'activity.landing.startButtonText1': '开始销售',
  'activity.landing.footerTitle': '通过免费Pandova商店在线销售',
  'activity.landing.freeTitle': '免费在线销售',
  'activity.landing.featureDesc3': '除了OPay在线支付，Pandova还提供流行的支付方式，如银行卡、信用卡、手机钱包等，让您的客户可以用自己的方式支付，让购物变得轻松安全。',
  'activity.landing.featureDesc1': 'Pandova为商家提供了从各种模板中进行选择的选项，以立即创建他们的门店。',
  'activity.landing.featureDesc2': '仪表板是一个集多功能于一体的库存管理系统，可为商家提供业务概览。商家可以管理产品、订单、跟踪配送等！',
}
