export default {
  'customer.pleaseSelectScope': '请选择范围',
  'customer.deleteCustomer.sureToDelete': '确认要删除？',
  'customer.addCustomer.addAddress': '添加地址',
  'customer.customerDetail.remark': '备注',
  'customer.addCustomer.state': '洲/省',
  'customer.createMessage.sendMessage': '发送消息',
  'customer.addCustomer.name': '姓名',
  'customer.addCustomer.internationalCode': '区号',
  'customer.pleaseEnterAddress': '请输入地址',
  'customer.createMessage.mailContent': '邮件内容',
  'customer.messageList.messageTitle': '消息标题',
  'customer.createMessage.selectOrderJustONe': '最近30天内只有一个订单的顾客',
  'customer.addCustomer.deleteAddress': '删除地址',
  'customer.selectTextCustomer': '客户',
  'customer.addCustomerGroup.group': '分组',
  'customer.createMessage.smsContent': '短信内容',
  'customer.customerDetail.lastLanding': '上次登录时间',
  'customer.messageDetail.sendState': '发送状态',
  'customer.messageDetail.successCustomer': '发送成功的顾客数量',
  'customer.createMessage.selectAllCustomer': '选择所有顾客',
  'customer.operation': '操作',
  'customer.pleaseEnterMail': '请输入邮箱',
  'customer.addCustomer.city': '城市',
  'customer.shippingAddress': '配送地址',
  'customer.billingAddress': '账单地址',
  'customer.noDataTips': '暂无数据',
  'customer.selectTextEmail': 'EMAIL',
  'customer.createMessage.selectSubscriptionMail': '订阅邮件的顾客',
  'customer.deleteCustomer.cancel': '取消',
  'customer.selectTextSuccess': '成功',
  'customer.selectTextCustomerGroup': '客户分组',
  'customer.addCustomer.lastName': '姓',
  'customer.addCustomerGroup.selectAll': '选择所有顾客',
  'customer.customerDetail.orderAmount': '订单总额',
  'customer.customerDetail.group': '分组',
  'customer.messageDetail.activityName': '活动名称',
  'customer.addCustomer.setDefaultAddress': '设为默认地址',
  'customer.placeSelectCountry': '请选择国家',
  'customer.messageDetail.messageContent': '消息内容',
  'customer.addCustomerGroup': '添加分组',
  'customer.pleaseEnterLastName': '请输入姓氏',
  'customer.createMessage.selectCustomerAndGroup': '选择顾客/分组',
  'customer.addCustomer.setBillingAddress': '设为账单地址',
  'customer.customerDetail.howLongAgo': '多久以前',
  'customer.addCustomerGroup.selectCustomer': '选择顾客',
  'customer.customerGroupList': '顾客分组列表',
  'customer.customerDetail.shippingAddress': '收货地址',
  'customer.delete': '删除',
  'customer.customerDetail.viewOrderDetail': '查看订单详情',
  'customer.addCustomer.country': '国家',
  'customer.addCustomer.streetAddress': '街道地址',
  'customer.selectGroup': '选择分组',
  'customer.addCustomer.mail': '邮箱',
  'customer.createMessage.emailNote': '注意：每天只能免费发送100封邮件。',
  'customer.customerDetail.recentOrders': '最近订单',
  'customer.pleaseEnterCity': '请输入城市',
  'customer.deleteCustomer.unBack': '不能回退',
  'customer.addCustomer.remark': '备注',
  'customer.addMessage': '添加营销消息',
  'customer.addCustomer.billingAddress': '账单地址',
  'customer.customerDetail.orderList': '订单列表',
  'customer.messageDetail.sendChannel': '消息发送渠道',
  'customer.addCustomer.zipCode': '邮编（可选）',
  'customer.selectTextAll': '全部',
  'customer.customerList.name': '姓名',
  'customer.customerList.mailSelect': '邮件订阅筛选',
  'customer.addCustomer.shippingPhone': '收货电话',
  'customer.addCustomer.phone': '电话',
  'customer.subscribed': '已订阅',
  'customer.createMessage.activityName': '活动名称',
  'customer.addCustomer.cancel': '取消',
  'customer.createMessage.selectTopCustomer': '近30天消费金额Top 10%的顾客',
  'customer.addCustomer.defaultAddress': '默认地址',
  'customer.messageDetail.messageTitle': '消息标题',
  'customer.messageDetail.failCustomer': '发送失败的顾客数量',
  'customer.addCustomerGroup.add': '添加',
  'customer.customerDetail.orderNumber': '订单号',
  'customer.addCustomerGroup.customerGroupName': '顾客分组名称',
  'customer.customerDetail': '顾客详情',
  'customer.createMessage.mailName': '邮件名称',
  'customer.detail': '详情',
  'customer.addCustomer.save': '保存',
  'customer.customerList': '顾客列表',
  'customer.customerList.email': '邮箱',
  'customer.pleaseEnterFirstName': '请输入名字',
  'customer.editCustomer': '编辑顾客',
  'customer.addCustomer': '添加顾客',
  'customer.customerDetail.orderTotalSpend': '总订单金额',
  'customer.addCustomerGroup.unGroup': '未分组',
  'customer.deleteCustomer.confirmDelete': '确认删除',
  'customer.messageList.activityName': '活动名称',
  'customer.selectTextSms': 'SMS',
  'customer.pleaseEnterInternationalCode': '请输入国际区号',
  'customer.customerList.phone': '电话',
  'customer.editCustomerGroup': '编辑分组',
  'customer.addCustomer.viewAllGroup': '查看所有分组',
  'customer.customerGroupList.customerCount': '顾客数量',
  'customer.customerList.addCustomer': '添加顾客',
  'customer.noAddressTips': '暂无地址，请点击“新建地址”添加',
  'customer.customerDetail.phone': '电话',
  'customer.customerDetail.mail': '邮箱',
  'customer.customerDetail.billingAddress': '账单地址',
  'customer.placeSelectStateAndGovernorate': '请选择州/省',
  'customer.customerList.searchName': '搜索顾客',
  'customer.messageDetail': '消息详情',
  'customer.createMessage.title': '消息标题',
  'customer.addCustomer.basicInformation': '基本信息',
  'customer.messageList.sendState': '发送状态',
  'customer.addCustomer.firstName': '名',
  'customer.addCustomerGroup.search': '搜索',
  'customer.messageList.sendChannel': '消息发送渠道',
  'customer.customerGroupList.groupName': '分组名称',
  'customer.pleaseSelectMessageType': '请选择发送渠道',
  'customer.addCustomer.shippingAddress': '收货地址',
  'customer.addCustomerGroup.save': '保存',
  'customer.createMessage.selectNoOrder': '没有订单的顾客',
  'customer.messageList': '营销消息列表',
  'customer.customerDetail.orderCount': '订单数量',
  'customer.createMessage.content': '消息内容',
  'customer.makeSureDelete': '确认要删除吗？',
  'customer.addCustomer.handle': '处理人',
  'customer.createMessage.sendChannel': '消息发送渠道',
  'customer.addCustomer.pleaseEnterGroupName': '请输入分组名称',
  'customer.editAddress': '编辑地址',
  'customer.pleaseEnterMailContent': '请输入邮件内容',
  'customer.addCustomerGroup.customerList': '顾客列表',
  'customer.selectTextFail': '失败',
  'customer.addCustomer.group': '分组',
  'customer.selectTextBeSending': '发送中',
  'customer.notSubscribed': '未订阅',
  'customer.customerList.lastVisit': '上次访问时间',
  'customer.createMessage.customerScopeType': '用户范围类型',
  'customer.deleteSuccess': '删除成功',
  'customer.pleaseEnterHandle': '请输入处理人',
  'customer.pleaseEnterMessageName': '请输入活动名称',
  'customer.createMessage.smsName': '短信名称',
  'customer.edit': '编辑',
  'customer.pleaseEnterPhone': '请输入电话',
  'customer.addCustomerGroup.cancel': '取消',
  'customer.addCustomer.address': '地址',
}
