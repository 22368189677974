export default {
  'portal.commonFooter.aboutAddress': '地址：',
  'portal.price.team': '团队',
  'portal.price.discount': '折扣',
  'portal.home.contactWaySubmitted': '已提交',
  'portal.referral.copyrightInformation': '© 本活动的最终解释权归Pandova所有',
  'portal.commonFooter.aboutServiceTerms': '服务条款',
  'portal.price.rateOpayOnlinePay': 'OPay在线支付',
  'portal.home.contactWayPlaceholder': '邮箱或手机号',
  'portal.price.storeThemes': '店铺主题',
  'portal.home.faqTitle1': 'Pandova适合我的业务吗？',
  'portal.home.faqTitle2': '我不懂代码可以创建在线商店吗？',
  'portal.home.faqTitle5': '有交易手续费吗？',
  'portal.home.faqTitle3': '我的在线商店会适配移动端吗？',
  'portal.home.faqTitle4': '我可以免费使用多久？',
  'portal.price.expenseManagement': '费用管理',
  'portal.referral.errorMessage': '系统繁忙，请稍后重试',
  'portal.commonFooter.aboutPrivacyPolicy': '隐私政策',
  'portal.price.explainText': '我们为您设计了一个免费的专业计划，它包含了您销售更多产品所需的一切。',
  'portal.price.onlineStore': '网店',
  'portal.helpCenter.PandovaHelpCenter': 'Pandova帮助中心',
  'portal.home.onlinePay': '快速安全地获得付款',
  'portal.home.sloganMinutes': '5分钟',
  'portal.home.examinePrice': '定价计划',
  'portal.home.contactWayExplainInfoText': '留下您的联系方式，我们会尽快联系。',
  'portal.blog.SeeMore': '查看更多',
  'portal.home.contactWaySubmitText': '提交',
  'portal.commonFooter.productAndServicePandova': 'Pandova',
  'portal.home.sloganFree': '免费',
  'portal.price.customDomain': '自定义域名',
  'portal.price.freeSSL': '免费 SSL 证书',
  'portal.home.onlinePayInfoText1': '只需几分钟即可申请开通 OPay 在线支付，在线接收客户的付款。 目前支持银行卡、信用卡、手机钱包等主流的支付方式，让您的客户用自己的方式支付，让购物更简单，提高转化率。',
  'portal.price.free': '免费',
  'portal.price.leadingFeature': '主要特征',
  'portal.onlineCustomer.guideText': '您可以提问，或者分享您的反馈。',
  'portal.price.shippingIntegration': '物流集成',
  'portal.price.analytics': '分析',
  'portal.home.freeStartGuidanceText': '免费开始在线销售',
  'portal.referral.helpContent3': '3、告诉你的朋友他们可以通过你的链接注册并获得奖励',
  'portal.referral.helpContent2': '2、将您的链接分享到社交媒体或直接分享给您的朋友',
  'portal.referral.helpContent1': '1、复制邀请链接',
  'portal.commonHeader.price': '价格',
  'portal.price.unlimitedProducts': '无限产品',
  'portal.price.manuallyOrder': '手工创建订单',
  'portal.commonHeader.helpCenter': '帮助中心',
  'portal.price.customerGroup': '顾客分组',
  'portal.home.contactWaySubmitFormatErrorMessage': '只支持邮箱格式或50位以内的数字',
  'portal.commonHeader.freeStart': '免费开始',
  'portal.home.featureIntroduceDataAnalyseExplainText': '数据分析可以让你全面详细地了解你的业务，以更好地了解销售、订单和受众，从而更好地优化你的产品和营销策略。',
  'portal.commonFooter.aboutPhone': '电话：',
  'portal.price.month': '/月',
  'portal.blog.latestRecommended': '最新推荐',
  'portal.referral.noLoginText': '请先登录',
  'portal.price.support': '支持',
  'portal.referral.copyLink': '复制链接',
  'portal.onlineCustomer.feedbackBack': '返回',
  'portal.home.faq': 'FAQ',
  'portal.commonFooter.productAndServiceHelpCenter': '帮助中心',
  'portal.onlineCustomer.helpCenterEntrance': '帮助中心',
  'portal.price.customerManagement': '顾客管理',
  'portal.commonFooter.about': '关于我们',
  'portal.down.publicityText': '随时随地在手机上销售和管理',
  'portal.onlineCustomer.welcomeText': '欢迎',
  'portal.price.onlinePay': '在线支付集成',
  'portal.home.sloganCreateWebsite1': '创建你的网店',
  'portal.commonFooter.productAndService': '产品和服务',
  'portal.home.featureIntroduceDataAnalyse': '数据分析优化经营',
  'portal.price.unlimitedOrders': '无限订单',
  'portal.blog.ReadMinutes': '分钟阅读',
  'portal.referral.registerTime': '注册日期',
  'portal.price.marketing': '营销',
  'portal.down.publicityTextDesc': '下载APP，可以在任何地方用手机管理你的店铺，添加商品、分享到社交媒体、与客户沟通等，永远不错过任何一笔订单。',
  'portal.referral.copyLinkSuccess': '链接已复制',
  'portal.price.staffAccount': '员工账号',
  'portal.price.googleAnalytics': '集成谷歌分析',
  'portal.referral.guideText': '分享您的推荐链接给您的朋友',
  'portal.commonFooter.productAndServiceAppDown': 'APP下载',
  'portal.onlineCustomer.feedbackFormFeedbackContent': '反馈内容',
  'portal.commonFooter.aboutAddressContent': '13G Ahmed Kamel St., Al lasilki St., New Maadi, MaadiCairo, Egypt',
  'portal.price.smsMarketing': '短信营销',
  'portal.price.facebook': '集成脸书像素',
  'portal.commonHeader.myStore': '我的商店',
  'portal.helpCenter.quickSearchPlaceholder': '快速搜索',
  'portal.price.engineOptimization': '搜索引擎优化',
  'portal.home.contactWaySubmitSuccessOK': '好的',
  'portal.referral.notData': '暂无邀请记录',
  'portal.referral.helpTitle': '如何邀请',
  'portal.onlineCustomer.nigeriaEntrance': '尼日利亚地区咨询',
  'portal.price.plan': '价格计划',
  'portal.price.limitedTimeOffer': '（限时优惠）',
  'portal.blog.categoryAll': '全部',
  'portal.home.sloganFor': '',
  'portal.home.freeExplain': '日常使用所需的一切，完全免费！',
  'portal.onlineCustomer.egyptEntrance': '埃及地区咨询',
  'portal.home.featureIntroduceBusinessTools': '营销工具助力业务增长',
  'portal.home.contactWaySubmitErrorMessage': '请填写信息',
  'portal.home.featureIntroduceBusinessToolsExplainText': '你可以创建营销活动，并将营销推广信息通过Facebook、WhatsApp、Twitter、Instagram、邮件、短信等方式发送给你的客户，吸引他们购买，从而实现你的业务增长。',
  'portal.price.explainText2': '让我们今天开始吧！',
  'portal.commonHeader.login': '登录',
  'portal.home.contactWaySubmitSuccessInfoText': '我们将尽快与您联系。',
  'portal.onlineCustomer.feedbackEntrance': '意见反馈',
  'portal.commonHeader.blog': '博客',
  'portal.commonFooter.aboutContactUs': '联系我们',
  'portal.referral.friendName': '姓名',
  'portal.price.commission': '佣金',
  'portal.home.freeThemeExplainText': '不断更新的免费主题和装饰组件，轻松自定义颜色、图像和字体，无需任何设计技巧，即可打造精彩的网店，吸引客户浏览和购买。',
  'portal.referral.orderNum': '订单数',
  'portal.home.ProductSummary': '在线销售您想卖的一切，易于使用，并通过 Facebook、WhatsApp、Twitter、Instagram 等社交渠道推广吸引更多客户。',
  'portal.onlineCustomer.feedbackFormContact': '联系方式',
  'portal.price.24/7support': '24/7 支持',
  'portal.home.contactWaySubmitSuccessMessage': '您的联系方式',
  'portal.helpCenter.allResultShowText': '显示一下查询的所有结果',
  'portal.commonHeader.home': '特征',
  'portal.price.processingFee': '处理费用',
  'portal.commonHeader.down': '下载',
  'portal.home.knowMore': '了解更多',
  'portal.home.faqAnswer1': 'Pandova 非常适合想要销售商品的商家，您可以创建在线商店，出售您想要的一切，还可以通过社交渠道进行推广以吸引更多客户。',
  'portal.home.faqAnswer2': '绝对可以，使用Pandova 不需要您了解如何编码或设计。您可以在没有技术知识的情况下快速创建您的在线商店并开始销售，整个过程大约需要5分钟。',
  'portal.home.faqAnswer5': 'Pandova目前对商家不收取交易手续费。但是当您的客户在线支付时，支付平台会收取大约1.5%支付费率（具体费率以您开通的支付平台为准）。了解更多信息请访问',
  'portal.home.faqAnswer3': '是的，您的在线商店会附带一个移动端优化版本，也就是说，您的在线商店在电脑和手机上都会呈现出非常友好的界面。',
  'portal.home.faqAnswer4': '目前推出的这个免费版本，包含日常使用的一些基础功能，您可以永久免费使用。了解更多信息请访问',
  'portal.referral.activityRules': '了解更多详情',
  'portal.onlineCustomer.feedbackFormUploadImage': '上传图片',
  'portal.price.emailMarketing': '电子邮件营销',
  'portal.referral.myInviteList': '我的邀请',
  'portal.home.freeTheme': '免费主题定制你的商店',
  'portal.onlineCustomer.feedbackSubmit': '提交',
  'portal.price.reportsAnalytics': '报告和分析',
}
