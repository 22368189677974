import antdEnUS from 'ant-design-vue/es/locale-provider/en_US'
import momentEU from 'moment/locale/eu'
import account from './en-US/account'
import charge from './en-US/charge'
import comment from './en-US/comment'
import common from './en-US/common'
import customer from './en-US/customer'
import discount from './en-US/discount'
import helpCenter from './en-US/help-center'
import home from './en-US/home'
import onlineShop from './en-US/online-shop'
import order from './en-US/order'
import portal from './en-US/portal'
import product from './en-US/product'
import report from './en-US/report'
import setting from './en-US/setting'
import sms from './en-US/sms'
import application from './en-US/application'
import activity from './en-US/activity'

const components = {
  antLocale: antdEnUS,
  momentName: 'eu',
  momentLocale: momentEU,
}

export default {
  message: '-',
  ...components,
  ...account,
  ...charge,
  ...comment,
  ...common,
  ...customer,
  ...discount,
  ...helpCenter,
  ...home,
  ...onlineShop,
  ...order,
  ...portal,
  ...product,
  ...report,
  ...setting,
  ...sms,
  ...application,
  ...activity,
}
