export default {
  'setting.payment.creditCardRateEG': '银行卡 2.25%+2EGP; 手机钱包 2.25%+2EGP; Ref.code 2%',
  'setting.store.stateAndGovernorate': '州/省',
  'setting.payment.businessLaterDescription': '如果您现在需要激活企业类型，请先激活个人类型，然后联系客服升级为企业。',
  'setting.store.industry': '店铺行业',
  'setting.store.titleDescription': '查看并且更新您的商店详细信息',
  'setting.addStaff.sendInvitation': '发送邀请',
  'setting.tax.pleaseEnterTaxDescription': '请输入描述',
  'setting.payment.receiptSumDescription': '所有在线支付成功的订单总额',
  'setting.payment.agreeUseData': '我同意按此使用我的数据。',
  'setting.payment.activate': '激活',
  'setting.account.phone': '手机号',
  'setting.store.city': '城市',
  'setting.store.title': '店铺设置',
  'setting.payment.selectIndustry': '选择行业',
  'setting.payment.underReview': '审核中',
  'setting.add': '添加',
  'setting.logistics.customShipping': '自定义地区运费',
  'setting.logisticsEdit.description': '描述',
  'setting.payment.paymentActivated': 'OPay支付已开通，您可以在线接收付款了。',
  'setting.account.titleDescription': '客户查看在线店铺时可使用的语言',
  'setting.shopSetting.onlineLanguage': '在线店铺语言',
  'setting.payment.createEnterpriseAccount': '创建企业OPay帐户',
  'setting.payment.cancel': '取消',
  'setting.domainList.actions': '操作',
  'setting.domainList.course': '查看教程',
  'setting.pleaseEnterMail': '请输入邮箱',
  'setting.logistics.titleDescription': '管理您向客户发送货物的方式',
  'setting.logistics.addShippingForOther': '为其他地区添加运费',
  'setting.notifications.title': '消息中心',
  'setting.smsManage.title': '短信管理',
  'setting.payment.businessContactInfo': '业务联系信息',
  'setting.payment.uploadOwnerAddress': '上传所有者地址证明',
  'setting.store.postcode': '邮编',
  'setting.notifications.messageSendDate': '推送日期',
  'setting.payment.businessLogoLimitDescription': '只能上传jpg/jepg/png/pdf格式',
  'setting.selectTextGeneralStaff': '员工',
  'setting.payment.bossInfo': '企业主信息',
  'setting.store.streetNumber': '街道+门牌号',
  'setting.payment.bankAccountName': '银行账户名',
  'setting.payment.amountToSettleDescription': '顾客付款成功且扣除交易手续费后，还未结算到您的银行账户的金额总和',
  'setting.logistics.openCodTips': '货到付款已激活',
  'setting.store.description': '店铺描述',
  'setting.account.storeLanguageDescription': '您的客户在浏览在线商店时，将使用此语言。',
  'setting.tax.editTax': '编辑税',
  'setting.domain.bindDomainQuantityHint': '最多只支持3个域名',
  'setting.payment.settlementPeriod': '结算周期',
  'setting.payment.bankName': '银行名称',
  'setting.payment.businessType': '你是做什么生意的？',
  'setting.logistics.name': '名称',
  'setting.logistics.pickInStore': '支持到店自取',
  'setting.domainList.mainDomain': '主域名',
  'setting.account.preferredLanguageDescription': '登录后，这将是您看到的语言。 这不会影响顾客查看在线店铺显示的语言。',
  'setting.payment.helpEmail': '帮助邮箱',
  'setting.selectTextYes': '是',
  'setting.logistics.isShowEarnings': '是否在结账时可见',
  'setting.chargeManage.title': '记账管理',
  'setting.logistics.shippingFreeDescription': '您的顾客在下单时无需支付运费',
  'setting.tax.percentage': '税率',
  'setting.account.lastName': '名',
  'setting.selectTextNo': '否',
  'setting.payment.description': '在您的在线商店中，您的客户可以使用该支付方式付款。',
  'setting.payment.selectSettlementType': '选择结算类型',
  'setting.payment.account': '账号',
  'setting.staff.mail': '邮箱',
  'setting.pleaseEnterLastName': '请输入姓氏',
  'setting.payment.ImprovingInfoAddLimit': '在帐户设置完成之前，不会处理任何结算。',
  'setting.logistics.regionName': '地区名称',
  'setting.domain.requiredValue': '所需值',
  'setting.logistics.reserveOrder': '保留订单',
  'setting.staff.addStaff': '添加员工',
  'setting.payment.selectBank': '选择银行',
  'setting.payment.todayReceipt': '今日已收款',
  'setting.payment.socialMedia': '开店的社交媒体',
  'setting.payment.uploadSignId': '上传签名人ID（企业所有者）',
  'setting.payment.businessDescription': '商业服务说明',
  'setting.pleaseEnterCarriage': '请输入运费',
  'setting.payment.openFail': '开通失败！',
  'setting.charge.title': '费用明细',
  'setting.account.resetPassword': '重置密码',
  'setting.logistics.customShippingDescription': '您需要配置国内分地区的运费，在您配置完成之前，未配置运费所在地区的顾客将无法下单',
  'setting.store.cancel': '取消',
  'setting.logistics.stopCodTips': '货到付款已停用',
  'setting.chargeManage.titleDescription': '对记账管理的描述',
  'setting.payment.dailyReceiptLimit': '每日收款限额',
  'setting.payment.merchantInfo': '商家信息',
  'setting.logisticsEdit.name': '名称',
  'setting.pleaseSelectIndustry': '请选择行业',
  'setting.payment.idType': '证件类型',
  'setting.payment.activeStatus': '活跃',
  'setting.payment.transactionFee': '交易手续费',
  'setting.payment.creditCardRateNg': '1.5%上限为每笔交易2000奈拉',
  'setting.payment.untilSettlementBeforeReview': '在账户审核完成之前，不会处理任何结算。',
  'setting.store.tagline': '店铺标题',
  'setting.payment.upload': '上传',
  'setting.tax.pleaseEnterTaxPercentage': '请输入税值',
  'setting.staff.titleDescription': '管理员工可在您商店中查看的内容或执行的操作',
  'setting.payment.enterAccount': '输入账号',
  'setting.account.storeLanguage': '店铺语言',
  'setting.logistics.modifyText': '是否清除运费设置？这会使顾客无法下单',
  'setting.payment.activatePayment': '激活OPay支付',
  'setting.logistics.shippingType': '配送方式',
  'setting.logistics.selectAdministrativeRegion': '选择行政区',
  'setting.payment.dailySettlementDescription': '每天都会进行结算。',
  'setting.account.language': '语言',
  'setting.store.whatsApp': 'WhatsApp',
  'setting.payment.wallet': '结算到卡',
  'setting.domain.list': '域名列表',
  'setting.tax.name': '税名称',
  'setting.payment.cashier': '收银台',
  'setting.logistics.price': 'setting.logistics.price',
  'setting.payment.idNumber': '身份证号码',
  'setting.staff.title': '员工管理',
  'setting.store.storeName': '店铺名字',
  'setting.payment.noOpenDescription': '一键创建您的OPay支付账户。',
  'setting.payment.selectSocialMedia': '选择社交媒体',
  'setting.logistics.shippingFlat': '固定运费',
  'setting.logisticsEdit.price': '运费',
  'setting.store.storeUrl': '商城链接',
  'setting.payment.checkAccount': '查看收款账户',
  'setting.payment.businessNameEg': '英文商业名称',
  'setting.makeSureDelete': '确认要删除吗？',
  'setting.payment.ownerAddress': '所有者地址',
  'setting.logistics.shippingAmount': '运费金额',
  'setting.logistics.courierDelivery': '支持快递配送',
  'setting.payment.needHelp': '需要帮助？',
  'setting.shopSetting.onlineLanguageLabel': '语言列表',
  'setting.domain.formDomainPlaceholder': '域名示例：example.com',
  'setting.payment.title': 'OPay账号',
  'setting.selectTextShopManager': '店长',
  'setting.logistics.title': '运费设置',
  'setting.domainList.createDate': '添加日期',
  'setting.payment.settlementIntervalDescription': '顾客付款成功后，资金结算到您的银行账户的周期',
  'setting.logistics.stopCodSuccessTips': '停用 Cash on Delivery ',
  'setting.payment.amountToBeSettled': '待结算金额',
  'setting.store.addressDescription': '此地址将显示在您的发票上。您可在发货设置中编辑用于计算运费的地址。您的主要营业地点可能会影响您的商店中可用的应用。',
  'setting.payment.businessTypeDetail': '业务类型',
  'setting.payment.currency': '货币',
  'setting.logistics.free': '免费',
  'setting.payment.modifyEmailText': '修改邮箱',
  'setting.payment.address': '地址',
  'setting.domain.describe': '顾客访问商城的域名地址',
  'setting.tax.title': '税费',
  'setting.title': '设置管理',
  'setting.payment.and': '和',
  'setting.payment.selectIdType': '选择证件类型',
  'setting.store.country': '国家',
  'setting.logistics.openCodModelTips': '启用后，消费者可以在结账时选择货到付款的支付方式',
  'setting.domainList.bindExistingDomain': '绑定已有域名',
  'setting.payment.website': '官方网站',
  'setting.logistics.currentUsed': '当前',
  'setting.pleaseEnterInternationalCode': '请输入国际区号',
  'setting.edit': '编辑',
  'setting.payment.settingPaymentAccountHelp': '如何设置OPay支付账号',
  'setting.staff.search': '搜索',
  'setting.account.password': '密码',
  'setting.logistics.shippingFlatDescription': '配送到全国任意地区，顾客下单时只需支付您配置的运费',
  'setting.payment.contactInformation': '联系方式',
  'setting.payment.birthday': '生日',
  'setting.addStaff.staffDescription': '通过向员工发送邀请为他们提供访问您商店的权限',
  'setting.staff.creatTime': '创建时间',
  'setting.logistics.addFreeShipping': '添加运费',
  'setting.store.currencyDescription': '这是您销售的产品所采用的货币。',
  'setting.payment.enterBvn': '输入BVN',
  'setting.payment.enterPhone': '输入手机号',
  'setting.addStaff.contactStoreManage': '请联系店铺管理员',
  'setting.payment.accountInfo': '收款账户信息',
  'setting.domain.configContent': '需配置域名指向内容，',
  'setting.payment.area': '地区',
  'setting.logistics.pickUpDescription': '为方便顾客取货请输入自取点的位置相关信息，该信息将在顾客结账时显示',
  'setting.domain.domain': '域名',
  'setting.logistics.getShopInfoFail': '获取店铺信息失败',
  'setting.addStaff.mail': '邮箱',
  'setting.domainList.linked': '已连接',
  'setting.payment.socialMediaAccount': '网站/社交媒体账户',
  'setting.domain.bind': '绑定',
  'setting.payment.phone': '手机号',
  'setting.logistics.selectShippingType': '选择运费方式',
  'setting.logistics.pickUpAddress': '取件地点',
  'setting.logistics.openCodTips\t': '货到付款已激活',
  'setting.payment.toOpen': '去开通',
  'setting.chargeGroup.titleDescription': '对分组的描述',
  'setting.payment.enterAccountName': '输入开户姓名',
  'setting.tax.addTax': '新增税',
  'setting.payment.bankInfo': '银行账户信息',
  'setting.logistics.editShipping': '编辑运费',
  'setting.payment.phoneNumber': '电话号码',
  'setting.payment.Individual': '个人',
  'setting.payment.selectState': '请选择',
  'setting.store.save': '保存',
  'setting.account.cancel': '取消',
  'setting.internationalCode': '国际区号',
  'setting.payment.businessLater': '企业（即将支持）',
  'setting.selectTextRead': '已读',
  'setting.payment.createPersonalAccount': '创建个人OPay帐户',
  'setting.payment.withinHours': '24小时内',
  'setting.domain.bindFail': '绑定失败',
  'setting.domainList.united': '未连接',
  'setting.message.loadingAllText': '没有更多消息了',
  'setting.store.storeMessageDescription': 'Pandova和您的客户将使用此信息来联系您。',
  'setting.staff.delete': '删除',
  'setting.payment.businessLogo': '商家Logo',
  'setting.domain.domainInputVerify': '请输入您的正确的域名',
  'setting.payment.enterTradingName': '输入商业名称',
  'setting.logisticsEdit.isShowStore': '是否在商城展示',
  'setting.pleaseSelectLanguage': '请选择语言',
  'setting.payment.opaySuccessDescription': 'OPay支付开通成功！你可以去OPay了解更多信息！',
  'setting.tax.compoundTax': '复合税',
  'setting.pleaseSelectState': '请选择州',
  'setting.payment.LogoLimitDescription': 'Logo大小不能超过512KB',
  'setting.payment.titleDescription': '启用并管理店铺的支付服务提供商',
  'setting.logistics.shippingName': '运费名称',
  'setting.store.storeCurrency': '商品货币',
  'setting.addStaff.staff': '员工',
  'setting.logistics.stopCod': '停用货到付款',
  'setting.logistics.addFlatShipping': '添加为固定运费',
  'setting.account.details': '详细信息',
  'setting.tax.description': '描述',
  'setting.addStaff.cancel': '取消',
  'setting.delete': '删除',
  'setting.deleteSuccess': '删除成功',
  'setting.notifications.messageTitle': '消息标题',
  'setting.logistics.specificRegion': '指定地区',
  'setting.addStaff.urlInvalid': '邀请链接已过期',
  'setting.payment.signatoryName': '签字人姓名',
  'setting.logisticsEdit.save': '保存',
  'setting.payment.providedByOpay': '由OPay提供支付服务',
  'setting.payment.bankAccount': '银行账号',
  'setting.logistics.addShipping': '添加运费',
  'setting.payment.submit': '提交',
  'setting.payment.bankSwift': '公司银行SWIFT代码',
  'setting.staff.state': '状态',
  'setting.tax.save': '保存',
  'setting.payment.unOpenPayment': 'OPay',
  'setting.payment.bankToAccount': '结算到账户',
  'setting.domain.promptInfoText': '您需要登录您的提供商帐号并更改设置，方可连接您的域名。验证连接以确保您的域名已设置正确。',
  'setting.store.phone': '电话',
  'setting.payment.governorate': '省',
  'setting.payment.goToOpay': '跳转OPay',
  'setting.account.title': '账户设置',
  'setting.store.storeMessage': '店铺信息',
  'setting.payment.reviewFailed': 'OPay支付账号绑定审核未通过',
  'setting.tax.pleaseEnterTaxName': '请输入税名称',
  'setting.payment.settlementCycle': '结算周期为',
  'setting.account.mail': '电子邮箱',
  'setting.account.firstName': '姓',
  'setting.logistics.pickUpTitle': '店铺有新的订单',
  'setting.selectTextUnread': '未读',
  'setting.store.storeLogo': '店铺LOGO',
  'setting.payment.selectBirthday': '选择生日',
  'setting.payment.opened': '已开通',
  'setting.payment.optional': '（可选）',
  'setting.payment.transactionFeeText': '交易费费率',
  'setting.payment.creditCardRate': '0',
  'setting.payment.limitNow': '你的收款限额现在是',
  'setting.logistics.addToSpecificRegion': '添加到指定地区',
  'setting.payment.toComplete': '去完善KYC信息',
  'setting.logistics.closeCodModelTips': '订单取消后，商品重新入库',
  'setting.payment.willOpenPayment': '即将开通支付',
  'setting.payment.toOpenMode': '去开通支付方式',
  'setting.account.preferredLanguage': '后台语言',
  'setting.payment.openSuccess': '恭喜你现在可以开始销售了',
  'setting.payment.registerEmailTip': '您的注册邮箱是',
  'setting.payment.termsOfUse': '使用条款',
  'setting.payment.dailyReceiptLimitDescription': '每天在线支付成功的订单总额上限',
  'setting.store.address': '店铺地址',
  'setting.payment.completeAccountSetup': '完成帐户设置',
  'setting.shopSetting.onlineLanguageDescription': '您的客户在浏览在线商店时，将使用此语言。',
  'setting.staff.activityTime': '激活时间',
  'setting.payment.enterAddress': '输入地址',
  'setting.payment.tradingName': '商业名称',
  'setting.logistics.settingText': '确认保存设置',
  'setting.detail': '详情',
  'setting.logistics.stopCodDescription': '停用后，网店客户将不可使用此支付方式结账。',
  'setting.payment.uploadFile': '上传文件',
  'setting.payment.failDescription': '提交的信息审核未通过',
  'setting.payment.bvn': 'BVN',
  'setting.domainList.status': '状态',
  'setting.store.selectFile': '选择文件',
  'setting.payment.cashierConfig': '收银台配置',
  'setting.domain.tautologyBind': '重试',
  'setting.domainList.delete': '删除',
  'setting.payment.businessCategory': '业务类别',
  'setting.payment.enterWebsite': '输入官方网站',
  'setting.payment.receiptSum': '累计已收款',
  'setting.payment.privacyPolicy': '隐私政策',
  'setting.pleaseEnterTransport': '请输入运输方式名称',
  'setting.payment.receipt': '收款',
  'setting.payment.haveRead': '我确认我读过OPay的',
  'setting.mailFailed': '邮箱格式错误',
  'setting.payment.settlementType': '结算类型',
  'setting.payment.selectCity': '选择市区',
  'setting.payment.industryInformation': '行业信息',
  'setting.pleaseEnterFirstName': '请输入名字',
  'setting.logistics.shippingFree': '免运费',
  'setting.domainList.settingMainDomain': '设为主域名',
  'setting.payment.accountName': '开户姓名',
  'setting.payment.paymentOpened': 'OPayCheckout支付已经开通',
  'setting.payment.businessLogoDescription': '我们建议您使用尺寸为100x100的图标，以获得最佳效果。最大大小：512 KB。',
  'setting.logistics.shippingMethodsLabel': '支持的配送类型',
  'setting.payment.creditCardRateText': '通道费率',
  'setting.payment.residentialAddress': '居住地址',
  'setting.payment.underReviewDescription': '在审核完成之前，不会进行结算',
  'setting.charge.titleDescription': '对费用明细的描述',
  'setting.logistics.addCustomShipping': '为指定地区添加运费',
  'setting.payment.otherFile': '其他（上传其他文档）（可选）',
  'setting.payment.nearestMark': '最近的地标',
  'setting.operation': '操作',
  'setting.account.editPassword': '更改密码',
  'setting.payment.needSetUpSoon': '在帐户设置完成之前，不会处理任何结算。您需要尽快完成帐户设置，否则您的帐户将在开户一个月后被冻结。',
  'setting.payment.limit': '您目前的收款限额为',
  'setting.shipping': '运输',
  'setting.buttonTips': '只有店铺管理员有该权限',
  'setting.payment.industry': '行业',
  'setting.payment.chooseFile': '选择要上传的文件（图片或PDF）',
  'setting.addStaff.lastName': '姓',
  'setting.payment.enterBusiness': '输入你的生意',
  'setting.smsManage.titleDescription': '对短信管理的描述',
  'setting.payment.businessNameArabic': '阿拉伯文商业名称',
  'setting.logistics.editSpecificRegion': '编辑指定地区',
  'setting.payment.subCategory': '子类别',
  'setting.account.save': '保存',
  'setting.payment.todayReceiptDescription': '今天在线支付成功的订单总额',
  'setting.addStaff.firstName': '名',
  'setting.payment.verifyMailTitle': '确认邮箱',
  'setting.payment.bankBranch': '银行分行',
  'setting.payment.opayKycSuccessDescription': '祝贺您，您的支付账户已审批开通。',
  'setting.pleaseEnterStoreName': '请输入店铺名称',
  'setting.staff.name': '姓名',
  'setting.logistics.addSpecificRegion': '添加指定地区',
  'setting.tax.titleDescription': '管理店铺收税的方式',
  'setting.payment.review': '审核中',
  'setting.payment.cod': '货到付款',
  'setting.payment.backEmail': '退单邮件',
  'setting.payment.ImprovingInfoOpenSettlement': '单击下面的按钮完成帐户设置。',
}
