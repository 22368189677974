export default {
  'onlineShop.themeDesign.newProduct': 'New Arrivals',
  'onlineShop.theme.addThemeButtonText': 'Add theme',
  'onlineShop.themeDesign.globalSubButtonTextColor': 'Sub button text color',
  'onlineShop.themeDesign.componentAttrBESTTitle': 'Recommendation',
  'onlineShop.themeDesign.componentAttrSubscriptionSubDefaultTitle': 'Subscribe to us for exclusive discounts, deals, and the latest merchandise.',
  'onlineShop.themeDesign.makeSureDelete': 'Confirm to delete?',
  'onlineShop.common.helpCenterEntrance': 'How to decorate the shop?',
  'onlineShop.themeDesign.componentAttrProductLink': 'Product Link',
  'onlineShop.nav.menuItem': 'Menu item',
  'onlineShop.themeDesign.componentAttrShowButtomLine': 'Show bottom split line',
  'onlineShop.theme.currentThemeInfoText': 'This is the page style that customers will see when they visit your online store.',
  'onlineShop.themeDesign.componentAttrsMaxNum': 'Maximum',
  'onlineShop.theme.currentThemeTitle': 'Current Theme',
  'onlineShop.themeDesign.componentAttrBgc': 'Background Color',
  'onlineShop.common.publishButtonText': 'Publish',
  'onlineShop.themeDesign.componentAttrButtonDefaultSubTitle': 'Button text',
  'onlineShop.themeDesign.componentAttrSubscriptionDefaultTitle': 'Subscribe now',
  'onlineShop.themeDesign.globalSubButtonBgColor': 'Sub button background color',
  'onlineShop.themeDesign.globalTwitterLink': 'Twitter',
  'onlineShop.themeDesign.componentAttrTwoTitle': 'Secondary title',
  'onlineShop.themeDesign.componentAttrMobileMenuTextColor': 'Mobile menu color',
  'onlineShop.themeDesign.componentAttrsAlignCenter': 'Center',
  'onlineShop.themeDesign.componentAttrBarDefault': 'Welcome to our shop',
  'onlineShop.themeDesign.globalFontSize': 'Title font size',
  'onlineShop.themeDesign.componentAttrLogoWidth': 'Logo width',
  'onlineShop.themeDesign.globalPrimaryButtonTextColor': 'Main button text color',
  'onlineShop.themeDesign.componentAttrLabelDefaultTitle': 'Default content',
  'onlineShop.themeDesign.componentAttrTitlePlaceholder': 'Please enter title',
  'onlineShop.themeDesign.menuSelectionPlaceholder': 'Select the menu',
  'onlineShop.themeDesign.componentAttrSlideHeight': 'Slideshow height',
  'onlineShop.themeDesign.globalTextBgcColor': 'Text background color',
  'onlineShop.themeDesign.componentAttrLink': 'Link',
  'onlineShop.themeDesign.componentAttrSlideWidth': 'Slideshow width',
  'onlineShop.themeDesign.componentAttrsliderTextShow': 'Whether to display text',
  'onlineShop.common.publishAddSaveButtonText': 'Save and Publish',
  'onlineShop.themeDesign.componentSlideshow': 'Slideshow',
  'onlineShop.themeDesign.globalPrimaryButtonBgColor': 'Primary button background color',
  'onlineShop.themeDesign.componentAllProduct': 'Products',
  'onlineShop.themeDesign.componentAttrSliderImage': 'Banner Image',
  'onlineShop.themeDesign.globalYouTubePlaceholder': 'Ex. https://www.youtube.com/pandova',
  'onlineShop.themeDesign.globalContentTextFontWeight': 'Content text font weight',
  'onlineShop.theme.myShopOperation': 'Operation',
  'onlineShop.common.customButtonText': 'Customize',
  'onlineShop.common.moreButtonText': 'More',
  'onlineShop.common.renameNameButtonText': 'Rename',
  'onlineShop.theme.marketTitle': 'Theme market',
  'onlineShop.themeDesign.globalCommodityPrice': 'Commodity price',
  'onlineShop.themeDesign.componentAttrTopPadding': 'Top inner margin',
  'onlineShop.themeDesign.blockText': 'Item',
  'onlineShop.themeDesign.LeavePageTitle': 'Leaving this page, will all unsaved data in this page be lost?',
  'onlineShop.nav.addMenuItem': 'Add menu item',
  'onlineShop.page.titlePlaceholder': 'E.g. terms of service, frequently asked questions',
  'onlineShop.themeDesign.componentAttrMarginTop': 'Top margin',
  'onlineShop.themeDesign.componentAttrSlideSpeed': 'Rotation speed',
  'onlineShop.themeDesign.menuTitle': 'Menu',
  'onlineShop.themeDesign.componentAttrButtonPadding': 'Bottom inner margin',
  'onlineShop.themeDesign.componentRecommendProduct': 'Recommended Items',
  'onlineShop.themeDesign.componentFooter': 'Footer',
  'onlineShop.themeDesign.componentBanner': 'Banner',
  'onlineShop.nav.menuLinkPlaceholder': 'Copy the link or select the page',
  'onlineShop.theme.headerTitle': 'Theme',
  'onlineShop.nav.menuItemNoData': 'No items found',
  'onlineShop.themeDesign.componentAttrsFullLabel': 'Full',
  'onlineShop.themeDesign.globalColor': 'Colour',
  'onlineShop.themeDesign.componentAttrsPaddingLabel': 'Padding',
  'onlineShop.themeDesign.MenuComponentDesign': 'Decorate',
  'onlineShop.common.previewShopButtonText': 'Preview',
  'onlineShop.themeDesign.HomeText': 'Home',
  'onlineShop.themeDesign.componentHeader': 'header',
  'onlineShop.themeDesign.componentAttrButtonText': 'Button text',
  'onlineShop.themeDesign.componentAttrPrimaryButton': 'Main button',
  'onlineShop.themeDesign.componentAttrProductCollection': 'Collections',
  'onlineShop.themeDesign.componentAnnouncement': 'Announcement bar',
  'onlineShop.themeDesign.categoryTitleProduct': 'Product',
  'onlineShop.themeDesign.globalTwitterPlaceholder': 'Ex. https://twitter.com/pandova',
  'onlineShop.themeDesign.globalSubButtonBdColor': 'Sub button border color',
  'onlineShop.themeDesign.componentAttrMinorButton': 'Secondary button',
  'onlineShop.themeDesign.StartDesign': 'Start decoration',
  'onlineShop.themeDesign.globalContentTextFontFamily': 'Text font family',
  'onlineShop.themeDesign.componentAttrImageBannerDefaultTitle': 'Banner default title',
  'onlineShop.themeDesign.globalContentTextFontSize': 'Text font size',
  'onlineShop.themeDesign.componentAttrButtonStyle': 'Button style',
  'onlineShop.themeDesign.componentAttrProductGroup': 'Collections',
  'onlineShop.themeDesign.componentAttrsAlignLeft': 'Align Left',
  'onlineShop.themeDesign.componentAttrIsShowEmail': 'Show email',
  'onlineShop.themeDesign.saveButtonText': 'Save',
  'onlineShop.themeDesign.globalFacebookLink': 'Facebook',
  'onlineShop.themeDesign.globalInstagramPlaceholder': 'Ex. http://instagram.com/pandova',
  'onlineShop.themeDesign.componentAttrsAlignSmall': 'Small',
  'onlineShop.themeDesign.componentAttrMobileMenuBgc': 'Mobile menu background',
  'onlineShop.common.titleColor': 'Title Color',
  'onlineShop.page.updateTime': 'Update time',
  'onlineShop.themeDesign.componentAttrButtonLink': 'Button link',
  'onlineShop.themeDesign.componentAttrPersonageName': 'Personal Center',
  'onlineShop.themeDesign.componentAttrImgRadius': 'Picture fillet',
  'onlineShop.themeDesign.componentAttrsSelectLarge': 'Large',
  'onlineShop.page.content': 'Content',
  'onlineShop.themeDesign.componentAttrRadius': 'Fillet',
  'onlineShop.themeDesign.componentAttrTextColor': 'Text color',
  'onlineShop.themeDesign.globalCommodityDiscountedPrice': 'Commodity discounted price',
  'onlineShop.themeDesign.componentAttrsAlignRIght': 'Align Right',
  'onlineShop.nav.link': 'Link',
  'onlineShop.themeDesign.componentAttrSelectPlaceholder': 'Please select',
  'onlineShop.themeDesign.globalPinterestPlaceholder': 'Ex. https://pinterest.com/pandova',
  'onlineShop.themeDesign.MenuComponentLibrary': 'Resources',
  'onlineShop.themeDesign.globalGuidanceText': 'Add your social media links',
  'onlineShop.themeDesign.globalPinterestText': 'Pinterest',
  'onlineShop.themeDesign.componentAttrSlideDefaultSubTitle': 'Slide sub title',
  'onlineShop.theme.myShopTitle': 'My Themes',
  'onlineShop.themeDesign.componentAttrsNormal': 'Normal',
  'onlineShop.themeDesign.componentAttrSlideDefaultTitle': 'Slide default title',
  'onlineShop.themeDesign.componentAttrSecondaryTitle': 'Subscription title',
  'onlineShop.theme.themeMarketInfoExplainText': 'Pandova offers free themes designed to provide the best customized effect of the mall.',
  'onlineShop.themeDesign.globalContentText': 'Text color',
  'onlineShop.themeDesign.componentAttrGoodsSetDefault': 'Product collection',
  'onlineShop.common.deleteButtonText': 'Delete',
  'onlineShop.themeDesign.addSection': 'Add Section',
  'onlineShop.themeDesign.globalInstagramText': 'Instagram',
  'onlineShop.themeDesign.globalFontFamily': 'Font',
  'onlineShop.themeDesign.componentAttrSecondaryMenuColor': 'Secondary menu color',
  'onlineShop.themeDesign.globalImage': 'Picture',
  'onlineShop.themeDesign.addComponent': 'Add component',
  'onlineShop.themeDesign.componentAttrSelected': 'Select',
  'onlineShop.themeDesign.pageHome': 'Home page',
  'onlineShop.theme.myShopLastUpdateTime': 'Last modification time',
  'onlineShop.themeDesign.componentAttrsContainerWidth': 'Container Width',
  'onlineShop.theme.myShopInfoExplainText': 'Manage all your decoration themes here. You can also replace the online page style effect by publishing new themes.',
  'onlineShop.theme.myShopThemeName': 'Theme name',
  'onlineShop.nav.new': 'New menu',
  'onlineShop.page.pageTitle': 'Page title',
  'onlineShop.themeDesign.globalFacebookPlaceholder': 'Ex. https://facebook.com/pandova',
  'onlineShop.themeDesign.componentAttrSecondarySubTitle': 'Secondary subscription title',
  'onlineShop.themeDesign.quickLinks': 'Quick links',
  'onlineShop.themeDesign.componentProductCollection': 'Product Collection',
  'onlineShop.nav.menuTitle': 'Menu title',
  'onlineShop.themeDesign.MenuComponentGlobalSet': 'Global settings',
  'onlineShop.themeDesign.componentAttrProductDetail': 'Product details',
  'onlineShop.themeDesign.componentAttrTextPlaceholder': 'Please enter content',
  'onlineShop.themeDesign.componentAttrLogo': 'Logo',
  'onlineShop.themeDesign.addBlock': 'Add Block',
  'onlineShop.themeDesign.globalPrimaryButtonBdColor': 'Primary button border color',
  'onlineShop.themeDesign.categoryTextLink': 'Text link',
  'onlineShop.themeDesign.globalTextColor': 'Text Color',
  'onlineShop.themeDesign.componentAttrsAlignMode': 'Align',
  'onlineShop.theme.goStoreText': 'Go to online store',
  'onlineShop.themeDesign.componentAttrHome': 'Home',
  'onlineShop.page.title': 'Title',
  'onlineShop.themeDesign.componentAttrsBold': 'Bold',
  'onlineShop.themeDesign.close': 'Close',
  'onlineShop.nav.menuTitlePlaceholder': 'E.g. footer menu',
  'onlineShop.common.previewButtonText': 'Preview',
  'onlineShop.themeDesign.componentAttrText': 'Text',
  'onlineShop.themeDesign.menuNew': 'Create a new menu',
  'onlineShop.page.new': 'New page',
  'onlineShop.themeDesign.componentAttrTitle': 'Title',
  'onlineShop.themeDesign.componentAttrReplace': 'Change',
  'onlineShop.themeDesign.globalSocialMedia': 'Social media',
  'onlineShop.themeDesign.componentAttrButtonMargin': 'Bottom margin',
  'onlineShop.nav.tips': 'You can set up the menu here. When designing the top navigation, footers for the store, the related menus can be manually linked',
  'onlineShop.themeDesign.LeavePageContentText': 'Have you confirmed your departure?',
  'onlineShop.themeDesign.componentAttrSecondaryMenuBgcColor': 'Secondary menu background color',
  'onlineShop.themeDesign.componentAttrsBolder': 'Bolder',
  'onlineShop.themeDesign.componentAttrsLighter': 'Lighter',
  'onlineShop.common.subTitleColor': 'Sub Title Color',
  'onlineShop.themeDesign.componentAttrImgTransparency': 'Picture transparency',
  'onlineShop.themeDesign.globalYouTubeText': 'YouTube',
  'onlineShop.themeDesign.componentAttrsAlignMiddle': 'Middle',
  'onlineShop.themeDesign.categoryImageLink': 'Pictures link',
  'onlineShop.themeDesign.componentAttrTopOuterMargin': 'Top outer margin',
  'onlineShop.common.copyButtonText': 'Copy',
}
