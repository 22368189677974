export default {
  'home.quickMoveShop': 'نقل المنتجات ',
  'home.kycInfo.accountFeozen': 'تم تجميد حساب OPay الخاص بك، لا يمكن إتمام التسويات و استلام المدفوعات',
  'home.shippingTypeHasSet': 'إعداد نموذج الشحن',
  'home.paymentMethodExplanation': 'قم باختيار طرق الدفع للعملاء',
  'home.addDomainOperationText': 'إضافة نطاق خاص (الدومين)',
  'home.viewPaymentDetail': 'عرض التفاصيل',
  'home.kycInfo.paymentReceived': 'تم استلام',
  'home.productAdded': 'تم إضافة المنتج ',
  'home.kycInfo.comma': '،',
  'home.payment.goSet': 'اختيار',
  'home.goSettingUpdateShipping': 'يمكنك الانتقال إلى الإعدادات لتعديل نموذج الشحن.',
  'home.completedStepsPercent': 'الخطوات الأساسية',
  'home.adminLanguage': 'اللغة المفضلة',
  'home.kycInfo.accountNotset': 'لم تقم بإكمال إعدادات حساب OPay الخاص بك، تم تجميد حسابك.',
  'home.addProductExplanation': 'قم بإضافة منتج في متجرك الإلكتروني',
  'home.shopThemeExplanation': 'تصميم شكل و هيئة المتجر',
  'home.selectThemeOperationText': 'اخترتصميم المتجر ',
  'home.shopNeed': 'المتجر ليس جيدا',
  'home.shippingFee': 'تكلفة الشحن',
  'home.domainSetting': 'إضافة نطاق خاص (الدومين)',
  'home.kycInfo.onlyBeUsed': '٣. يمكن تجربة الحساب لمدة ',
  'home.dowloadText': 'تنزيل',
  'home.addOtherProduct': 'إضافة منتج آخر',
  'home.title': 'الصفحةالرئيسية',
  'home.kycInfo.accountPayment': 'حاليا تم استلام مبلغ',
  'home.todaySales': 'المبيعات',
  'home.shopInfoComplete': 'إكمال معلومات المتجر',
  'home.kycInfo.willFrozen': 'سيتم تجميد الحساب بعد يوم',
  'home.shopMallPreview': 'عرض المتجر',
  'home.productAddedGuide': 'يمكن إضافة المزيد من المنتجات في صفحة المنتجات',
  'home.kycInfo.maxAccount': 'الحد الأقصى لاستلام المدفوعات هو',
  'home.kycInfo.remaining': 'متبقي',
  'home.logout': 'تسجيل الخروج',
  'home.todayPageVisit': 'عدد مشاهدات الصفحة',
  'home.paymentActivated': 'تم تفعيل الدفع',
  'home.kycInfo.setNow': 'إكمال الآن',
  'home.shopHasInit': 'تم الانتهاء من الإعداد الأولي',
  'home.kycInfo.daysOfBracket': 'أيام',
  'home.kycInfo.NotSettle': 'لا يمكن التسوية',
  'home.shopMoveTip': 'اذا لديك منتجات على منصة أخرى، يمكنك الضغط هنا لنقل المنتجات',
  'home.creatShop': 'إنشاء متجر',
  'home.domainSettingExplain': 'قم بربط الدومين الخاص بك ليتمكن العملاء من إيجاد متجرك بسهولة',
  'home.shopThemeUpdated': 'تم تحديث مظهر المتجر بنجاح',
  'home.todayProcess': 'إحصائيات اليوم',
  'home.shopTheme': 'مظهر المتجر',
  'home.kycInfo.limitForMonry': 'هناك حد اقصى لاستلام الاموال',
  'home.addProduct': 'إضافة منتج',
  'home.checkShopping': 'عرض تكلفة الشحن',
  'home.dowloadPandovaApp': 'نزل تطبيقPandova!',
  'home.goSettingUpdatePayment': 'يمكن تغيير إعدادات الدفع في صفحة الإعدادات',
  'home.customTheme': 'مظهر المتجر',
  'home.shopInitSteps': 'تبقى 4 خطوات لإكمال إعداد متجرك',
  'home.todayOrderNumber': 'عدد الطلبات',
  'home.paymentMethod': 'طريقة الدفع',
  'home.fillToAddressAndCurrency': 'أدخل العنوان الذي تم تسجيل المتجر فيه وتحقق من العملة',
  'home.someStepsForInit': 'خطوة بخطوة لإكمال إعدادات الحساب',
  'home.kycInfo.expirationFrozen': 'سيتم تجميد الحساب بعد انتهاء صلاحيته',
  'home.completeStoreOperationText': 'معلومات المتجر',
  'home.todayUserVisit': 'عدد الزوار',
  'home.kycInfo.setOpayAccount': 'يرجى إكمال إعدادات حساب OPay الخاص بك',
  'home.shippingFeeExplanation': 'يمكن تعيين تكلفة الشحن لمناطق التسليم حسب الوزن والسعر',
  'home.goAdd': 'إضافة',
  'home.accountSetting': 'إعدادات الحساب',
  'home.goDecorate': 'تصميم الآن',
  'home.settingWarnTips': 'إذا لم تكن قد أكملت ضبط الإعدادات ، فلن يتمكن عملاؤك من الطلب.',
  'home.kycInfo.days': 'ايام',
  'home.moreSettingTip': 'قم بإكمال إعدادات حسابك للحصول على المزيد من الشعبية',
  'home.checkPayment': 'عرض إعدادات الدفع',
  'home.shopThemeGuide': 'يمكن تصميم مظهر متجرك في صفحة المتجر الإلكتروني',
}
