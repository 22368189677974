export default {
  'account.register.emailCheckCode': '邮箱验证码',
  'account.shopAdd.shopNameFormatTip': '最多50个字符',
  'account.register.falseCheckCode': '验证码不正确',
  'account.register.reSend': '重新发送',
  'account.forgetPassword.checkCode': '验证码',
  'account.login.linkManager': '请联系店铺管理员',
  'account.shopAdd.shopInfo': '店铺信息',
  'account.login.register': '注册',
  'account.shopAdd.domainUrl': '店铺域名',
  'account.register.passwordFalseFormat': '密码要求8-16位，建议包含字母和数字',
  'account.resetPassword.currentAccount': '当前账户',
  'account.register.goAgree': '继续即表示您同意',
  'account.resetPassword.newPassword': '新密码',
  'account.shopAdd.addShop': '创建门店',
  'account.shopAdd.freeUse': '开始免费试用',
  'account.register.sendMail': '发送验证码',
  'account.resetPassword.backToLogin': '返回登录',
  'account.forgetPassword.falseMail': '邮箱不存在',
  'account.register.accountExist': '已有账户？',
  'account.register.privacy': '隐私政策',
  'account.shopList.allShops': '所有门店',
  'account.shopAdd.shopDomainFormatTip': '最多50位，可以是数字或字符或“-”但不能以“-”开头',
  'account.register.passwordNoCommon': '密码不一致',
  'account.orderDetail.paymentTime': '付款时间',
  'account.resetPassword.password': '密码',
  'account.register.success': '注册成功',
  'account.register.confirmPassword': '确认密码',
  'account.login.canNotUse': '当前邀请链接已过期',
  'account.register.codeMailTips': '输入发送到您邮箱的验证码',
  'account.register.firstName': '名字',
  'account.resetPassword.passwordNoCommon': '密码不一致',
  'account.forgetPassword.sendCheckMailButton': '发送验证码',
  'account.register.and': '和',
  'account.forgetPassword.requireText': '请填写该字段',
  'account.login.falseMail': '邮箱不存在',
  'account.register.password': '密码',
  'account.resetPassword.passwordFalseLength': '8-16位',
  'account.login.onAccount': '没有账户？',
  'account.register.createButton': '创建',
  'account.login.password': '密码',
  'account.register.passwordCheckTips': '8-16位，建议包含字母和数字',
  'account.login.forgetPassword': '忘记密码',
  'account.forgetPassword.passwordMail': '输入发送到您邮箱的验证码',
  'account.register.accountLogin': '登录',
  'account.forgetPassword.reSend': '重新发送',
  'account.shopAdd.country': '国家/地区',
  'account.login.mail': '邮箱',
  'account.register.email': '邮箱',
  'account.shopAdd.business': '行业（可选）',
  'account.forgetPassword.explainText': '我们需要您的邮箱和验证码，来确认这是您本人的账户。',
  'account.forgetPassword.falseCheckCode': '验证码不正确',
  'account.login.back': '返回',
  'account.resetPassword.reset': '重置密码',
  'account.forgetPassword.email': '邮箱',
  'account.resetPassword.passwordFalseFormat': '密码要求8-16位，建议包含字母和数字',
  'account.forgetPassword.goOn': '继续',
  'account.register.serviceTerms': '服务条款',
  'account.register.createAccount': '创建你的账户',
  'account.register.mailExisted': '邮箱已被注册',
  'account.resetPassword.confirmButton': '确认',
  'account.resetPassword.finish': '密码设置完成',
  'account.login.login': '登录',
  'account.register.passwordPlaceHolder': '8-16位',
  'account.register.nextToCreateShop': '下一步',
  'account.shopAdd.shopName': '店铺名称',
  'account.login.falsePassword': '密码不正确',
  'account.resetPassword.confirmPassword': '确认密码',
  'account.resetPassword.passwordRequireText': '密码要求8-16位',
  'account.register.lastName': '姓氏',
  'account.shopAdd.completeInfo': '请完善以下信息开始免费体验',
  'account.resetPassword.requireText': '请填写该字段',
  'account.register.requireTrueMail': '请填写正确的邮箱',
  'account.register.passwordFalseLength': '密码要求8-16位',
  'account.resetPassword.oldPassword': '原始密码',
  'account.register.checkCode': '验证码',
  'account.register.requireText': '请填写该字段',
  'account.resetPassword.back': '返回',
  'account.register.createFirstShop': '完善更多信息',
}
